import { Col, Row } from "antd";
import React from "react";

export default function ListHeader() {
  return (
    <Row style={{ marginTop: 20 }} gutter={10}>
      <Col span={5} className="tituloTabla">
        Nombre
      </Col>

      <Col span={3} className="tituloTabla">
        Acción
      </Col>
    </Row>
  );
}
