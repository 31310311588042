import {
  URL_BASE_ASISTENCIAS,
  URL_BASE_EDITAR_ASISTENCIA,
  URL_BASE_XLS,
  URL_GENERICOS,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, postFile, postGetFile } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

////////////////////////////////////////////////

export const startGetAsistencias = () => {
  return async (dispatch, getState) => {
    const { filtroAsistencia } = getState().asistenciasReducer;
    const url = `${URL_BASE_ASISTENCIAS}/GetAsistenciasByFechas`;

    const result = await post(url, filtroAsistencia);
    if (result.code === 0) {
      dispatch(storeServicios(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get asistencias",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeServicios = (servicios) => ({
  type: types.storeServicios,
  payload: servicios,
});

//////////////////////////////////////////

export const startGetXLS = () => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_XLS}/GetAsistenciasXLS`;
    const { filtroAsistencia } = getState().asistenciasReducer;

    const result = await postGetFile(url, "Asistencias.xls", filtroAsistencia);

    if (result.code === 0) {
      //
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get xls",
            `${result.error}`
          )
        )
      );
    }
  };
};
//////////

export const setFiltroAsistencia = (filtro) => ({
  type: types.setFiltroAsistencia,
  payload: filtro,
});

/////////

export const startGetAsistencia = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetAsistencia/${asistenciaID}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeAsistencia(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get asistencia",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeAsistencia = (asistencia) => ({
  type: types.storeAsistencia,
  payload: asistencia,
});

///////////////////////////

export const startEditarDatosGenerales = () => {
  return async (dispatch, getState) => {
    const { nombre, usuarioID } = getState().userReducer.user;
    const { asistencia } = getState().asistenciasReducer;
    const url = `${URL_BASE_EDITAR_ASISTENCIA}/EditarDatosGenerales`;
    const result = await post(url, { ...asistencia, usuarioID });
    //
    if (result.code === 0) {
      dispatch(startGetAsistencia(asistencia.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};

export const setAsistenciaDato = (dato) => ({
  type: types.setAsistenciaDato,
  payload: dato,
});

///////////////////////////////////////////////////////

export const startChangeServicio = (body) => {
  return async (dispatch, getState) => {
    const { nombre, usuarioID } = getState().userReducer.user;

    const url = `${URL_BASE_EDITAR_ASISTENCIA}/ChangeServicio`;
    const result = await post(url, { ...body, usuarioID });
    //
    if (result.code === 0) {
      dispatch(startGetAsistencia(body.asistenciaID, false));
      dispatch(startGetServicioAsistencia(body.asistenciaID));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};

//////////////////////////////////////////////////////

export const startChangeCosto = (body) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_EDITAR_ASISTENCIA}/ChangeProveedor`;
    const result = await post(url, { ...body, usuarioID });
    //
    if (result.code === 0) {
      dispatch(startGetAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("ChangeProveedor", `${result.error}`)
        )
      );
    }
  };
};

//////////////////////////////////////////////////////

export const startChangeProveedor = (asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const { sucursalSelected, costoCambioEspecial, conclusionCambioEspecial } =
      getState().asistenciasReducer;

    const body = {
      proveedorSucursalID: sucursalSelected,
      // estatusFinalId: conclusionCambioEspecial,
      // costo: Number(costoCambioEspecial),
      asistenciaID,
      usuarioID,
    };

    const url = `${URL_BASE_EDITAR_ASISTENCIA}/ChangeProveedor`;
    const result = await post(url, body);
    //
    if (result.code === 0) {
      dispatch(startGetAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};

//////////////////////////////////////////////////////

export const startGetProveedoresByTexto = (texto) => {
  return async (dispatch) => {
    const url = `${URL_GENERICOS}/GetProveedoresByTexto/${texto}`;

    const result = await get(url);
    //
    if (result.code === 0) {
      dispatch(storeProveedoresByText(result.data));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};

const storeProveedoresByText = (proveedores) => ({
  type: types.storeProveedoresByText,
  payload: proveedores,
});

export const clearProveedoresByTexto = () => ({
  type: types.clearProveedoresByTexto,
  payload: null,
});

export const startGetAsistenciaSucursalesByProveedorID = ({
  proveedorID,
  asistenciaID,
}) => {
  return async (dispatch) => {
    const url = `${URL_BASE_EDITAR_ASISTENCIA}/GetAsistenciaSucursalesByProveedorID/${proveedorID}/${asistenciaID}`;

    const result = await get(url);
    console.log({ result });
    //
    if (result.code === 0) {
      dispatch(storeSucursalesByProveedor(result.data));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};

const storeSucursalesByProveedor = (sucursales) => ({
  type: types.storeSucursalesByProveedor,
  payload: sucursales,
});
//////////////////////////////////////////////////////

export const startChangeEstatusFinal = (asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const { estatusFinalSelected } = getState().asistenciasReducer;

    if (estatusFinalSelected === 0) return;
    const body = {
      estatusFinalID: estatusFinalSelected,
      asistenciaID,
      usuarioID,
    };

    const url = `${URL_BASE_EDITAR_ASISTENCIA}/ChangeEstatusFinal`;
    const result = await post(url, body);
    //
    if (result.code === 0) {
      dispatch(startGetAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};
//////////////////////////////////////////////////////

export const setSucursalSelected = (sucursalID) => ({
  type: types.setSucursalSelected,
  payload: sucursalID,
});

export const setProveedorSelected = (proveedorID) => ({
  type: types.setProveedorSelected,
  payload: proveedorID,
});

export const setCostoCambioEspecial = (costo) => ({
  type: types.setCostoCambioEspecial,
  payload: costo,
});

export const setConclusionCambioEspecial = (estatusFinalID) => ({
  type: types.setConclusionCambioEspecial,
  payload: estatusFinalID,
});

export const setEstatusFinalSelected = (estatusFinalID) => ({
  type: types.setEstatusFinalSelected,
  payload: estatusFinalID,
});

//////////////////////////////////////////////////////

export const startGetServicioAsistencia = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_EDITAR_ASISTENCIA}/GetServicioAsistencia/${asistenciaID}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeServicioAsistencia(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get servicio asistencia",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeServicioAsistencia = (servicioAsistencia) => ({
  type: types.storeServicioAsistencia,
  payload: servicioAsistencia,
});

///////////////////////////////////////

export const startUpdateDireccion = (body) => {
  return async (dispatch, getState) => {
    const { nombre, usuarioID } = getState().userReducer.user;

    const url = `${URL_BASE_EDITAR_ASISTENCIA}/UpdateDireccion`;
    const result = await post(url, { ...body, usuarioID });
    //
    if (result.code === 0) {
      dispatch(startGetAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};
////////

export const startQuitarProveedor = (asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const body = { asistenciaID, usuarioID };

    const url = `${URL_BASE_EDITAR_ASISTENCIA}/QuitarProveedor`;
    const result = await post(url, body);

    //
    if (result.code === 0) {
      dispatch(startGetAsistencia(asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};

////

export const setAsistenciasLoading = (payload) => ({
  type: types.setAsistenciasLoading,
  payload,
});
