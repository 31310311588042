import React, { useEffect } from "react";
import CardPage from "../../layout/CardPage";
import { Button, Space } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startGetClientes } from "../../context/actions/clientesActions";
import { startGetProveedores } from "../../context/actions/proveedoresActions";
import { startGetEstadosTodos } from "../../context/actions/reportesActions";

export const Reportes = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(startGetClientes());
    dispatch(startGetProveedores());
    dispatch(startGetEstadosTodos())
  },[dispatch])


  return (
    <CardPage titulo="Reportes">
      <Space>
        <Button onClick={() => history.push("/reportes/dia")} type="primary">
          Reporte Asistencias por Día
        </Button>
        <Button
          onClick={() => history.push("/reportes/General")}
          type="primary"
        >
          Reporte General
        </Button>
        <Button
          onClick={() => history.push("/reportes/pagosProveedor")}
          type="primary"
        >
          Pagos a Proveedor
        </Button>
        <Button
          onClick={() => history.push("/reportes/reportesPorMes")}
          type="primary"
        >
          Reportes x Mes
        </Button>
      </Space>
    </CardPage>
  );
};
