import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetOrdenesPagoPagar } from "../../../context/actions/ordenesPagoActions";
import CardPage from "../../../layout/CardPage";
import { Controles } from "./Controles";
import { OrdenesLista } from "./OrdenesLista";
import { OrdenesPagoCabecera } from "./OrdenesPagoCabecera";

export const Pagar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetOrdenesPagoPagar());
  }, []);
  return (
    <CardPage>
      <Controles />
      <OrdenesPagoCabecera />
      <OrdenesLista />
    </CardPage>
  );
};
