import ModelPage from "../../../layout/ModelPage";
import FormaCampania from "../detalle/campanias/FormaCampania";
import { InfoCampania } from "./infoCampania/InfoCampania";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { startPostCampania } from "../../../context/actions/campaniasActions";
import CardPage from "../../../layout/CardPage";

export const SeccionInfoCampania = ({ nombreCampania }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { clienteID } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const { cliente } = useSelector((state) => state.clientesReducer);

  const handleRegresar = () => history.push(`/clientes/detalle/${clienteID}`);

  const handleOk = () => {
    dispatch(startPostCampania(clienteID, false));
    setOpenModal(false);
  };

  return (
    <CardPage
      titulo={`Campaña ${nombreCampania} del cliente ${cliente.nombreComercial}`}
      tituloBoton="Regresar"
      accionBoton={handleRegresar}
      tituloBoton2="Editar"
      accionBoton2={() => setOpenModal(true)}
    >
      <InfoCampania />

      <ModelPage
        titulo="Agregar Campaña"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <FormaCampania />
      </ModelPage>
    </CardPage>
  );
};
