import { EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { TagSelector } from "../../components/tagSelector/TagSelector";
import { cortaString } from "../../utils/cortaString";

export const useColumns = () => {
  const history = useHistory();

  const handleClick = ({ asistenciaID }) =>
    history.push(`/asistencias/editarAsistencia/${asistenciaID}`);

  const columns = [
    {
      title: "ID",
      dataIndex: "asistenciaID",
      key: "asistenciaID",
    },
    {
      title: "Fecha",
      dataIndex: "fechaCreacion",
      key: "fechaCreacion",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      render: (tag, record) => cortaString(record.nombre, 25),
    },
    // {
    //   title: "Validador",
    //   dataIndex: "validador1",
    //   key: "validador1",
    // },
    {
      title: "Servicio",
      dataIndex: "servicio",
      key: "servicio",
    },
    {
      title: "Cuenta",
      dataIndex: "cuenta",
      render: (tag, record) => cortaString(record.cuenta, 25),
    },
    {
      title: "Operador",
      dataIndex: "operador",
      key: "operador",
    },
    {
      title: "Estatus",
      render: (tag, record) => <TagSelector tag={tag} />,
    },
    // {
    //   title: "",
    //   width: 30,
    //   render: (text, record) => (
    //     <EditOutlined
    //       onClick={() => handleClick(record)}
    //       style={{ color: "#ca2228" }}
    //       className="eyeIcon"
    //     />
    //   ),
    // },
  ];

  return { columns };
};
