import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import {useUploadFile} from "../../detalle/infoOrdenPago/useUploadFile"
import ModelPage from "../../../../layout/ModelPage";

export const CargaPagoModalBoton = ({ showModal, setShowModal, ordenPagoID, setPagoCargado }) => {
    const { body, props, setBody, handleClick } = useUploadFile(1, ordenPagoID, setPagoCargado); // 1 = pago
  
    const ok = () => {
      handleClick();
      setShowModal(false);
    };
  
    const cancel = () => {
      setBody({});
      setShowModal(false);
    };
  
    //////////////
    return (
      <ModelPage
        titulo={`Cargar Comprobante de Pago de orden ${ordenPagoID}`}
        isVisible={showModal}
        handleOk={ok}
        handleCancel={cancel}
      >
        <div className="tituloPequeño">
          Agregue el comprobante de pago
        </div>
  
        <Upload {...props} style={{ width: "100%" }}>
          <Button
            style={{ textAlign: "left", marginTop: 20 }}
            icon={<UploadOutlined />}
          >
            Seleccione documento
          </Button>
        </Upload>
        <span style={{ marginLeft: 20 }}>{body.file?.name}</span>
      </ModelPage>
    );
  };