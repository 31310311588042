import { Checkbox, Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import { startPostCerateOrdenPago } from "../../../context/actions/ordenesPagoActions";
import { formatCurrency } from "../../../utils/formatCurrency";
import { ListaItemTitulo } from "./ListaItemTitulo";
import { dateFromString } from "../../../utils/dateFromString";

export const ProveedorAsistencias = ({ proveedorID }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [asistenciasSelected, setAsistenciasSelected] = useState([]);
  const [montoTotal, setMontoTotal] = useState(0);

  const { asistencias } = useSelector(
    (state) => state.ordenesPagoReducer.asistenciasElegibles2
  );
  const filtradas = asistencias.filter((a) => a.proveedorID === proveedorID);

  ///
  // const isDiabled = (asistencia) => {
  //   if (asistenciasSelected.length === 0) return false;
  //   return (
  //     asistenciasSelected.filter((a) => a.clienteID === asistencia.clienteID)
  //       .length === 0
  //   );
  // };

  const onChange = (target, asistencia) => {
    if (target.checked) {
      setAsistenciasSelected((state) => [
        ...state,
        {
          clienteID: asistencia.clienteID,
          asistenciaID: asistencia.asistenciaID,
          costo: asistencia.costo,
        },
      ]);
      setMontoTotal((monto) => monto + asistencia.costo);
    } else {
      const newList = asistenciasSelected.filter(
        (a) => a.asistenciaID !== asistencia.asistenciaID
      );
      setAsistenciasSelected(newList);
      setMontoTotal((monto) => monto - asistencia.costo);
    }
  };

  const handleClick = () => {
    let asistenciasIds = [];

    asistenciasSelected.map((a) => {
      asistenciasIds = [...asistenciasIds, a.asistenciaID];
    });
    const body = {
      asistenciasIds: asistenciasIds.toString(),
      proveedorID,
    };

    dispatch(startPostCerateOrdenPago(body)).then((ordenPagoID) =>
      history.push(`/ordenesPago/detalle/${ordenPagoID}`)
    );
  };

  ////////
  return (
    <>
      <ListaItemTitulo />
      {filtradas.map((a, i) => (
        <Row key={i} className="rowItemElemento">
          <Col span={2}>
            <Checkbox
              //disabled={isDiabled(a)}
              name={a.asistenciaID}
              onChange={({ target }) => onChange(target, a)}
            ></Checkbox>{" "}
            {a.asistenciaID}
          </Col>
          <Col span={2}>{dateFromString(a.fecha).soloFecha}</Col>
          <Col span={3}>{a.cliente}</Col>
          <Col span={4}>{a.campania}</Col>
          <Col span={4}>{a.servicio}</Col>
          <Col span={2}>{a.estatusFinalNombre}</Col>
          <Col span={5}>{a.ubicacion}</Col>
          <Col span={2} style={{ textAlign: "right" }}>
            {formatCurrency(a.costo)}
          </Col>
        </Row>
      ))}

      <Row style={{ marginTop: 10, marginLeft: 10 }}>
        <Col span={4}>
          <BotonSubmit
            disabled={asistenciasSelected.length === 0}
            tituloBoton="Generar OP"
            onClick={handleClick}
          />
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          Seleccionados: {asistenciasSelected.length}
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          Monto: {formatCurrency(montoTotal)}
        </Col>
      </Row>
    </>
  );
};
