import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Upload } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { useParams } from "react-router";
import BotonDivL from "../../../../../components/Boton/BotonDivL";
import BotonSubmit from "../../../../../components/Boton/BotonSubmit";
import { startGetDocumentoPago, startGetDocumentoPago2 } from "../../../../../context/actions/proveedoresActions";
import { useUploadFile2 } from "./useUploadFile2";

export const FilesPago = () => {
  const dispatch = useDispatch();
  const { proveedorID } = useParams();
  const { constanciaSituacionUri, caratulaEdoCtaUri } = useSelector(
    (state) => state.proveedoresReducer.proveedor
  );
  const { body, props1, props2, handleClick } = useUploadFile2(proveedorID);
  return (
    <>
      <Row gutter={10}>
        <Col>
          <div className="tituloPequeño">Constancia de situación fiscal</div>

          {constanciaSituacionUri !== "" && (
            <BotonDivL
              onClick={() =>
                dispatch(
                  startGetDocumentoPago2(proveedorID, "ConstanciaSituacionUri")
                )
              }
              tituloBoton="Descargar"
            />
          )}
          <Upload {...props1} style={{ width: "100%" }}>
            <Button
              style={{ textAlign: "left", marginTop: 20 }}
              icon={<UploadOutlined />}
            >
              Seleccione documento
            </Button>
          </Upload>
          <div>{body.constanciaSituacion?.name}</div>
        </Col>
        <Col>
          <div className="tituloPequeño">Carátula estado de cuenta</div>
          {caratulaEdoCtaUri !== "" && (
            <BotonDivL
              onClick={() =>
                dispatch(
                  startGetDocumentoPago2(proveedorID, "CaratulaEdoCtaUri")
                )
              }
              tituloBoton="Descargar"
            />
          )}
          <Upload {...props2} style={{ width: "100%" }}>
            <Button
              style={{ textAlign: "left", marginTop: 20 }}
              icon={<UploadOutlined />}
            >
              Seleccione documento
            </Button>
          </Upload>
          <div style={{ height: 30 }}>{body.caratulaEdoCta?.name}</div>
        </Col>
      </Row>

      <div style={{ marginTop: 10, width: 250 }}>
        <BotonSubmit onClick={handleClick} tituloBoton="Cargar Documentos" />
      </div>
    </>
  );
};
