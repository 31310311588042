import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatNumber } from "../../../utils/formatNumber";

export const AsistenciasTodas = () => {
  const {
    reportePorMes: { asistenciasTodas, asistenciasOP },
    verTodo,
  } = useSelector((state) => state.reportesReducer);

  if (!asistenciasTodas) return <div></div>;
  return (
    <div className="">
      <Row>
        {verTodo ? (
          <>
            <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
              Asistencias en el Mes
            </Col>
            <Col
              xs={5}
              md={4}
              lg={3}
              className="datoReporte"
              style={{ textAlign: "center" }}
            ></Col>

            <Col
              xs={5}
              md={4}
              lg={3}
              className="datoReporte"
              style={{ textAlign: "center" }}
            >
              {formatNumber(asistenciasTodas.asistenciasTotalCantidad, 0)}
            </Col>
            <Col
              xs={7}
              md={4}
              lg={4}
              className="datoReporte"
              style={{ textAlign: "right" }}
            >
              {formatCurrency(asistenciasTodas.asistenciasTotalMonto)}
            </Col>
          </>
        ) : (
          <>
            <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
         &nbsp;
            </Col>
            <Col
              xs={5}
              md={4}
              lg={3}
              className="datoReporte"
              style={{ textAlign: "center" }}
            ></Col>

          
          </>
        )}
      </Row>
      <Row>
        <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
          Asistencias con OP
        </Col>
        <Col
          xs={5}
          md={4}
          lg={3}
          className="datoReporte"
          style={{ textAlign: "center", backgroundColor: "#bcf74f" }}
        >
          {formatNumber(
            (100 * asistenciasOP.asistenciasConOPTotal) /
              asistenciasTodas.asistenciasTotalCantidad,
            2
          )}
          %
        </Col>
        {verTodo && (
          <>
            <Col
              xs={5}
              md={4}
              lg={3}
              className="datoReporte"
              style={{ textAlign: "center" }}
            >
              {formatNumber(asistenciasOP.asistenciasConOPTotal, 0)}
            </Col>
            <Col
              xs={7}
              md={4}
              lg={4}
              className="datoReporte"
              style={{ textAlign: "right" }}
            >
              {formatCurrency(asistenciasOP.asistenciasConOPTotalCosto)}
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
          Asistencias sin OP
        </Col>
        <Col
          xs={5}
          md={4}
          lg={3}
          className="datoReporte"
          style={{ textAlign: "center", backgroundColor: "#f7b74f" }}
        >
          {" "}
          {formatNumber(
            100 -
              (100 * asistenciasOP.asistenciasConOPTotal) /
                asistenciasTodas.asistenciasTotalCantidad,
            2
          )}
          %
        </Col>
        {verTodo && (
          <>
            <Col
              xs={5}
              md={4}
              lg={3}
              className="datoReporte"
              style={{ textAlign: "center" }}
            >
              {" "}
              {formatNumber(
                asistenciasTodas.asistenciasTotalCantidad -
                  asistenciasOP.asistenciasConOPTotal,
                0
              )}
            </Col>
            <Col
              xs={7}
              md={4}
              lg={4}
              className="datoReporte"
              style={{ textAlign: "right" }}
            >
              {formatCurrency(
                asistenciasTodas.asistenciasTotalMonto -
                  asistenciasOP.asistenciasConOPTotalCosto
              )}
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
