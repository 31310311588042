import { types } from "../types/types";

const init = {
  map: {},
  poligono: [
    // [-99.179346, 19.402743],
    // [-99.09288780900582, 19.510097732313014],
    // [-99.06815792014598, 19.38573022107647],
  ],
};
//
export const mapReducer = (state = init, action) => {
  switch (action.type) {
    case types.setMap:
      return {
        ...state,
        map: action.payload,
      };
    case types.addPoligono:
      const final = [...state.poligono, action.payload];

      return {
        ...state,
        poligono: final,
      };

    default:
      return state;
  }
};
