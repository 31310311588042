import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

////
export default function useColums() {
  const history = useHistory();

  const handleClick = ({ proveedorSucursalID, proveedorID }) =>
    history.push(
      `/proveedores/sucursalDetalle/${proveedorID}/${proveedorSucursalID}`
    );

  const sucursalesColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "telefono1",
      dataIndex: "telefono1",
    },
    {
      title: "telefono2",
      dataIndex: "telefono2",
    },
    {
      title: "telefono3",
      dataIndex: "telefono3",
    },
    {
      title: "comentarios",
      dataIndex: "comentarios",
    },
    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined
          onClick={() => handleClick(record)}
          style={{ color: "#ca2228" }}
          className="eyeIcon"
        />
      ),
    },
  ];

  return { sucursalesColumns };
}
