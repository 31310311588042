import { Col, Row } from "antd";
import React from "react";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { CajaRow3 } from "../../../../../components/cajaRow3/CajaRow3";

export const ProveedoresItem = ({ proveedor }) => {
  const { proveedor: proveedorNombre, cantidad, monto } = proveedor;
  return (
    <CajaRow3 nombre={proveedorNombre} cantidad={cantidad} monto={monto} />
  );
};
