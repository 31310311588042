import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  cleanClienteForm,
  startGetClientes,
  startPostCliente,
} from "../../../context/actions/clientesActions";
import CardPage from "../../../layout/CardPage";
import ModelPage from "../../../layout/ModelPage";
import FormaCliente from "../formaCliente/FormaCliente";
import Tabla from "./Tabla";

////
export default function Listado() {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetClientes());
    dispatch(cleanClienteForm());
  }, []);

  const handleAgregar = () => setOpenModal(true);
  const handleOk = () => {
    dispatch(startPostCliente());
    setOpenModal(false);
  };

  ////
  return (
    <CardPage
      titulo="Clientes"
      tituloBoton="Agregar"
      accionBoton={handleAgregar}
    >
      <Tabla />
      <ModelPage
        titulo="Agregar Cliente"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <FormaCliente />
      </ModelPage>
    </CardPage>
  );
}
