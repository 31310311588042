import React from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatNumber } from "../../../../utils/formatNumber";
import { Cuadro } from "../utils/Cuadro";
import { Spinner } from "../utils/Spinner";
import { useConOrdenPago } from "./useConOrdenPago";

export const ConOrdenPago = () => {
    const { asistencias, costo } = useConOrdenPago()

    const { loadingReporte } = useSelector(state => state.reportesReducer)
    if (loadingReporte)
        return <Spinner />


    return (
        <>
            <Cuadro
                backgroundColor={"#16545e"}
                titulo={<><div>Con orden de pago</div></>}
                cantidad={formatNumber(asistencias)}
                monto={formatCurrency(costo)}
            />
        </>
    );
};
