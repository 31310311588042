export const aniosOptions = (inicio, fin) => {
  let salida = [];
  for (let i = inicio; i <= fin; i++) {
    salida = [...salida, { texto: i.toString(), valor: i }];
  }
  return salida;
};


export const aniosOptions2 = (inicio, fin) => {
  let salida = [];
  for (let i = inicio; i <= fin; i++) {
    salida = [...salida, { label: i.toString(), value: i }];
  }
  return salida;
};
