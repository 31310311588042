import React from 'react'
import { TituloModal } from '../../../../../components/tituloModal/TituloModal'
import { Button, Drawer, Modal } from 'antd'
import { ContenidoDawer } from './_ContenidoDawer'

export const DrawerAsistencia = ({ open, setOpen, servicioPorValidarID }) => {

    return (
        <div>
            <Drawer
                size='large'
                open={open}
                onClose={() => setOpen(false)}
                footer={[
                    <Button danger size='small' key='back' onClick={() => setOpen(false)}>Cerrar</Button>
                ]}
                title={<TituloModal titulo="Datos de Servicio" />}
            >
                <ContenidoDawer setOpen={setOpen} servicioPorValidarID={servicioPorValidarID} />
            </Drawer>
        </div>
    )
}
