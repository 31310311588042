export const formatCurrencyDecimals = (number, decimals) => {
  if (isNaN(number)) return "";

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: decimals,
  });

  return formatter.format(number);
};
