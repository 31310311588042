import { Spin } from "antd";
import React, { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import sinImagen from "../../../../assets/SIN-IMAGEN.jpg";
import { URL_BASE_CLIENTES } from "../../../../constants/url";
import { startPostLogo } from "../../../../context/actions/clientesActions";
import { Imagen } from "./Imagen";
import { validaFile } from "./validaFile";

export const LogoEmpresa = () => {
  const { clienteID } = useParams();
  const dispatch = useDispatch();

  const { showLogo } = useSelector((state) => state.clientesReducer);
  const imgUrl = `${URL_BASE_CLIENTES}/GetLogo/${clienteID}`;
  const inputFile = useRef(null);

  const handleFile = async ({ target: { files } }) => {
    if (validaFile(files)) {
      dispatch(startPostLogo({ file: files[0], clienteID }));
    }
  };

  const handleClick = () => inputFile.current.click();

  /////
  return (
    <div style={{ marginTop: 10, textAlign: "center", cursor: "pointer" }}>
      {showLogo ? (
        <Imagen onClick={handleClick} imgUrl={imgUrl} />
      ) : (
        <Spin style={{ marginTop: 30 }} />
      )}
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleFile}
        multiple={false}
      />
    </div>
  );
};
