import { Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelValor from "../../../../../../components/label/LabelValor";
import { AutorizarPago2Modal } from "./AutorizarPago2Modal";
import { AutorizarPagoModal } from "./AutorizarPagoModal";

export const AutorizacionPago = () => {
  const { email } = useSelector((state) => state.userReducer.user);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const {
    estatusOrdenPagoID,
    fechaAutorizacionPago,
    usuarioAutorizacionPago,
    fechaAutorizacionPago2,
    usuarioAutorizacionPago2,
  } = useSelector((state) => state.ordenesPagoReducer.ordenPago);

  ///////////////////////
  if (estatusOrdenPagoID < 4) return <div></div>; // aun no se llega a este punto
  //
  if (estatusOrdenPagoID === 4 && !fechaAutorizacionPago) {
    if (
      email !== "j.moragrega@escotel.com.mx" &&
      email !== "angelica.herrera@escotel.com.mx" &&
      email !== "pat9000@hotmail.com"
    )
      return (
        <div style={{ marginTop: 20, color: "#ca2228" }}>
          No puede autorizar
        </div>
      );
    else
      return (
        <>
          <BotonSubmit
            style={{ marginTop: 8 }}
            tituloBoton="Autorizar Primer Nivel"
            onClick={() => setShowModal(true)}
          />
          {!fechaAutorizacionPago && (
            <AutorizarPagoModal
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </>
      );
  }

  if (estatusOrdenPagoID === 4 && fechaAutorizacionPago) {
    return (
      <>
        <Row gutter={10}>

          <Col span={12}>
            <LabelValor
              label="Autorización primer nivel"
              valor={`${fechaAutorizacionPago} - ${usuarioAutorizacionPago}`}
            />
          </Col>

          <Col span={12}>
            {email === "angelica.herrera@escotel.com.mx" ? (
              <>
                <BotonSubmit
                  style={{ marginTop: 19 }}
                  tituloBoton="Autorizar Segundo Nivel"
                  onClick={() => setShowModal2(true)}
                />
                <AutorizarPago2Modal
                  showModal={showModal2}
                  setShowModal={setShowModal2}
                />
              </>
            ) : (
              <div style={{ marginTop: 20, color: "#ca2228" }}>
                No puede autorizar 2°
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
  return (
    <Row gutter={10}>
      <Col span={12}>
        <LabelValor
          label="Autorización primer nivel"
          valor={`${fechaAutorizacionPago} - ${usuarioAutorizacionPago}`}
        />
      </Col>
      <Col span={12}>
        <LabelValor
          label="Autorización segundo nivel"
          valor={`${fechaAutorizacionPago2} - ${usuarioAutorizacionPago2}`}
        />
      </Col>
    </Row>
  );
};
