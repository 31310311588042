
export const localCarreteroProveedorReduce = (asistencias, proveedorID) => {
    var array = asistencias.filter(a => a.proveedorID === proveedorID)
    var result = [];
    array.reduce((res, value) => {
        if (!res[value.localCarretero]) {
            res[value.localCarretero] = { localCarretero: value.localCarretero, costo: 0, cantidad: 0 };
            result.push(res[value.localCarretero])
        }
        res[value.localCarretero].costo += value.costo;
        res[value.localCarretero].cantidad++;

        return res;
    }, {})
    return result
}
