import React from 'react'
import { useSelector } from 'react-redux'
import { LocalCarreteroItem } from './LocalCarreteroItem'
import { localCarreteroProveedorReduce } from './localCarreteroProveedorReduce'


export const LocalCarretero = ({ proveedor }) => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const listado = localCarreteroProveedorReduce(asistenciasMes, proveedor.Id)

    return (
        <div style={{ padding: 5, borderBottom: '1px solid #ccc' }}>
            {listado.map((reg, i) => <LocalCarreteroItem reg={reg} key={i} i={i} />)}
        </div>
    )
}
