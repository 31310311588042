import {
  URL_BASE_CLIENTES,
  URL_DELETE_DOCUMENTO,
  URL_DOWNLOAD_DOCUMENTO,
  URL_GET_CAMPANIA_SERVICIOS,
  URL_GET_DOCUMENTOS,
  URL_POST_CAMPANIA,
  URL_POST_CAMPANIA_SERVICIOS,
  URL_POST_SCRIPT,
  URL_UPDATE_CAMPANIA,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { dele, get, getFile, post } from "../../utils/fetch";
import { types } from "../types/types";
import { startGetCliente } from "./clientesActions";
import { setLoadingCampaniaServicios } from "./loadingActions";
import { notification } from "./notificationActions";

/////////////////////////////////////////////////////////////////////
export const startPostCampania = (clienteID, esNuevo = true) => {
  return async (dispatch, getState) => {
    const { campania } = getState().campaniasReducer;
    const body = {
      ...campania,
      clienteID,
    };

    const url = esNuevo ? URL_POST_CAMPANIA : URL_UPDATE_CAMPANIA;

    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetCliente(clienteID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Campaia",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const setScript = (texto) => ({
  type: types.setScript,
  payload: texto,
});

export const selectCampaniaScript = (campaniaID) => {
  return (dispatch, getState) => {
    const { campanias } = getState().clientesReducer.cliente;
    let textoScript = "";
    campanias.map((c) => {
      if (c.campaniaID == campaniaID) {
        textoScript = c.textoScript;
      }
    });

    dispatch(setScript({ textoScript, campaniaID }));
  };
};

/////////////////////////////////////////////////////////////////////////////////

export const startGetCampania = (campaniaID) => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_CLIENTES}/GetCampania/${campaniaID}`);
    if (result.code === 0) {
      dispatch(storeCampania(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Clientes",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeCampania = (campania) => ({
  type: types.storeCampania,
  payload: campania,
});

/////////////////////////////////////////////////////////////////////////////////

export const cambiarEstatusCampania = (campaniaID) => {
  return async (dispatch) => {
    const result = await get(
      `${URL_BASE_CLIENTES}/CampaniaEstatus/${campaniaID}`
    );
    if (result.code === 0) {
      dispatch(startGetCampania(campaniaID));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Clientes",
            `${result.error}`
          )
        )
      );
    }
  };
};

/////////////////////////////////////////////////////////////////////////////////

export const startPostScript = () => {
  return async (dispatch, getState) => {
    const { script } = getState().campaniasReducer;
    const { clienteID } = getState().clientesReducer.cliente;

    const result = await post(URL_POST_SCRIPT, script);
    console.log(script);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      await dispatch(startGetCliente(clienteID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post script",
            `${result.error}`
          )
        )
      );
    }
  };
};
/////////////////////////////////////////////////////////////////////
export const setValueCampania = (payload) => ({
  type: types.setValueCampania,
  payload,
});

export const cleanCampaniaForm = () => ({
  type: types.cleanCampaniaForm,
  payload: null,
});

/////////////////////////////////////////////////////////////////////
export const pickTipoAsistencia = (tipoAsistenciaID) => ({
  type: types.pickTipoAsistencia,
  payload: tipoAsistenciaID,
});

export const updateServicioID = (body) => {
  return (dispatch, getState) => {
    const { servicios } = getState().campaniasReducer.postServicios;
    let newServicios = [];
    if (body.accion) {
      newServicios = [
        ...servicios,
        {
          servicioID: body.servicioID,
          eventos: 0,
          costoEvento: 0,
          km: 0,
          condicionesEspeciales: "",
        },
      ];
    } else {
      newServicios = servicios.filter((s) => s.servicioID !== body.servicioID);
    }
    dispatch(updateServicios(newServicios));
  };
};

const updateServicios = (payload) => ({
  type: types.updateServicios,
  payload,
});

export const updateServicioDato = (body) => {
  return (dispatch, getState) => {
    const { servicios } = getState().campaniasReducer.postServicios;
    let newServicios = servicios.map((s) => {
      if (s.servicioID === body.servicioID) {
        return { ...s, ...body };
      } else {
        return { ...s };
      }
    });

    dispatch(updateServicios(newServicios));
  };
};

///////////////////////////////////////////////////////////////////////

export const startPostCampaniaServicios = () => {
  return async (dispatch, getState) => {
    const { postServicios } = getState().campaniasReducer;
    const result = await post(URL_POST_CAMPANIA_SERVICIOS, postServicios);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Servicios",
            `${result.error}`
          )
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////

export const startLoadCampaniaServicios = (body) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingCampaniaServicios(true));
    const url = `${URL_GET_CAMPANIA_SERVICIOS}/${body.campaniaID}/${body.tipoAsistenciaID}`;
    const result = await get(url, body);
    if (result.code === 0) {
      dispatch(initPostServicios(body));
      dispatch(updatePostServiciosFull(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get campania servicios",
            `${result.error}`
          )
        )
      );
    }

    dispatch(setLoadingCampaniaServicios(false));
  };
};

const updatePostServiciosFull = (payload) => ({
  type: types.updatePostServiciosFull,
  payload,
});
export const initPostServicios = (payload) => ({
  type: types.initPostServicios,
  payload,
});

//////////////////

export const cleanCampaniaName = () => ({
  type: types.cleanCampaniaName,
  payload: null,
});

export const startLoadDocumentos = (clienteID) => {
  return async (dispatch) => {
    const result = await get(`${URL_GET_DOCUMENTOS}/${clienteID}`);
    if (result.code === 0) {
      dispatch(storeFiles(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get documentos",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const startDownloadDocumento = (
  campaniaDocumentoID,
  nombreDocumento,
  nombreArchivo
) => {
  return async (dispatch) => {
    const url = `${URL_DOWNLOAD_DOCUMENTO}/${campaniaDocumentoID}`;
    const nombreFinal = `${nombreDocumento}-${nombreArchivo}`;

    const result = await getFile(url, nombreFinal);
    if (result.code !== 0) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Download documento",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const startDeleteFile = (campaniaDocumentoID, clienteID) => {
  return async (dispatch) => {
    const result = await dele(`${URL_DELETE_DOCUMENTO}/${campaniaDocumentoID}`);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startLoadDocumentos(clienteID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Dele documento",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeFiles = (payload) => ({
  type: types.storeFiles,
  payload,
});
