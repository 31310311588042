import { Col, Row } from "antd";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  startDeleteFile,
  startDownloadDocumento,
} from "../../../../context/actions/campaniasActions";
import { useParams } from "react-router-dom";
//
export default function Item({ file }) {
  const dispatch = useDispatch();
  const { clienteID } = useParams();
  const { campaniaDocumentoID, url, nombre, campania, esConfidencial } = file;

  const handleDownload = () =>
    dispatch(startDownloadDocumento(campaniaDocumentoID, nombre, url));

  const handleDelete = () =>
    dispatch(startDeleteFile(campaniaDocumentoID, clienteID));
  return (
    <Row gutter={10} className="rowTabla">
      <Col span={5}>{nombre}</Col>
      <Col span={5}>{campania}</Col>
      <Col span={3}>{esConfidencial === true ? "Si" : "No"}</Col>
      <Col span={3} style={{ textAlign: "right" }}>
        <CloudDownloadOutlined
          onClick={handleDownload}
          className="icon"
          style={{ color: "blue" }}
        />
        <DeleteOutlined
          onClick={handleDelete}
          className="icon"
          style={{ color: "#ca2228" }}
        />
      </Col>
    </Row>
  );
}
