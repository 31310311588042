import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LabelTelefono } from "../../../components/input/LabelTelefono";
import LabelText from "../../../components/input/LabelText";
import LabelTextarea from "../../../components/input/LabelTextarea";
import TipoIvaSelect from "../../../components/select/TipoIvaSelect";
import { setValueCliente } from "../../../context/actions/clientesActions";

////////////////
export default function FormaCliente() {
  const dispatch = useDispatch();
  const {
    razonSocial,
    rfc,
    contactoNombre,
    contactoEmail,
    direccionFisica,
    nombreComercial,
    tipoIva,
    comentarios,
    contactoTelefono1,
    contactoTelefono2,
    contactoTelefono3,
    tipoTelefono1,
    tipoTelefono2,
    tipoTelefono3,
  } = useSelector((state) => state.clientesReducer.cliente);
  //

  const handleChange = ({ target }) => {
    dispatch(setValueCliente(target));
  };

  const handleChangeTipo = (value, name) => {
    const target = {
      name,
      value,
    };
    dispatch(setValueCliente(target));
  };
  ////////////////
  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelText
            label="Razón social"
            name="razonSocial"
            onChange={handleChange}
            value={razonSocial}
          />
        </Col>
        <Col span={8}>
          <LabelText
            label="RFC"
            name="rfc"
            onChange={handleChange}
            value={rfc}
          />
        </Col>
        <Col span={8}>
          <LabelText
            label="Nombre comercial"
            name="nombreComercial"
            onChange={handleChange}
            value={nombreComercial}
          />
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelText
            label="Contacto"
            name="contactoNombre"
            onChange={handleChange}
            value={contactoNombre}
          />
        </Col>
        <Col span={12}>
          <LabelText
            label="Email"
            name="contactoEmail"
            onChange={handleChange}
            value={contactoEmail}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelTelefono
            onChangeTipo={(val) => handleChangeTipo(val, "tipoTelefono1")}
            onChange={handleChange}
            name="contactoTelefono1"
            label="Teléfono 1"
            value={contactoTelefono1}
            valueTipo={tipoTelefono1}
          />
        </Col>

        <Col span={12}>
          <LabelTelefono
            onChangeTipo={(val) => handleChangeTipo(val, "tipoTelefono2")}
            onChange={handleChange}
            name="contactoTelefono2"
            label="Teléfono 2"
            value={contactoTelefono2}
            valueTipo={tipoTelefono2}
          />
        </Col>
        <Col span={12}>
          <LabelTelefono
            onChangeTipo={(val) => handleChangeTipo(val, "tipoTelefono3")}
            onChange={handleChange}
            name="contactoTelefono3"
            label="Teléfono 3"
            value={contactoTelefono3}
            valueTipo={tipoTelefono3}
          />
        </Col>
        <Col span={12}>
          <TipoIvaSelect
            label="Tipo de iva"
            name="tipoIva"
            onChange={(val) => handleChangeTipo(val, "tipoIva")}
            value={tipoIva}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col span={24}>
          <LabelTextarea
            label="Dirección física"
            name="direccionFisica"
            onChange={handleChange}
            value={direccionFisica}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col span={24}>
          <LabelTextarea
            label="Comentarios"
            name="comentarios"
            onChange={handleChange}
            value={comentarios}
          />
        </Col>
      </Row>
    </>
  );
}
