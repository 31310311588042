import { getMonthNow } from "../../utils/getMonthNow";
import { getYearNow } from "../../utils/getYearNow";
import { types } from "../types/types";

const init = {
  asistencias: [],
  asistencia: {},
  proveedores: [],
  sucursales: [],
  proveedorSelected: "",
  costoCambioEspecial: 0,
  conclusionCambioEspecial: 0,
  sucursalSelected: 0,
  estatusFinalSelected: 0,
  filtroAsistencia: {
    fechaInicio: new Date(),
    fechaFin: new Date(),
    proveedorId: null,
    clienteId: null,
  },
  servicioAsistencia: {},
  asistenciasLoading: false,
};
export const asistenciasReducer = (state = init, action) => {
  switch (action.type) {
    case types.setAsistenciasLoading:
      return {
        ...state,
        asistenciasLoading: action.payload,
      };

    case types.setEstatusFinalSelected:
      return {
        ...state,
        estatusFinalSelected: action.payload,
      };
    case types.setSucursalSelected:
      return {
        ...state,
        sucursalSelected: action.payload,
      };
    case types.setCostoCambioEspecial:
      return {
        ...state,
        costoCambioEspecial: action.payload,
      };
    case types.setConclusionCambioEspecial:
      return {
        ...state,
        conclusionCambioEspecial: action.payload,
      };
    case types.setProveedorSelected:
      return {
        ...state,
        proveedorSelected: action.payload,
      };
    case types.storeSucursalesByProveedor:
      return {
        ...state,
        sucursales: action.payload,
      };
    case types.clearProveedoresByTexto:
      return {
        ...state,
        proveedores: [],
      };
    case types.storeProveedoresByText:
      return {
        ...state,
        proveedores: action.payload,
      };
    case types.storeServicioAsistencia:
      return {
        ...state,
        servicioAsistencia: action.payload,
      };

    case types.setAsistenciaDato:
      return {
        ...state,
        asistencia: {
          ...state.asistencia,
          ...action.payload,
        },
      };

    case types.storeAsistencia:
      return {
        ...state,
        asistencia: action.payload,
      };

    case types.storeServicios:
      return {
        ...state,
        asistencias: action.payload,
      };
    case types.setFiltroAsistencia: {
      return {
        ...state,
        filtroAsistencia: {
          ...state.filtroAsistencia,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};
