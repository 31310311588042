import React from "react";
import { useSelector } from "react-redux";
import LabelSelect from "./LabelSelect";

export default function CampaniasSelect({ value, onChange }) {
  const { campanias } = useSelector((state) => state.clientesReducer.cliente);
  let opciones = [];
  campanias.map((c) => {
    opciones = [...opciones, { texto: c.nombre, valor: c.campaniaID }];
  });
  return (
    <LabelSelect
      name="campaniaID"
      value={value}
      onChange={onChange}
      label="Campaña"
      opciones={opciones}
    />
  );
}
