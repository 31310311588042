import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import { startChangeEstatusFinal } from "../../../../context/actions/asistenciasActions";
import ModelPage from "../../../../layout/ModelPage";
import { Forma } from "./Forma";
import { cambioConclusion } from "../../../../constants/cons";

export const CambioConclusion = () => {
  const { email } = useSelector((state) => state.userReducer.user);

  const { asistenciaID } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleOk = () => {
    dispatch(startChangeEstatusFinal(asistenciaID));
    setOpenModal(false);
  };

  //// modificar para crear roles
  if (!cambioConclusion.includes(email)) return <></>;

  //
  return (
    <>
      <BotonDivL
        tituloBoton="Cambiar Conclusión"
        onClick={() => setOpenModal(true)}
      />
      <ModelPage
        width={800}
        titulo="Cambiar Conclusión"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <Forma />
      </ModelPage>
    </>
  );
};
