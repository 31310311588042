import { types } from "../types/types";

const init = {
  bancos: [],
  listas: [],
  listaSelected: {},
  listaCampos: [],
  loadingCampos: false,
};
//
export const listasReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeListaCampos:
      return {
        ...state,
        listaCampos: action.payload,
      };
    case types.setLoadingCampos:
      return {
        ...state,
        loadingCampos: action.payload,
      };

    case types.setListaCampos:
      return {
        ...state,
        listaCampos: action.payload,
      };

    case types.setListaSelected:
      return {
        ...state,
        listaSelected: action.payload,
      };
    case types.storeListas:
      return {
        ...state,
        listas: action.payload,
      };
    case types.storeBancos:
      return {
        ...state,
        bancos: action.payload,
      };

    default:
      return state;
  }
};
