import { Timeline } from "antd";
import { dateFromString } from "../../../../utils/dateFromString";

export default function DatosHistorial({ historial }) {
  return (
    <Timeline mode="left">
      {historial.map((x, i) => (
        <Timeline.Item color={"gray"} key={i}>
          <p>
            <span style={{ color: "#4c66cf" }}>
              {dateFromString(x.fecha).dateStep}
            </span>{" "}
            {"->"} <span style={{ color: "#e62929" }}>{x.usuarioNombre}</span>:{" "}
            <span style={{ color: "#4ba3a3" }}>{x.tipo}</span> {"->"}{" "}
            <span style={{ color: "#5e5756" }}>{x.comentarios}</span>
          </p>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}
