export const tiposTextoValor = (arr) => {
  let salida = [];
  arr.map((tipo) => {
    salida = [
      ...salida,
      {
        texto: tipo.nombre,
        valor: tipo.tipoAsistenciaID,
      },
    ];
  });

  return salida;
};
