import { Select, Form, Tag } from "antd";
import useMunicipiosEstados from "../../hooks/useMunicipiosEstados";

export const SelecMunicipioOrigenDestino = ({ numberLp }) => {
  const { arrayEstadosMunicipio } = useMunicipiosEstados()


  const tagRender = (props, color) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };
  const props = {
    allowClear: true,
    mode: 'multiple',
    options: arrayEstadosMunicipio,
    showSearch: true,
  }
  return (
    <>
      <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "30px"}}>

        <Form.Item name={`origen${numberLp}`} label={`Municipio Origen LP${numberLp}`}>
          <Select tagRender={(props) => tagRender(props, "blue")} {...props} />
        </Form.Item>

        <Form.Item name={`destino${numberLp}`} label={`Municipio Destino LP${numberLp}`}>
          <Select tagRender={(props) => tagRender(props, "red")} {...props} />
        </Form.Item>

      </div>
    </>
  )
}