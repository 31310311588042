import { useSelector } from "react-redux";
import CardPage from "../../../layout/CardPage";
import { AsistenciasTodas } from "./_AsistenciasTodas";
import { AsistenciasConOP } from "./_AsistenciasConOP";
import Loading from "../../../components/loading/Loading";
import { Controles } from "./controles/Controles";
import { ChartTotales } from "./chartTotales/ChartTotales";
import { Col, Row } from "antd";
import { ChartDesglose } from "./chartDesglose/ChartDesglose";

export const ReportePorMes = () => {
  const { loadingReporte } = useSelector((state) => state.reportesReducer);

  //
  return (
    <CardPage titulo="Reporte por Mes">
      <Controles />

      {loadingReporte ? (
        <Loading texto="Cargando Reporte..." />
      ) : (
        <>
          <Row>
           
            <Col span={3} style={{padding: 10}}>
              <ChartTotales />
            </Col>
            <Col span={21} style={{paddingTop: 40}}>
              <AsistenciasTodas />
            </Col>
          </Row>

          <Row>
           
           <Col span={3} style={{padding: 10}}>
             <ChartDesglose />
           </Col>
           <Col span={21} style={{paddingTop: 10}}>
           <AsistenciasConOP />
           </Col>
         </Row>

    
        </>
      )}
    </CardPage>
  );
};
