import { Col, Divider, Row } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cleanServicioSelected } from "../../../context/actions/serviciosActions";
import { startSelectTipoAsistencia } from "../../../context/actions/tiposAsistenciaActions";
import CardPage from "../../../layout/CardPage";
import Cabecera from "./cabecera/Cabecera";
import CamposServicio from "./camposServicio/CamposServicio";
import DatosServicio from "./datosServicio/DatosServicio";
import Servicios from "./servicios/Servicios";

export default function Detalle() {
  const { tiposAsistencia } = useSelector(
    (state) => state.tiposAsistenciaReducer
  );

  const { servicios } = useSelector(
    (state) => state.tiposAsistenciaReducer.tipoAsistenciaSelected
  );

  const handleRegresar = () => {};
  const { tipoAsistenciaID } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startSelectTipoAsistencia(tipoAsistenciaID));
    dispatch(cleanServicioSelected());
  }, []);

  if (tiposAsistencia.length === 0 || !servicios) return <div></div>;

  return (
    <CardPage
      titulo="Información de Tipo de Servicio"
      tituloBoton="Regresar"
      accionBoton={handleRegresar}
    >
      <Row gutter={[20, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <Cabecera />
          <Servicios />
        </Col>
        <Col span={16}>
          <DatosServicio />
          <Divider />
          <CamposServicio />
        </Col>
      </Row>
    </CardPage>
  );
}
