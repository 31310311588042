import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Row } from "antd";

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MAPBOX_TOKEN } from "../../../../../constants/cons";
//import { setGeoDataOrigen } from "../../../../context/actions/capturaActions";
import geoFetch from "../../../../../utils/geoFetch";
import { DireccionOrigen } from "./DireccionOrigen";
import { fnDivideFeature } from "./fnDivideFeature";
import { SelectSucursal } from "./SelectSucursal.js.js";
import { InputLatLng } from "./InputLatLng";
import { InputTextSerach } from "./InputTextSerach";
import { LatLng } from "./LatLng";
import { Sitios } from "./Sitios";
import { startPostPin } from "../../../../../context/actions/proveedoresActions";
import { setMarkers } from "./setMarkers";
import { useParams } from "react-router-dom";
import { isNull } from "../../../../../utils/isNull";

mapboxgl.accessToken = MAPBOX_TOKEN;

export const Pines = () => {
  const dispatch = useDispatch();

  const { proveedorSucursalID } = useParams();
  const { proveedorSucursales } = useSelector(
    (state) => state.proveedoresReducer.proveedor
  );

  const map = useRef(null);
  const mapContainer = useRef(null);
  const marker = useRef(null);

  const [textoLatLng, setTextoLatLng] = useState("");
  const [textoBusca, setTextoBusca] = useState("");

  const [sitios, setSitios] = useState([]);
  const [longitud, setLongitud] = useState(-99.201963);
  const [latitud, setLatitud] = useState(19.413631);
  const [zoom, setZoom] = useState(14);
  const [direccion, setDireccion] = useState({});
  //
  const debounedref = useRef(null);

  /// INICIAL
  //////////////////////////////////////////////

  useLayoutEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      controls: true,
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [longitud, latitud],
      zoom: zoom,
    });
  }, []);

  useEffect(() => {
    map.current.addControl(new mapboxgl.NavigationControl());
    marker.current = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([longitud, latitud])
      .addTo(map.current);

    marker.current.on("dragend", onDragEnd);

    map.current.on("move", () => {
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, []);

  /////// marker inicial
  useEffect(() => {
    proveedorSucursales.map((s) => {
      if (s.proveedorSucursalID == proveedorSucursalID) {
        if (!isNull(s.lat) && !isNull(s.lng)) {
          map.current.flyTo({
            center: [s.lng, s.lat],
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          });
          marker.current.setLngLat([s.lng, s.lat]);
        }
      }
    });
  }, [proveedorSucursales]);

  /// para el drag and drop
  //////////////////////////////////////////////
  async function onDragEnd() {
    setTextoLatLng("");
    setTextoBusca("");
    const { lng, lat } = await marker.current.getLngLat();
    setLatitud(lat);
    setLongitud(lng);
    getAddressByPin(lng, lat);
    setSitios([]);
  }

  /// cuando hacen busqueda texto
  //////////////////////////////////////////////
  const handleSearch = ({ target }) => {
    setTextoBusca(target.value);
    if (debounedref.current) clearTimeout(debounedref.current);
    debounedref.current = setTimeout(async () => {
      try {
        const { data } = await geoFetch(`${target.value}.json`, {
          params: { proximity: [longitud, latitud] },
        });
        setSitios(data.features);
      } catch (e) {
        console.log(e);
      }
    }, 1500);
  };

  /// Sacamos la direccion desde un PIN
  //////////////////////////////////////////////
  const getAddressByPin = async (lng, lat) => {
    try {
      const { data } = await geoFetch(`${lng},${lat}.json`, {
        params: { limit: 1 },
      });
      const latLng = {
        latOrigen: lat.toString(),
        lngOrigen: lng.toString(),
      };

      const salida = { ...fnDivideFeature(data.features[0]), ...latLng };
      setBody(salida);
    } catch (e) {
      console.log(e);
    }
  };

  /// cuando ponen lat y lng directo
  //////////////////////////////////////////////
  const handleLatLng = ({ target }) => {
    setTextoLatLng(target.value);
    setTextoBusca("");

    if (target.value !== "") {
      const arr = target.value.split(",");
      if (arr.length === 2) {
        const newLatitud = Number(arr[0]);
        const newlongitud = Number(arr[1]);
        setLatitud(newLatitud);
        setLongitud(newlongitud);
        getAddressByPin(newlongitud, newLatitud);
        map.current.flyTo({
          center: [newlongitud, newLatitud],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });

        marker.current.setLngLat([newlongitud, newLatitud]);
      }
    }
  };

  /// En la busqueda el click
  //////////////////////////////////////////////
  const handleSitioClick = (sitio) => {
    setTextoLatLng("");
    map.current.flyTo({
      center: sitio.center,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });

    marker.current.setLngLat(sitio.center);
    setLatitud(sitio.center[1]);
    setLongitud(sitio.center[0]);
    setSitios([]);
    const latLng = {
      latOrigen: sitio.center[1].toString(),
      lngOrigen: sitio.center[0].toString(),
    };
    const salida = { ...fnDivideFeature(sitio), ...latLng };

    setBody(salida);
  };

  const setBody = (salida) => {
    setDireccion({
      ...direccion,
      ...salida,
    });
  };

  const handleSucursal = (val) => {
    setDireccion({
      ...direccion,
      proveedorSucursalID: val,
    });
  };

  const handleEstablecer = () => {
    const body = {
      ...direccion,
      proveedorSucursalID,
    };
    dispatch(startPostPin(body));
  };

  //////////////////////////////////////////////
  return (
    <>
      <div ref={mapContainer} style={styles.mapContainer}>
        <div style={styles.dataContainer}>
          <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
            <InputTextSerach
              textoBusca={textoBusca}
              handleSearch={handleSearch}
            />
            <InputLatLng
              handleLatLng={handleLatLng}
              textoLatLng={textoLatLng}
            />
            <LatLng latitud={latitud} longitud={longitud} zoom={zoom} />

            <SelectSucursal
              onChange={handleSucursal}
              onClick={handleEstablecer}
            />

            <DireccionOrigen direccionOrigen={direccion.direccionOrigen} />
          </Row>
          <Sitios sitios={sitios} handleSitioClick={handleSitioClick} />
        </div>
      </div>
    </>
  );
};

const styles = {
  dataContainer: {
    zIndex: 9999,
    position: "relative",
    width: "90%",
    paddingLeft: 10,
  },
  mapContainer: {
    height: 470,
    width: "100%",
    marginBottom: 10,
  },
};
