import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  setPagina,
  startGetProveedor,
} from "../../../context/actions/proveedoresActions";
import { startLoadDocumentosProveedor } from "../../../context/actions/proveedoresFilesActions";
import Folder from "./folder/Folder";
import InfoProveedor from "./infoProveedor/InfoProveedor";

export default function Detalle() {
  const { proveedorID } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetProveedor(proveedorID));
    dispatch(startLoadDocumentosProveedor(proveedorID));
    dispatch(setPagina("sucursales"));
  
  }, []);

  return (
    <>
      <InfoProveedor />
      <Folder />
    </>
  );
}
