import { types } from "../types/types";
const init = {
    loadingCampaniaServicios:false,
    loadingCampos: false
}
export const loadingReducer = (state = init, action) => {
  switch (action.type) {
    case types.setLoadingCampaniaServicios:
      return {
        ...state,
        loadingCampaniaServicios: action.payload,
      };

      case types.setLoadingCampos:
      return {
        ...state,
        loadingCampos: action.payload,
      };

    default:
      return state;
  }
};
