import {Alert, Button, Spin} from "antd";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {cambiarEstatusProveedor} from "../../../../context/actions/proveedoresActions";

export const EstatusProveedor = () => {
  const dispatch = useDispatch();
  const {proveedorID} = useParams();
  const {proveedor: {estaActivo}, cambiandoEstatus} = useSelector(
    (state) => state.proveedoresReducer
  );

  const handleAction = () => dispatch(cambiarEstatusProveedor(proveedorID));

  if (cambiandoEstatus) return <div style={{textAlign: 'right', paddingRight: 30, marginTop: 10}}><Spin/></div>

  if (estaActivo)
    return (
      <Alert
        type='success'
        message='Proveedor Activo'
        action={
          <Button onClick={handleAction} size='small' type='text'>
            Desactivar
          </Button>
        }
      />
    );

  return (
    <Alert
      type='error'
      message='Proveedor Inactivo'
      action={
        <Button onClick={handleAction} size='small' type='text'>
          activar
        </Button>
      }
    />
  );
};
