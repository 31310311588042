import { Col, Row } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import BotonDiv2 from "../../../../components/Boton/BotonDiv2";
import LabelSelect from "../../../../components/select/LabelSelect";
import {
  startAddOrdenPagoRow,
  startGetOrdenPagoItemsXLS,
} from "../../../../context/actions/ordenesPagoActions";
import { formatCurrency } from "../../../../utils/formatCurrency";

export const SelectAsistenciasElegibles = () => {
  const dispatch = useDispatch();
  const { ordenPagoID } = useParams();
  const [asistenciaID, setAsistenciaID] = useState();
  const {
    listaAsistenciasElegibles,
    ordenPago: { estatusOrdenPagoID, serviciosCount, total },
  } = useSelector((state) => state.ordenesPagoReducer);

  const handleClick = () => {
    if (asistenciaID) {
      dispatch(startAddOrdenPagoRow({ asistenciaID, ordenPagoID }));
      setAsistenciaID(null);
    }
  };

  const handleXLS = () => {
    dispatch(startGetOrdenPagoItemsXLS(ordenPagoID));
  };

  //////////////////////////
  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={3}>Registros: {serviciosCount}</Col>
      <Col span={3}>Total : {formatCurrency(total)}</Col>
      <Col span={3}></Col>
      <Col span={3}>
        <BotonDivL tituloBoton="Descargar XLS" onClick={handleXLS} />
      </Col>
      <Col span={8}>
        <LabelSelect
          onChange={(val) => setAsistenciaID(val)}
          placeholder="servicios elegibles..."
          opciones={listaAsistenciasElegibles}
          value={asistenciaID}
        />
      </Col>

      {estatusOrdenPagoID < 3 && (
        <Col span={3}>
          <BotonDiv2 onClick={handleClick} tituloBoton="Agregar" />
        </Col>
      )}
    </Row>
  );
};
