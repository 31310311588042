import { SyncOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const SyncIcon = ({ onClick }) => {
  return (
    <SyncOutlined
      onClick={onClick}
      style={{ color: "#ca2228", fontSize: 20 }}
      className="eyeIcon"
    />
  );
};
