import { DatePicker } from "antd";
import moment from "moment";
import React from "react";

export default function LabelDateTime({
  label,
  name,
  onChange,
  value,
  placeholder,
}) {
  const handleChange = (val, value) => {
    const body = {
      target: {
        name,
        value,
      },
    };

    onChange(body);
  };

  return (
    <>
      <div className="labelImput">{label}</div>
      <DatePicker
        style={{ width: "100%" }}
        name={name}
        onChange={handleChange}
        value={moment(value)}
        placeholder={placeholder}
      />
    </>
  );
}
