import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { URL_BASE_ORDENESPAGO } from "../../../../constants/url";
import { notification } from "../../../../context/actions/notificationActions";
import { startGetOrdenPago } from "../../../../context/actions/ordenesPagoActions";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../../../utils/composeNotification";
import { postFile } from "../../../../utils/fetch";

export const useUploadFile3 = (tipo, ordenPagoID) => {
  const dispatch = useDispatch();
  const { usuarioID } = useSelector((state) => state.userReducer.user);

  const [body, setBody] = useState({});

  const camposEror = () => {
    let salida = false;
    if (estaVacioNull(body.facturaPDF)) salida = true;
    if (estaVacioNull(body.facturaXML)) salida = true;
    if (estaVacioNull(body.verificacionFactura)) salida = true;

    //console.log({ salida });
    return salida;
  };

  const estaVacioNull = (valor) => {
    console.log(valor);
    if (valor === "" || valor === null || valor === undefined) {
      return true;
    } else return false;
  };

  const props1 = {
    beforeUpload: (facturaPDF) => {
      setBody({ ...body, facturaPDF });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };
  const props2 = {
    beforeUpload: (facturaXML) => {
      setBody({ ...body, facturaXML });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };
  const props3 = {
    beforeUpload: (verificacionFactura) => {
      setBody({ ...body, verificacionFactura });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const handleClick = async () => {
    if (camposEror()) {
      return;
    }

    let formdata = new FormData();
    formdata.append("mimetype", "application/pdf");

    formdata.append("facturaPDF", body.facturaPDF);
    formdata.append("facturaXML", body.facturaXML);
    formdata.append("fechaPagoRequerido", body.fechaPagoRequerido);
    formdata.append("verificacionFactura", body.verificacionFactura);

    formdata.append("ordenPagoID", ordenPagoID);
    formdata.append("tipo", tipo);
    formdata.append("usuarioID", usuarioID);

    const url = `${URL_BASE_ORDENESPAGO}/PostDocumento3/`;
    const result = await postFile(url, formdata);
    console.log(result);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(ordenPagoID));
      setBody({});
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - PostDocumento3",
            `${result.error}`
          )
        )
      );
    }
  };

  return { body, props1, props2, props3, setBody, handleClick };
};
