import { Row, Col } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BotonDiv2 from "../../../../components/Boton/BotonDiv2";
import LabelText from "../../../../components/input/LabelText";
import { startAddHistorialOrdenPago } from "../../../../context/actions/ordenesPagoActions";
import { HistorialItem } from "./HistorialItem";

///////////////////////
export default function InfoOrdenPagoHistorial() {
  const dispatch = useDispatch();
  const [comentario, setComentario] = useState("");
  const { ordenPagoID } = useParams();
  const { ordenPagoHistorial } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );
  const { usuarioID } = useSelector((state) => state.userReducer.user);

  const postComentario = () => {
    const body = {
      usuarioID,
      ordenPagoID,
      comentario,
    };
    dispatch(startAddHistorialOrdenPago(body));
    setComentario("");
  };

  ///////////////////////
  return (
    <div style={{ height: 250, overflowY: "auto", overflowX: "hidden" }}>
      <Row gutter={10}>
        <Col span={18}>
          <LabelText
            name="comentario"
            value={comentario}
            onChange={({ target }) => setComentario(target.value)}
          />
        </Col>
        <Col span={4}>
          <BotonDiv2 onClick={postComentario} tituloBoton="Agregar" />
        </Col>
      </Row>
      {ordenPagoHistorial && (
        <div style={{ marginTop: 10, marginLeft: 3 }}>
          {ordenPagoHistorial.map((h, i) => (
            <HistorialItem key={i} historial={h} />
          ))}
        </div>
      )}
    </div>
  );
}
