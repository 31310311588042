import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetProveedores, } from "../../../context/actions/proveedoresActions";
import CardPage from "../../../layout/CardPage";
import Agregar from "../agregar/Agregar";
import Tabla from "./Tabla";
import { Button, Modal } from "antd";

////
export default function Listado() {
  const [openModal, setOpenModal] = useState(false);

  const { Backoffice_AltaProveedores } = useSelector((state) => state.userReducer.user)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetProveedores());
  }, [dispatch]);

  const handleAgregar = () => setOpenModal(true);

  ////
  return (
    <CardPage
      titulo="Proveedores"
      tituloBoton={Backoffice_AltaProveedores && "Agregar"}
      accionBoton={handleAgregar}
    >
      <Tabla />

      <Modal width={900} onCancel={()=> setOpenModal(false)} open={openModal} footer={<Button danger key={'cerrar'} onClick={() => setOpenModal(false)}>Cerrar</Button>}>
        <Agregar />
      </Modal>
    </CardPage>
  );
}
