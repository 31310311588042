import { DatePicker } from "antd";
import IsBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
dayjs.extend(IsBetween);

export function DatePickerFilter(dataIndex) {
  function fechaEntreRango(fecha, inicio, fin) {
    const [day1, month1, year1] = fecha.split("-");
    const [day2, month2, year2] = inicio.split("-");
    const [day3, month3, year3] = fin.split("-");
    return (
      dayjs(`${year1}-${month1}-${day1}`).isBetween(
        `${year2}-${month2}-${day2}`,
        `${year3}-${month3}-${day3}`
      ) ||
      fecha === inicio ||
      fecha === fin
    );
  }

  const onChange = (value, clear, confirm, setSelectedKeys) => {
    if (!value) {
      clear();
      confirm();
      return;
    }
    setSelectedKeys([
      {
        inicio: value[0].format("DD-MM-YYYY"),
        pasado: value[1].format("DD-MM-YYYY"),
      },
    ]);
    confirm();
  };
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <>
        <RangePicker
          format="DD-MM-YYYY"
          onChange={(values) =>
            onChange(values, clearFilters, confirm, setSelectedKeys)
          }
        />
      </>
    ),

    onFilter: (value, record) =>
      fechaEntreRango(record[dataIndex], value.inicio, value.pasado),
  };
}
