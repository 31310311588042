import { Col, Row } from "antd";
import React from "react";
import { BuscaProveedor } from "./BuscaProveedor";
import { ListaSucursales } from "./ListaSucursales";
import { ListadoProveedores } from "./ListadoProveedores";

export const Forma = () => {



  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <BuscaProveedor />
          <ListadoProveedores />
        </Col>
        <Col span={12}>
          <ListaSucursales />
        </Col>
      </Row>

    </>
  );
};
