import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL_BASE_ASISTENCIAS } from "../../constants/url";
import { setAsistenciasLoading } from "../../context/actions/asistenciasActions";

export const useFiltroAsistencias = () => {
  const dispatch = useDispatch();
  const [listaFinal, setListaFinal] = useState([]);

  const filter = async ({ target }) => {
    dispatch(setAsistenciasLoading(true));
    const data = await axios.get(
      `${URL_BASE_ASISTENCIAS}/GetAsistenciasByBusqueda/${target.value}`
    );

    setListaFinal(data.data);
    dispatch(setAsistenciasLoading(false));
  };

  return { listaFinal, filter };
};
