import React, { useState } from "react";
import {
  ToolOutlined,
  PushpinOutlined,
  RadarChartOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { setPaginaDetalleSucursal } from "../../../../context/actions/sucursalesActions";
import { useSelector } from "react-redux";

export const CuerpoMenu = () => {
  const { paginaDetalleSucursal } = useSelector(
    (state) => state.proveedoresReducer
  );
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(paginaDetalleSucursal);

  const handleClick = ({ key }) => {
    setCurrent(key);
    dispatch(setPaginaDetalleSucursal(key));
  };

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      style={{ marginBottom: 15 }}
    >
      <Menu.Item key="tiposAsistencia" icon={<ToolOutlined />}>
        Tipos de asistencia
      </Menu.Item>
      <Menu.Item key="geocerca" icon={<RadarChartOutlined />}>
        Geocerca
      </Menu.Item>

      <Menu.Item key="pines" icon={<PushpinOutlined />}>
        Pines
      </Menu.Item>
    </Menu>
  );
};
