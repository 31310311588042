import { Col, Row, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import BotonDiv2 from "../../../../../components/Boton/BotonDiv2";
const { Option } = Select;

//
export const SelectSucursal = ({ onChange, value, onClick }) => {
  const { proveedorSucursales } = useSelector(
    (state) => state.proveedoresReducer.proveedor
  );

  ///
  return (
    <Col xs={24} md={6} xl={6}>
      <Row gutter={10}>
        {/* <Col span={18}>
          <Select
            onChange={onChange}
            placeholder="sucursal"
            name="sucursal"
            style={{ width: "100%" }}
          >
            <Option key="x1" value="0">
              Seleccione
            </Option>
            {proveedorSucursales.map((s, i) => (
              <Option key={i} value={s.proveedorSucursalID}>
                {s.nombre}
              </Option>
            ))}
          </Select>
        </Col> */}
        <Col span={6}>
          <BotonDiv2 tituloBoton="Guardar" onClick={onClick} />
        </Col>
      </Row>
    </Col>
  );
};
