import { Spin } from 'antd'
import React from 'react'

export const Spinner = () => {
    return (
        <div style={{
            textAlign: 'center',
            paddingTop: 25
        }}>
            <Spin size="large" />
        </div>
    )
}
