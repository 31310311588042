import { Select } from "antd";

const { Option } = Select;

export default function LabelSelectSiNo({
  label,
  onChange,
  value,
  name,
  placeholder,
}) {
  return (
    <>
      <div className="labelImput">{label}</div>
      <Select
        placeholder={placeholder}
        showSearch
        value={value}
        name={name}
        onChange={onChange}
        style={{ width: "100%" }}
      >
        <Option value={true}>Si</Option>
        <Option value={false}>No</Option>
      </Select>
    </>
  );
}
