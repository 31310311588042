import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import { startChangeProveedor } from "../../../../context/actions/asistenciasActions";
import ModelPage from "../../../../layout/ModelPage";
import { Forma } from "./Forma";
import { emailCambiarProveedor } from "../../../../constants/cons";

export const CambioProveedor = () => {
    const { email } = useSelector((state) => state.userReducer.user);

    const { asistenciaID } = useParams();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const cambioProveedor = useSelector(state => state.userReducer.user.Backoffice_CambioProveedor)
  

    const handleOk = () => {
        dispatch(startChangeProveedor(asistenciaID));
        setOpenModal(false);
    };

    //// modificar para crear roles

    if (!emailCambiarProveedor.includes(email)) return <></>;
    if (!cambioProveedor) return <></>;

    return (
        <>
            <BotonDivL
                tituloBoton="Cambio Proveedor"
                onClick={() => setOpenModal(true)}
            />
            <ModelPage
                width={800}
                titulo="Cambio Proveedor"
                handleOk={handleOk}
                handleCancel={() => setOpenModal(false)}
                isVisible={openModal}
            >
                <Forma />
            </ModelPage>
        </>
    );
};
