import { Col, Row } from "antd";
import React from "react";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { formatCurrencyDecimals } from "../../../../../utils/formatCurrencyDecimals";

export const AsistenciasItem = ({ asistencia }) => {
  console.log(asistencia);
  const { asistenciaID, cliente, proveedor, costo, estatusFinal, servicio } =
    asistencia;
  return (
    <Row style={{ borderBottom: "1px solid #f1f1f1" }}>
      <Col xl={3} md={6} xs={12}>
        {asistenciaID}
      </Col>
      <Col xl={4} md={6} xs={12}>
        {servicio}
      </Col>
      <Col xl={6} md={6} xs={12}>
        {proveedor}
      </Col>
      <Col xl={4} md={6} xs={12}>
        {cliente}
      </Col>
      <Col xl={4} md={6} xs={12}>
        {estatusFinal}
      </Col>
      <Col xl={3} md={6} xs={12} style={{ textAlign: "" }}>
        {formatCurrencyDecimals(costo, 2)}
      </Col>
    </Row>
  );
};
