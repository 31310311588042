import { Button, Drawer, Form, Input, notification, Select, } from 'antd'
import { formatBody, optionFormAtention } from "../../utils/optionsFormAtention";
import { post } from "../../utils/fetch";
import { URL_BASE_CALIDAD } from "../../constants/url";

const FormAtencion = ({ openCalidad, setOpenCalidad, id, data}) => {
  const defaultValues= JSON.parse(data?.encuesta || '{}')
  const { TextArea} = Input;
  const [form] = Form.useForm();


  const openNotificationWithIcon = (type, title, content) => {
    notification[type]({
      message: title,
      description:content
    });
  };

  const submit = async (values) => {
    const body = formatBody(values, id)
    console.log({body})
    const data = await post(`${URL_BASE_CALIDAD}/PostEncuestaAtencion`, body)
    if(data?.code === -1 ){
      openNotificationWithIcon('error', "Error al enviar la encuesta", "Ha ocurrido un error intenta mas tarde")

      form.resetFields()
    }else {

      openNotificationWithIcon('success', "Encuesta guardada correctamente", "Su encuesta se ha guardado correctamente")
      setOpenCalidad(false)
    }


  }
  return (
    <>
      <Drawer
        title="Calidad de atención"
        placement="right"
        onClose={() => setOpenCalidad(false)}
        open={openCalidad}
        requiredMark={false}
      >
      <Form
          onFinish={submit}
          layout={'vertical'}
          scrollToFirstError
          form={form}
          initialValues={defaultValues}

      >
        {optionFormAtention.map( item => (
          <div key={item.groupName}>
            <h3 key={item.groupName} style={{color: 'red'}}> {item.groupName}</h3>
          {item.options.map( option => (
              <Form.Item key={item.label}>
                <div className='inputLabel'>{option.label}</div>

                <Form.Item key={option.label}  name={option.name}
                           rules={[{ required: true, message: "Este campo es requerido" }]}
                >
                  <Select disabled={Object.values(defaultValues).length > 0} options={option.radios} size="small" />
                </Form.Item>
              </Form.Item>
          ))}
          </div>
        ))}

        <div className='inputLabel'>{'Observaciones'}</div>
        <Form.Item name={'observaciones'}>
          <TextArea disabled={Object.values(defaultValues).length > 0} />
        </Form.Item>
           <Button disabled={Object.values(defaultValues).length > 0} type="primary" htmlType="submit">
             Enviar
           </Button>
      </Form>
      </Drawer>
    </>
  );
};
export default FormAtencion;
