import { Input } from "antd";
import React from "react";

export default function LabelNumber({
  label,
  name,
  onChange,
  value,
  placeholder,
}) {
  const HandleChange = (e) => {
    const body = {
      target: {
        name,
        value: Number(e.target.value),
      },
    };

    onChange(body);
  };

  return (
    <>
      <div className="labelImput">{label}</div>
      <Input
        type="number"
        name={name}
        onChange={HandleChange}
        value={value}
        placeholder={placeholder}
      />
    </>
  );
}
