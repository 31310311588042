import { Col, Input, Row, Switch } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateServicioDatoProveedor,
  updateServicioIDProveedor,
} from "../../../../../../context/actions/proveedoresActions";

export default function ServicioRow({ servicio }) {
  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState();
  //

  let row = {};

  const { servicios } = useSelector(
    (state) => state.proveedoresReducer.postServicios
  );

  servicios.map((s) => {
    if (s.servicioID === servicio.id) {
      row = { ...s };
    }
  });

  const handleChangeSwitch = (val) => {
    console.log(val);
    const body = { servicioID: servicio.id, accion: val, isActive: val };
    dispatch(updateServicioIDProveedor(body));

    if (val) setReadOnly(false);
    else setReadOnly(true);
  };

  const handleChangeInput = ({ target }) => {
    const body = { servicioID: servicio.id, [target.name]: target.value };
    dispatch(updateServicioDatoProveedor(body));
  };

  //
  return (
    <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
      <Col span={2}>
        <Switch
          onChange={handleChangeSwitch}
          checkedChildren="Si"
          unCheckedChildren="No"
          checked={row.isActive}
        />
      </Col>
      <Col span={4}>
        <div>{servicio.nombre}</div>
      </Col>
      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="lp1"
          onChange={handleChangeInput}
          value={row.lp1}
        />
      </Col>
      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="lp1m"
          onChange={handleChangeInput}
          value={row.lp1m}
        />
      </Col>
      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="lp2"
          onChange={handleChangeInput}
          value={row.lp2}
        />
      </Col>
      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="lp2m"
          onChange={handleChangeInput}
          value={row.lp2m}
        />
      </Col>

      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="lp3"
          onChange={handleChangeInput}
          value={row.lp3}
        />
      </Col>
      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="lp3m"
          onChange={handleChangeInput}
          value={row.lp3m}
        />
      </Col>


      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="banderazo"
          onChange={handleChangeInput}
          value={row.banderazo}
        />
      </Col>
      <Col span={2}>
        <Input
          readOnly={readOnly}
          name="xkm"
          onChange={handleChangeInput}
          value={row.xkm}
        />
      </Col>
    </Row>
  );
}
