import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export const usePagoEfectuado = () => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const [asistencias, setAsistencias] = useState(0)
    const [costo, setCosto] = useState(0)

    useEffect(() => {
        let a = 0;
        let c = 0;
        const listaNueva = asistenciasMes.filter(a => a.estaPagado || a.fechaCargaPago)
        listaNueva.map((asis) => {
            a++;
            c = c + Number(asis.costo)
        })

        setAsistencias(a)
        setCosto(c)

    }, [asistenciasMes])


    return { asistencias, costo }
}
