import { Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import LabelNumber from "../../../components/input/LabelNumber";
import { startBuscaOrdenPagoID } from "../../../context/actions/ordenPagoBuscaActions";

export const BuscarOrdenPagoID = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ordenPagoID, setOrdenPagoID] = useState();

  const handleClick = () => {
    dispatch(startBuscaOrdenPagoID(ordenPagoID)).then((respuesta) => {
      if (respuesta.ok) history.push(`/ordenesPago/detalle/${ordenPagoID}`);
    });
  };
  ///////
  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={6}>
        <LabelNumber
          label="Orden de Pago ID"
          value={ordenPagoID}
          onChange={({ target }) => setOrdenPagoID(target.value)}
        />
      </Col>
      <Col span={6} style={{ paddingTop: 22 }}>
        <BotonSubmit onClick={handleClick} tituloBoton="Buscar Orden de Pago" />
      </Col>
    </Row>
  );
};
