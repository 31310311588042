import { useSelector } from "react-redux";
import Campanias from "../campanias/Campanias";
import Documentos from "../documentos/Documentos";
import Script from "../script/Script";

export default function Accion() {
  const { pagina } = useSelector((state) => state.clientesReducer);

  if (pagina === "campanias") return <Campanias />;
  if (pagina === "documentos") return <Documentos />;
  if (pagina === "script") return <Script />
  return <div>err</div>;
}
