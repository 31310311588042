import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  pickTipoAsistenciaProveedor,
  startLoadProveedorServicios,
} from "../../../../../../context/actions/proveedoresActions";

export default function Tipo({ tipo }) {
  const dispatch = useDispatch();
  const { proveedorSucursalID } = useParams();
  const { tipoAsistenciaSelected } = useSelector(
    (state) => state.proveedoresReducer
  );
  const handleClick = async () => {
    const body = {
      proveedorSucursalID,
      tipoAsistenciaID: tipo.tipoAsistenciaID,
    };
   
    await dispatch(pickTipoAsistenciaProveedor(tipo));
    dispatch(startLoadProveedorServicios(body));
  };

  return (
    <div
      onClick={handleClick}
      className={`listaSelect ${
        tipoAsistenciaSelected.tipoAsistenciaID === tipo.tipoAsistenciaID &&
        "seleccionado"
      }`}
    >
      {tipo.nombre}
    </div>
  );
}
