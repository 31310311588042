import React from 'react'
import { useSelector } from 'react-redux'
import { CampaniaItem } from './CampaniaItem'
import { campaniasClienteReduce } from './campaniasClienteReduce'


export const Campanias = ({ cliente }) => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const listado = campaniasClienteReduce(asistenciasMes, cliente.Id)

    return (
        <div style={{ padding: 5, borderBottom: '1px solid #f1f1f1' }}>
            {listado.map((reg, i) => <CampaniaItem reg={reg} key={i} i={i} />)}
        </div>
    )
}
