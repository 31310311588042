import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProveedoresSelect } from "../../../../components/select/ProveedoresSelect";
import { setFiltroOrdenesPago } from "../../../../context/actions/ordenesPagoActions";

export const ControlProveedores = () => {
  const dispatch = useDispatch();
  const { proveedorID } = useSelector(
    (state) => state.ordenesPagoReducer.filtroOrdenesPago
  );

  return (
    <ProveedoresSelect
      value={proveedorID}
      onChange={(val) => dispatch(setFiltroOrdenesPago({ proveedorID: val }))}
    />
  );
};
