import React from "react";
import { EstatusIcon } from "../icons/EstatusIcon";

export default function LabelIcon({ label, valor }) {
  return (
    <>
      <div className="label">{label}</div>
      <div className="valor" style={{paddingLeft: 10, paddingTop: 4}}><EstatusIcon estatus={valor} /></div>
    </>
  );
}
