import { Col, Row } from "antd";

export default function ListaCabecera() {
  return (
    <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
      <Col span={5} className="bordeLista">
        Etiqueta
      </Col>
      <Col span={5} className="bordeLista">
        Requerido
      </Col>
      <Col span={5} className="bordeLista">
        Tipo
      </Col>
      <Col span={5} className="bordeLista">
        Lista
      </Col>
    </Row>
  );
}
