import { Menu } from "antd";
import {
  BankOutlined,
  FilePdfOutlined,
  DollarCircleOutlined,
  ToolOutlined,
  AreaChartOutlined,
  DashboardOutlined,
  PushpinOutlined,
  RadarChartOutlined, GlobalOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setPagina } from "../../../../context/actions/proveedoresActions";

export default function MenuProveedor() {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("sucursales");

  const handleClick = ({ key }) => {
    setCurrent(key);
    dispatch(setPagina(key));
  };

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      style={{ marginBottom: 15 }}
    >
      <Menu.Item key="sucursales" icon={<BankOutlined />}>
        Sucursales
      </Menu.Item>
      {/* <Menu.Item key="geocerca" icon={<RadarChartOutlined />}>
        Geocerca
      </Menu.Item>

      <Menu.Item key="pines" icon={<PushpinOutlined />}>
        Pines
      </Menu.Item>

      <Menu.Item key="tiposAsistencia" icon={<ToolOutlined />}>
        Tipos de asistencia
      </Menu.Item> */}
      <Menu.Item key="informacionPago" icon={<DollarCircleOutlined />}>
        Información de pago
      </Menu.Item>
      <Menu.Item key="documentos" icon={<FilePdfOutlined />}>
        Documentos
      </Menu.Item>
      <Menu.Item key="cobertura" icon={<GlobalOutlined/>} >
        Cobertura

      </Menu.Item>

      {/* <Menu.Item key="nivelesServicio" icon={<DashboardOutlined />}>
        Niveles de servicio
      </Menu.Item>
      <Menu.Item key="estadisticas" icon={<AreaChartOutlined />}>
        Estadísticas
      </Menu.Item> */}

    </Menu>
  );
}
