import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelText from "../../../components/input/LabelText";
import { cleanTipoAsistenciaForm, setValueTipoAsistencia } from "../../../context/actions/tiposAsistenciaActions";

export default function Agregar() {
  const dispatch = useDispatch();
  const { nombre } = useSelector(
    (state) => state.tiposAsistenciaReducer.tipoAsistencia
  );
  const handleChange = ({ target }) => dispatch(setValueTipoAsistencia(target));
  //
  useEffect(() => {dispatch(cleanTipoAsistenciaForm())}, []);

  return (
    <LabelText
      label="Tipo de asistencia"
      name="nombre"
      onChange={handleChange}
      value={nombre}
    />
  );
}
