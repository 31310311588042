import React from "react";
import { useSelector } from "react-redux";
import { SucursalesItem } from "./SucursalesItem";

export const ListaSucursales = () => {
  const { sucursales } = useSelector((state) => state.asistenciasReducer);
  console.log(sucursales);
  return (
    <>
      <div className="tituloLista">Sucursales</div>
      {sucursales.map((s, i) => (
        <SucursalesItem key={i} sucursal={s} />
      ))}
    </>
  );
};
