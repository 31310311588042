import { Col, Input, Row } from "antd";
import React from "react";
import CampaniasSelect from "../../../../components/select/CampaniasSelect";
import ButtonDiv from "../../../../components/Boton/BotonDiv";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCampaniaScript,
  setScript,
  startPostScript,
} from "../../../../context/actions/campaniasActions";

export default function Script() {
  const dispatch = useDispatch();
  const { textoScript, campaniaID } = useSelector(
    (state) => state.campaniasReducer.script
  );

  const handleclick = () => dispatch(startPostScript());
  const handleChange = (val) => dispatch(selectCampaniaScript(val));
  const handleChangeScript = ({ target }) =>
    dispatch(setScript({ textoScript: target.value, campaniaID }));

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <CampaniasSelect onChange={handleChange} />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <Input.TextArea
            rows={5}
            value={textoScript}
            onChange={handleChangeScript}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <ButtonDiv onClick={handleclick} tituloBoton="Guardar" />
        </Col>
      </Row>
    </>
  );
}
