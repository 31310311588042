import React from 'react';
import { formatCurrency } from '../../utils/formatCurrency';

export const StatsTable = ({totalOrdenes, totalCash, ...styles}) => {
  return (
    <div style={{...styles}}>

      <p style={{color: "#1890FF", display: 'inline-block', marginRight: '40px', marginBottom: '0px'}}>
        Total Ordenes:{" "}
        <span style={{color: "black"}}>
          {totalOrdenes}
        </span>
      </p>

      <p style={{color: "#1890FF", display: 'inline-block', marginBottom: '0'}}>
        Total :{" "}
        <span style={{color: "black"}}>
          {formatCurrency(totalCash)}
        </span>
      </p>
    </div>
  )
};
