import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { EstatusIcon } from "../../../components/icons/EstatusIcon";

////
export default function useColums() {
  const history = useHistory();

  const handleClick = ({ proveedorID }) =>
    history.push(`/proveedores/detalle/${proveedorID}`);

  const proveedoresColumns = [
    {
      title: "Nombre",
      dataIndex: "nombreComercial",
    },
    {
      title: "Razon Social",
      dataIndex: "razonSocial",
    },
    {
      title: "Contacto",
      dataIndex: "contactoNombre",
    },
    {
      title: "Teléfono",
      dataIndex: "contactoTelefono1",
    },
    {
      title: "Activo",
      dataIndex: "estaActivo",
      render: (data, row)=> <EstatusIcon estatus={data} />,
    },
    {
      title: "Interno",
      dataIndex: "esProveedorInterno",
      render: (data, row)=> <EstatusIcon estatus={data} />,

    },
    {
      title: "Crítico",
      dataIndex: "esProveedorCritico",
      render: (data, row)=> <EstatusIcon estatus={data} />,

    },
    
  ];

  return { proveedoresColumns };
}
