import { Col, Row } from "antd";
import React from "react";
import CardPage from "../../../layout/CardPage";
import Servicios from "./servicios/Servicios";
import TiposAsistencia from "./TiposAsistencia/TiposAsistencia";

/////////////
export const SeccionTiposAsistencia = () => {
  return (
    <CardPage titulo={`Tipos de Asistencia`}>
      <Row gutter={[10, 10]}>
        <Col span={5}>
          <TiposAsistencia />
        </Col>
        <Col span={19}>
          <Servicios />
        </Col>
      </Row>
    </CardPage>
  );
};
