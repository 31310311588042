import { types } from "../types/types";

const init = {
  tiposAsistencia: [],
  tipoAsistencia: {},
  tipoAsistenciaSelected: {
    servicios: [],
  },
  listas: [],
  servicioSelected: { id: 0 },
  campos: [],
};
export const tiposAsistenciaReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeTiposAsistencia:
      return {
        ...state,
        tiposAsistencia: action.payload,
      };

    case types.setValueTipoAsistencia:
      return {
        ...state,
        tipoAsistencia: {
          ...state.tipoAsistencia,
          [action.payload.name]: action.payload.value,
        },
      };

    case types.cleanTipoAsistenciaForm:
      return {
        ...state,
        tipoAsistencia: {},
      };

    case types.storeTipoAsistenciaSelected: {
      return {
        ...state,
        tipoAsistenciaSelected: action.payload,
      };
    }

    case types.storeListas:
      return {
        ...state,
        listas: action.payload,
      };

    case types.setServicioSelected:
      return {
        ...state,
        servicioSelected: action.payload,
      };

    case types.changeServicio:
      return {
        ...state,
        servicioSelected: {
          ...state.servicioSelected,
          ...action.payload,
        },
      };

    case types.cleanServicioSelected:
      return {
        ...state,
        servicioSelected: { id: 0 },
      };

    case types.storeCampos:
      return {
        ...state,
        campos: action.payload,
      };

    default:
      return state;
  }
};
