import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { formatCurrency } from '../../../utils/formatCurrency'
import { ProveedorDetalle } from './proveedorDetalle/ProveedorDetalle'

export const ProveedoresItem = ({ proveedor, i }) => {
    const [show, setShow] = useState(false)
    return (<>
        <Row gutter={10} className="rowItemElemento" onClick={() => setShow(!show)}>
            <Col span={1} style={{ textAlign: 'center' }}>{i + 1}</Col>
            <Col span={8}>{proveedor.proveedor}</Col>
            <Col span={2} style={{ textAlign: 'center' }}>{proveedor.cantidad}</Col>
            <Col span={4} style={{ textAlign: 'right' }}>{formatCurrency(proveedor.costo)}</Col>
            <Col span={4} style={{ textAlign: 'right' }}>{formatCurrency(proveedor.costo / proveedor.cantidad)}</Col>
        </Row>

        {show && (<ProveedorDetalle proveedor={proveedor} />)}
    </>)
}
