import { useDispatch, useSelector } from "react-redux";
import { startGetAsistenciasDia } from "../../../../context/actions/reportesActions";
import { DatePicker, Spin } from "antd";
import { useEffect, useState } from "react";
import { getYesterday } from "../../../../utils/getYesterday";

export const GeneralesFecha = () => {
  const dispatch = useDispatch();
  const { loadingReporte } = useSelector((state) => state.reportesReducer);
  useEffect(() => {
    var yesterday = getYesterday();
    dispatch(startGetAsistenciasDia(yesterday));
  }, []);

  const handleChange = (date, dateString) => {
    dispatch(startGetAsistenciasDia(dateString));
  };

  ///
  return (
    <div style={{ padding: "10px 10px 25px 25px" }}>
      <DatePicker onChange={handleChange} />
      {loadingReporte && <Spin style={{ marginLeft: 20 }} />}
      
    </div>
  );
};
