import React from 'react'
import { useSelector } from 'react-redux'
import CardPage from '../../../layout/CardPage'
import { Skele } from '../dashboard/utils/Skele'
import { ProveedorCabecera } from './ProveedorCabecera'
import { ProveedoresItem } from './ProveedoresItem'
import { useProveedores } from './useProveedores'

export const Proveedores2 = () => {

  const { proveedores } = useProveedores()

  const { loadingReporte } = useSelector(state => state.reportesReducer)
  if (loadingReporte)
    return <Skele />


  return (
    <CardPage>
      <ProveedorCabecera />
      {proveedores.map((p, i) => <ProveedoresItem key={i} proveedor={p} i={i} />)}
    </CardPage>
  )
}
