import {Alert, Button, Divider, Upload} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import ModelPage from "../../../../../../layout/ModelPage";
import { useParams } from "react-router";
import { useUploadFile3 } from "../../useUploadFile3";
import LabelDateTime from "../../../../../../components/input/LabelDateTime";

export const CargaFacturaModal = ({ showModal, setShowModal }) => {

  const { ordenPagoID } = useParams();
  const { body, props1, props2, props3, setBody, handleClick } = useUploadFile3(
    0,
    ordenPagoID
  ); // 0 = factura

  const ok = () => {
    handleClick();
    setShowModal(false);
  };

  const cancel = () => {
    setBody({});
    setShowModal(false);
  };

  const handleFecha = ({target}) => {
    setBody({
      ...body,
      fechaPagoRequerido: target.value
    })
  }
  //////////////
  return (
    <ModelPage
      titulo="Cargar Factura de Proveedor"
      isVisible={showModal}
      handleOk={ok}
      handleCancel={cancel}
    >
      <div className="tituloPequeño">Factura PDF emitida por el proveedor</div>

      <Upload {...props1} style={{ width: "100%" }}>
        <Button
          style={{ textAlign: "left", marginTop: 20 }}
          icon={<UploadOutlined />}
        >
          Seleccione documento
        </Button>
      </Upload>
      <span style={{ marginLeft: 20 }}>{body.facturaPDF?.name}</span>

      <Divider />
      <div className="tituloPequeño">Factura XML emitida por el proveedor</div>

      <Upload {...props2} style={{ width: "100%" }}>
        <Button
          style={{ textAlign: "left", marginTop: 20 }}
          icon={<UploadOutlined />}
        >
          Seleccione documento
        </Button>
        <span style={{ marginLeft: 20 }}>{body.facturaXML?.name}</span>
      </Upload>

      <Divider />
      <div className="tituloPequeño" style={{display: 'flex', alignItems:"center"}}>
        Verificación de factura por SAT
        <Alert type='warning' style={{fontSize: "10px"}} banner message={"Por favor verifica que los folios fiscales coincidan"}/>
      </div>

      <Upload {...props3} style={{ width: "100%" }}>
        <Button
          style={{ textAlign: "left", marginTop: 20 }}
          icon={<UploadOutlined />}
        >
          Seleccione documento
        </Button>
        <span style={{ marginLeft: 20 }}>{body.verificacionFactura?.name}</span>
      </Upload>

      <span style={{ marginLeft: 20 }}>{body.file?.name}</span>

      <div style={{ marginBottom: 10, marginTop: 20, maxWidth: 250 }}>
            <LabelDateTime
              label="Requerido para el"
              name="fechaPagoRequerido"
              onChange={handleFecha}
              value={body.fechaPagoRequerido}
            />
          </div>

    </ModelPage>
  );
};
