import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { startGetSucursal, startPostSucursal } from "../../../context/actions/sucursalesActions";
import CardPage from "../../../layout/CardPage";
import ModelPage from "../../../layout/ModelPage";
import FormaSucursal from "../detalle/folder/sucursales/formaSucursal/FormaSucursal";
import { Cuerpo } from "./Cuerpo";

////
export const SucursalDetalle = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [sucursalTexto, setSucursaltexto] = useState("...");
  const { proveedorID, proveedorSucursalID } = useParams();
  const { nombreComercial, proveedorSucursales } = useSelector(
    (state) => state.proveedoresReducer.proveedor
  );

  const handleRegresar = () =>
    history.push(`/proveedores/detalle/${proveedorID}`);

  useEffect(() => {
    dispatch(startGetSucursal(proveedorSucursalID));
    proveedorSucursales.map((s) => {
      if (s.proveedorSucursalID == proveedorSucursalID) {
        setSucursaltexto(s.nombre);
      }
    });
  }, [proveedorSucursales]);

  const handleOk = () => {
    dispatch(startPostSucursal(proveedorID))
  };
  ///
  return (
    <CardPage
      titulo={`Sucursal: ${sucursalTexto} | Proveedor: ${nombreComercial}`}
      tituloBoton="Regresar"
      accionBoton={handleRegresar}
      tituloBoton2="Editar"
      accionBoton2={() => setOpenModal(true)}
    >
      <Cuerpo />
      <ModelPage
        titulo="Editar Sucursal"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <FormaSucursal />
      </ModelPage>
    </CardPage>
  );
};
