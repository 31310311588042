import { Modal } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { startUpdateProveedor } from "../../../../context/actions/proveedoresActions";
import CardPage from "../../../../layout/CardPage";
import { Editar } from "./Editar";
import Info from "./Info";
import { getAllEstados, getMunicipioProveedor } from "../../../../context/actions/proveedorEstadoMunicipiosActions";

export default function InfoProveedor() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleRegresar = () => history.push("/proveedores");

  const handleOk = () => {
    // dispatch(startUpdateProveedor());
    setShow(false);
  };


  useEffect(()=> {
    dispatch(getAllEstados())
  },[])

  const { proveedorID } = useSelector(state => state.proveedoresReducer.proveedor)
  useEffect(()=> {
    dispatch(getMunicipioProveedor(proveedorID))
  },[proveedorID])



  return (
    <CardPage
      titulo="Información de Proveedor"
      tituloBoton="Regresar"
      accionBoton={handleRegresar}
      tituloBoton2="Editar"
      accionBoton2={() => setShow(true)}
    >
      <Info />
      <Modal
        title="Editar Proveedor"
        open={show}
        onOk={handleOk}
        footer={null}
        width={900}
        onCancel={() => setShow(false)}
      >
        <Editar />
      </Modal>
    </CardPage>
  );
}

