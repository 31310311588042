import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import { startPostCampaniaServicios } from "../../../../context/actions/campaniasActions";
import Loading from "../../../../layout/Loading";
import ListaServicios from "./ListaServicios";
import ListaVacia from "./ListaVacia";

///
export default function Servicios() {
  const dispatch = useDispatch();
  const { loadingCampaniaServicios } = useSelector(
    (state) => state.loadingReducer
  );
  const { tipoAsistenciaSelected } = useSelector(
    (state) => state.campaniasReducer
  );

  const handleClick = () => {
    dispatch(startPostCampaniaServicios());
  };
  ///

  if (loadingCampaniaServicios) return <Loading size="large" />;

  if (tipoAsistenciaSelected.servicios.length > 0)
    return (
      <>
        <div className="tituloServicios">{tipoAsistenciaSelected.nombre}</div>
        <ListaServicios servicios={tipoAsistenciaSelected.servicios} />
        <div className="contenedorBotonSubmit" style={{ maxWidth: 200 }}>
          <BotonSubmit
            onClick={handleClick}
            tituloBoton="Actualizar Servicios"
          />
        </div>
      </>
    );

  return <ListaVacia />;
}
