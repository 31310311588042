import React from 'react'
import { Campanias } from './Campanias'
import { Estatus } from './Estatus'
import { LocalCarretero } from './LocalCarretero'
import { Servicios } from './Servicios'

export const ClienteDetalle = ({ cliente }) => {

    return (
        <div style={{ padding: 1, backgroundColor: '#f1f1f1', border: '1px solid orange' }}>
            <Servicios cliente={cliente} />
            <LocalCarretero cliente={cliente} />
            <Estatus cliente={cliente} />
            <Campanias cliente={cliente} />
        </div>
    )
}
