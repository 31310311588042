import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

////
export default function useColums() {
  const history = useHistory();

  const handleClick = ({ tipoAsistenciaID }) =>
    history.push(`/tiposAsistencia/detalle/${tipoAsistenciaID}`);

  const tiposAsistenciaColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "servicios",
      dataIndex: "serviciosCount",
    },

    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined
          onClick={() => handleClick(record)}
          style={{ color: "#ca2228" }}
          className="eyeIcon"
        />
      ),
    },
  ];

  return { tiposAsistenciaColumns };
}
