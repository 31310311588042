import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  startGetTiposAsistencia,
  startPostTipoAsistencia,
} from "../../../context/actions/tiposAsistenciaActions";
import CardPage from "../../../layout/CardPage";
import ModelPage from "../../../layout/ModelPage";
import Agregar from "../agregar/Agregar";
import Tabla from "./Tabla";

////
export default function Listado() {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const handleAgregar = () => setOpenModal(true);

  useEffect(() => {
    dispatch(startGetTiposAsistencia());
  }, []);

  const handleOk = () => {
    dispatch(startPostTipoAsistencia());
    setOpenModal(false);
  };

  ////
  return (
    <CardPage
      titulo="Tipos de Asistencia"
      tituloBoton="Agregar"
      accionBoton={handleAgregar}
    >
      <Tabla />
      <ModelPage
        titulo="Agregar Tipo de Asistencia"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <Agregar />
      </ModelPage>
    </CardPage>
  );
}
