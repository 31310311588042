import { verificaLogin } from "./loginActions";
import { startGetEstadosTodos } from "./reportesActions";
import {
  startGetListas,
  startGetTiposAsistencia,
} from "./tiposAsistenciaActions";

export const startLoadInitial = () => {
  return async (dispatch) => {
    dispatch(verificaLogin());
    dispatch(startGetListas());
    dispatch(startGetTiposAsistencia());
    dispatch(startGetEstadosTodos());
  };
};
