import { DollarCircleOutlined, DollarCircleTwoTone } from "@ant-design/icons";
import { Button, Tooltip, notification } from "antd";
import { URL_BASE_ORDENESPAGO } from "../../constants/url";
import axios from "axios";
import { useSelector } from "react-redux";

const notificacionSuccess = () => {
    notification.success({
        message: "Orden Pagada",
        description: "La orden se pago correctamente",
        placement: "bottomRight",
        duration: 3
    })
}

const notificacionFailed = () => {
    notification.error({
        message: "No se pago la orden",
        description: "La orden no pudo pagarse ",
        placement: "bottomRight",
        duration: 3
    })
}

export default function ButtonPagar({model, tipo, setPagado}) {

    const {usuarioID} = useSelector((state) => state.userReducer.user)

    const onClickButton = async () => {
        await axios.post(`${URL_BASE_ORDENESPAGO}/UpdateEstadoPago`, {...model, usuarioID})
                   .then(() => {
                    notificacionSuccess();
                   })
                   .catch(() => {
                    notificacionFailed()
                   });

        setPagado(true);
    }

    if(tipo === "Icon"){
        return( 
        <Tooltip title="Pagar" placement="bottom" color="#87d068">
            <DollarCircleTwoTone twoToneColor="#52c41a" style={{fontSize: 15, marginLeft: 15}} onClick={onClickButton} />
        </Tooltip>)
    } else {
        return(
            <Button type="link" style={{color: '#52c41a', marginLeft: -9}} onClick={onClickButton}>
                <DollarCircleOutlined /> Pagar
            </Button>
        )
    }
}