import { Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router";
import BotonDiv from "../../../../components/Boton/BotonDiv";
import { ProveedoresSelect } from "../../../../components/select/ProveedoresSelect";
import { ControlFecha } from "./ControlFecha";
import { ControlProveedores } from "./ControlProveedores";

export const Controles = () => {
  const history = useHistory();

  return (
    <Row gutter={10}>
      <Col span={6}></Col>
      <Col span={4}>
        <ControlProveedores />
      </Col>
      <Col span={6}>
        <ControlFecha />
      </Col>
      <Col span={6}>
        <BotonDiv
          onClick={() => history.push("/ordenesPago/crear")}
          tituloBoton="Crear Orden Nueva"
          style={{ marginBottom: 15 }}
        />
      </Col>
    </Row>
  );
};
