import { Button, Form, Select, Switch } from "antd";
import { useDispatch, useSelector, } from "react-redux";
import useMunicipiosEstados from "../../hooks/useMunicipiosEstados";
import { postMunicipioProveedor, setAutoasignacion } from "../../context/actions/proveedorEstadoMunicipiosActions";
import { useState } from "react";
import { SelecMunicipioOrigenDestino } from "./SelecMunicipioOrigenDestino";


const options = [
  { label: "LP1", value: 1 },
  { label: "LP2", value: 2 },
  { label: "LP3", value: 3 },
]
const FormCobertura = () => {

  const { esAutoasignacion } = useSelector(state => state.proveedoresReducer.proveedor)

  const [ check, setCheck ] = useState(esAutoasignacion);
  const handleCheck = (value) => {
    setCheck(value)
    dispatch(setAutoasignacion(proveedorID, value))
  }

  const objeto = useMunicipiosEstados()
  objeto.lp = 1
  const dispatch = useDispatch()
  const { proveedorID } = useSelector(state => state.proveedoresReducer.proveedor)
  const [ form ] = Form.useForm();
  return (
    <>
      <Form
        form={form}
        onFinish={(values) => {
          dispatch(postMunicipioProveedor(proveedorID, values))
        }}
        layout={'vertical'}
        initialValues={objeto}
      >
        <Form.Item name={'autoAsigacion'} label={"Auto-Asignación"}>
          <Switch checkedChildren={"on"} unCheckedChildren={'off'} defaultChecked={check} onChange={handleCheck} />
        </Form.Item>
        {
          check ? (
            <>

              <SelecMunicipioOrigenDestino numberLp={1} />
              <SelecMunicipioOrigenDestino numberLp={2} />
              <SelecMunicipioOrigenDestino numberLp={3} />

              <Button type='primary' htmlType='submit' style={{ marginTop: "100px", marginBottom: "20px" }}>
                Guardar
              </Button>

            </>
          ) : (<></>)
        }
      </Form>
    </>
  );
};

export default FormCobertura;
