
export const campaniasClienteReduce = (asistencias, clienteID) => {

    var array = asistencias.filter(a => a.clienteID === clienteID)
    var result = [];
    array.reduce((res, value) => {
        if (!res[value.campaniaID]) {
            res[value.campaniaID] = { campania: value.campania, costo: 0, cantidad: 0 };
            result.push(res[value.campaniaID])
        }
        res[value.campaniaID].costo += value.costo;
        res[value.campaniaID].cantidad++;

        return res;
    }, {})
    return result
}
