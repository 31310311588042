import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { SucursalItem } from "./SucursalItem";

export const Sucursales = ({ map }) => {
  const { proveedorSucursalID } = useParams();
  const { proveedorSucursales } = useSelector(
    (state) => state.proveedoresReducer.proveedor
  );

  if (proveedorSucursales === undefined || proveedorSucursales.lenght === 0)
    return <div>Sin sucursales aún</div>;

  return (
    <div>
      {proveedorSucursales.map((s, i) => {
       
        if (proveedorSucursalID == s.proveedorSucursalID)
          return <SucursalItem key={i} sucursal={s} map={map} />;
      })}
    </div>
  );
};
