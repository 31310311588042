import { Col, Row } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SinRegistros } from '../../../../components/sinRegistros/SinRegistros'
import { dateFromString } from '../../../../utils/dateFromString'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { DrawerAsistencia } from './drawerAsistencia/DrawerAsistencia'
import { ZoomInOutlined } from '@ant-design/icons'
import { startGetServicioPorValidar } from '../../../../context/actions/opServiciosActions'

export const Listado = () => {
  const dispotch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [servicioPorValidarID, setServicioPorValidarID] = React.useState()
  const { serviciosPorValidar } = useSelector(state => state.opServiciosReducer)

  const handleOpen = async (asistenciaID, registroID) => {
    await setServicioPorValidarID(registroID)
    dispotch(startGetServicioPorValidar(asistenciaID))
    setOpen(true)
  }
  //
  return (
    <div>
      <Row>
        <Col span={2} className='tituloGrid2'>Asistencia</Col>
        <Col span={2} className='tituloGrid2'>Fecha</Col>
        <Col span={6} className='tituloGrid2'>Proveedor</Col>
        <Col span={3} className='tituloGrid2'>Servicio</Col>
        <Col span={3} className='tituloGrid2'>Area</Col>

        <Col span={5} className='tituloGrid2'>Dirección</Col>
        <Col span={2} className='tituloGrid2'>Costo</Col>
        <Col span={1} className='tituloGrid2'>Eval</Col>
      </Row>

      {serviciosPorValidar.length === 0 ? <SinRegistros /> : serviciosPorValidar.map((s, i) => (
        <Row key={i} className='grid2'>
          <Col span={2} style={{ margin: 'auto' }}>{s.asistenciaID}</Col>
          <Col span={2} style={{ margin: 'auto' }}>{dateFromString(s.fechaLlamadaIniciada).soloFecha}</Col>
          <Col span={6} style={{ margin: 'auto' }}>{s.proveedorNombre}</Col>
          <Col span={3} style={{ margin: 'auto' }}>{s.servicio}</Col>
          <Col span={3} style={{ margin: 'auto' }}>{s.localCarretero}</Col>
          <Col span={5}><div> {s.municipioOrigen} en {s.estadoOrigen}</div><div>  {s.municipioDestino} en {s.estadoDestino}</div></Col>
          <Col span={2} style={{ textAlign: 'right', margin: 'auto' }}>{formatCurrency(s.costo)}</Col>
          <Col span={1} style={{ margin: 'auto', textAlign: 'center' }} >
            <ZoomInOutlined onClick={() => handleOpen(s.asistenciaID, s.servicioPorValidarID)} className='eyeIcon' style={{ color: '#ce3e32', fontSize: 15 }} />
          </Col>
          <Col span={24} style={{ borderBottom: '1px solid #e8e8e8' }}> <b>Motivo Evaluación:</b> {s.comentariosEvaluacion} | <b>Comentarios internos:</b> {s.comentariosInternos}</Col>
        </Row>
     
      )
      )}

      <DrawerAsistencia open={open} setOpen={setOpen} servicioPorValidarID={servicioPorValidarID} />
    </div>
  )
}
