import { useSelector } from "react-redux";
import Loading from "../../../../layout/Loading";
import Campo from "./Campo";

///
export default function ListaCampos() {
  const { loadingCampos } = useSelector((state) => state.loadingReducer);
  const { campos } = useSelector((state) => state.tiposAsistenciaReducer);

  if (loadingCampos) return <Loading />;
  return (
    <>
      {campos.map((c, i) => (
        <Campo key={i} campo={c} />
      ))}
    </>
  );
}
