import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { URL_BASE_ORDENESPAGO } from "../../../../constants/url";
import { notification } from "../../../../context/actions/notificationActions";
import { startGetOrdenPago } from "../../../../context/actions/ordenesPagoActions";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../../../utils/composeNotification";
import { postFile } from "../../../../utils/fetch";

export const useUploadFile = (tipo, ordenPagoID, setPagoCargado) => {
  const dispatch = useDispatch();
  const { usuarioID } = useSelector((state) => state.userReducer.user);

  const [body, setBody] = useState({});

  const camposEror = () => {
    let salida = false;
    if (estaVacioNull(body.file, "file")) salida = true;

    return salida;
  };

  const estaVacioNull = (valor, campo) => {
    if (valor === "" || valor === null || valor === undefined) {
      return true;
    } else return false;
  };

  const props = {
    beforeUpload: (file) => {
      setBody({ ...body, file });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const handleClick = async () => {
    if (camposEror()) {
      return;
    }

    let formdata = new FormData();
    formdata.append("file", body.file);
    formdata.append("filename", "file");
    formdata.append("mimetype", "application/pdf");
    formdata.append("ordenPagoID", ordenPagoID);
    formdata.append("tipo", tipo);
    formdata.append("usuarioID", usuarioID);

    const url = `${URL_BASE_ORDENESPAGO}/PostDocumento/`;
    const result = await postFile(url, formdata);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(ordenPagoID));
      setBody({});
      setPagoCargado(true);
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post documento p",
            `${result.error}`
          )
        )
      );
    }
  };

  return { body, props, setBody, handleClick };
};
