import { types } from "../types/types";

const init = {
  campania: {
    nombre: "",
  },
  tipoAsistenciaSelected: {
    servicios: [],
  },
  postServicios: {
    servicios: [],
  },
  clienteFiles: [],
  script: {
    TextoScript: "",
    campaniaID: 0,
  },
};

//
export const campaniasReducer = (state = init, action) => {
  switch (action.type) {
    case types.setScript:
      return {
        ...state,
        script: { ...action.payload },
      };
    case types.storeFiles:
      return {
        ...state,
        clienteFiles: action.payload,
      };
    case types.storeCampanias:
      return {
        ...state,
        campania: action.payload,
      };

    case types.setValueCampania:
      return {
        ...state,
        campania: {
          ...state.campania,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.storeCampania:
      return {
        ...state,
        campania: action.payload,
      };

    case types.cleanCampaniaForm:
      return {
        ...state,
        campania: {},
      };

    case types.pickTipoAsistencia:
      return {
        ...state,
        tipoAsistenciaSelected: action.payload,
      };

    case types.initPostServicios:
      return {
        ...state,
        postServicios: {
          ...state.postServicios,
          ...action.payload,
        },
      };

    case types.updateServicios:
      return {
        ...state,
        postServicios: {
          ...state.postServicios,
          servicios: action.payload,
        },
      };

    case types.updatePostServiciosFull:
      return {
        ...state,
        postServicios: action.payload,
      };

    case types.cleanCampaniaName:
      return {
        ...state,
        campania: {
          nombre: "",
        },
      };
    default:
      return state;
  }
};
