import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import {
  setShowForma,
  startShowForma,
} from "../../../context/actions/usuariosActions";
import CardPage from "../../../layout/CardPage";
import { UsuarioForma } from "../usuarioForma/UsuarioForma";
import Tabla from "./Tabla";
import { UsuarioModulos } from "./usuarioModulos/UsuarioModulos";
import { UsuarioProveedor } from "./usuarioProveedor/UsuarioProveedor";

export const UsuariosListado = () => {
  const dispatch = useDispatch();
  const { showForma } = useSelector((state) => state.usuariosReducer);

  return (
    <CardPage
      titulo="Usuarios"
      tituloBoton="Agregar"
      accionBoton={() => dispatch(startShowForma())}
    >
      <Tabla />
      <Modal
        title="Datos de Usuario"
        open={showForma}
        onCancel={() => dispatch(setShowForma(false))}
        footer={[
          <Button key="back" onClick={() => dispatch(setShowForma(false))}>
            Cerrar
          </Button>,
        ]}
      >
        <UsuarioForma />
        <UsuarioModulos />
        <UsuarioProveedor />
      </Modal>
    </CardPage>
  );
};
