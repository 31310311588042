import { Col, Row } from "antd";
import React from "react";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../utils/formatNumber";

export const AsistenciasConOP = () => {
  const {
    reportePorMes: { asistenciasOP },
    verTodo,
  } = useSelector((state) => state.reportesReducer);

  if (!asistenciasOP) return <div></div>;

  return (
    <div style={{ marginTop: 20 }}>
      <Row className="">
        <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
          En Creación
        </Col>
        <Col
          xs={5}
          md={4}
          lg={3}
          className="datoReporte"
          style={{ textAlign: "center", backgroundColor: "#f76e4f" }}
        >
          {formatNumber(
            (100 * asistenciasOP.asistenciasConOP) /
              asistenciasOP.asistenciasConOPTotal,
            2
          )}
          %
        </Col>

        {verTodo && (
          <>
            <Col
              xs={5}
              md={4}
              lg={3}
              style={{ textAlign: "center" }}
              className="datoReporte"
            >
              {formatNumber(asistenciasOP.asistenciasConOP, 0)}
            </Col>
            <Col
              xs={7}
              md={4}
              lg={4}
              style={{ textAlign: "right" }}
              className="datoReporte"
            >
              {formatCurrency(asistenciasOP.asistenciasConOPCosto)}
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
          Factura cargada
        </Col>
        <Col
          xs={5}
          md={4}
          lg={3}
          style={{ textAlign: "center", backgroundColor: "#f7b74f" }}
          className="datoReporte"
        >
          {formatNumber(
            (100 * asistenciasOP.asistenciasConFactura) /
              asistenciasOP.asistenciasConOPTotal,
            2
          )}
          %
        </Col>
        {verTodo && (
          <>
            <Col
              xs={5}
              md={4}
              lg={3}
              style={{ textAlign: "center" }}
              className="datoReporte"
            >
              {formatNumber(asistenciasOP.asistenciasConFactura, 0)}
            </Col>
            <Col
              xs={7}
              md={4}
              lg={4}
              style={{ textAlign: "right" }}
              className="datoReporte"
            >
              {formatCurrency(asistenciasOP.asistenciasConFacturaCosto)}
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
          Autorizadas
        </Col>
        <Col
          xs={5}
          md={4}
          lg={3}
          style={{ textAlign: "center", backgroundColor: "#f7f14f" }}
          className="datoReporte"
        >
          {formatNumber(
            (100 * asistenciasOP.asistenciasAutorizadas) /
              asistenciasOP.asistenciasConOPTotal,
            2
          )}
          %
        </Col>

        {verTodo && (
          <>
            <Col
              xs={5}
              md={4}
              lg={3}
              style={{ textAlign: "center" }}
              className="datoReporte"
            >
              {formatNumber(asistenciasOP.asistenciasAutorizadas, 0)}
            </Col>
            <Col
              xs={7}
              md={4}
              lg={4}
              style={{ textAlign: "right" }}
              className="datoReporte"
            >
              {formatCurrency(asistenciasOP.asistenciasAutorizadasCosto)}
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col xs={12} md={7} lg={6} xl={5} className="labelReporte">
          Pagadas
        </Col>
        <Col
          xs={5}
          md={4}
          lg={3}
          style={{ textAlign: "center", backgroundColor: "#bcf74f" }}
          className="datoReporte"
        >
          {formatNumber(
            (100 * asistenciasOP.asistenciasPagadas) /
              asistenciasOP.asistenciasConOPTotal,
            2
          )}
          %
        </Col>

        {verTodo && (
          <>
            <Col
              xs={5}
              md={4}
              lg={3}
              style={{ textAlign: "center" }}
              className="datoReporte"
            >
              {formatNumber(asistenciasOP.asistenciasPagadas, 0)}
            </Col>
            <Col
              xs={7}
              md={4}
              lg={4}
              style={{ textAlign: "right" }}
              className="datoReporte"
            >
              {formatCurrency(asistenciasOP.asistenciasPagadasCosto)}
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
