import React from "react";

import ListaCabecera from "./ListaCabecera";
import ListaCampos from "./ListaCampos";

export default function Lista() {
  return (
    <div style={{ marginTop: 20, minHeight: 200 }}>
      <ListaCabecera />
      <ListaCampos />
    </div>
  );
}
