export const fnDivideFeature = (feature) => {
  const { context, place_name_es, text, address } = feature;
  const salida = {
    direccionOrigen: place_name_es,
    cpOrigen: context[0].text,
    municipioOrigen: context[1].text,
    estadoOrigen: context[2].text,
  };

  return salida;
};
