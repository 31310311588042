import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

////
export default function useColums() {
  const history = useHistory();

  const handleClick = ({ clienteID }) =>
    history.push(`/clientes/detalle/${clienteID}`);

  const clientesColumns = [
    {
      title: "Orden",
      dataIndex: "ordenVisualizacion",
    },
    {
      title: "Nombre",
      dataIndex: "nombreComercial",
    },
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
    },
    {
      title: "Contacto",
      dataIndex: "contactoNombre",
    },
    {
      title: "Email",
      dataIndex: "contactoEmail",
    },
    {
      title: "Teléfono 1",
      dataIndex: "contactoTelefono1",
    },

    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined
          onClick={() => handleClick(record)}
          style={{ color: "#ca2228" }}
          className="eyeIcon"
        />
      ),
    },
  ];

  return { clientesColumns };
}
