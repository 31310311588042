import { Spin } from "antd";
import React from "react";

export default function Loading({texto}) {
  return (
    <div style={{ textAlign: "center", paddingTop: 50 }}>
     <div><Spin size="large" /></div>
     <div style={{color: "#74B6FF"}}>{texto}</div>
    </div>
  );
}
