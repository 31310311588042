import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const useClientesOptions = () => {
  const [clientesOptions, setClientesOptions] = React.useState([]);
  const { clientes } = useSelector((state) => state.clientesReducer);

  useEffect(() => {
    var lista = [];
    if (clientes) {
      clientes.forEach((item) => {
        lista.push({
          value: item.clienteID,
          label: item.nombreComercial,
        });
      });
    }
    setClientesOptions(lista);
  }, [clientes]);

  return { clientesOptions };
};
