import { URL_BASE_PROVEEDORES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { dele, get, getFile } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startLoadDocumentosProveedor = (proveedorID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_PROVEEDORES}/GetDocumentos/${proveedorID}`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeFilesProveedor(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get documentos P",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const startDownloadDocumentoProveedor = (
  proveedorDocumentoID,
  nombreDocumento,
  nombreArchivo
) => {
  return async (dispatch) => {
    const url = `${URL_BASE_PROVEEDORES}/GetDocumento/${proveedorDocumentoID}`;
    const nombreFinal = `${nombreDocumento}-${nombreArchivo}`;

    const result = await getFile(url, nombreFinal);

    if (result.code === -1) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Download documento P",
            `${result.data}`
          )
        )
      );
    }
  };
};

export const startDeleteFileProveedor = (proveedorDocumentoID, proveedorID) => {
  return async (dispatch) => {
    const result = await dele(
      `${URL_BASE_PROVEEDORES}/DeleDocumento/${proveedorDocumentoID}`
    );
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startLoadDocumentosProveedor(proveedorID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Dele documento p",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeFilesProveedor = (payload) => ({
  type: types.storeFilesProveedor,
  payload,
});
