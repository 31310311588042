import { useSelector } from "react-redux";

const UseMunicipiosEstados = () => {
  const { estadosMunicipios } = useSelector(state => state.proveedoresMunicipiosReducer)

  const { proveedorOrigen, proveedorDestino, proveedoresOrigenDestinoLPs } = useSelector(state => state.proveedoresMunicipiosReducer)
  const origen1 = proveedorOrigen.map(item => item.municipioId)
  const destino1 = proveedorDestino.map(item => item.municipioId)
  const arrayEstadosMunicipio = estadosMunicipios.map(item => (
    {
      label: item.nombre,
      options: item.municipios.map(municipio => ({ label: municipio.nombre, value: municipio.municipioId }))
    }))
  return {
    arrayEstadosMunicipio,
    origen1,
    destino1,
    origen2: proveedoresOrigenDestinoLPs.municipioProveedorOrigen2.map(item => item.municipioId),
    destino2: proveedoresOrigenDestinoLPs.municipioProveedorDestino2.map(item => item.municipioId),
    origen3: proveedoresOrigenDestinoLPs.municipioProveedorOrigen3.map(item => item.municipioId),
    destino3: proveedoresOrigenDestinoLPs.municipioProveedorDestino3.map(item => item.municipioId),
  }

};
export default UseMunicipiosEstados;
