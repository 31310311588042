import React from "react";

export const Sitios = ({ sitios, handleSitioClick }) => {
  if (sitios.length === 0) return <></>;
  return (
    <div className="infoMapa" style={{ maxWidth: 600, marginTop: 20 }}>
      {sitios.map((sitio, i) => (
        <div key={i}>
          <div onClick={(e) => handleSitioClick(sitio)} className="itemSitios">
            {sitio.place_name_es}
          </div>
        </div>
      ))}
    </div>
  );
};
