import { CloudDownloadOutlined } from "@ant-design/icons";
import {Col, Drawer, Empty, Row} from "antd";
import ItemsArchivos from "./listaArchivos/ItemsArchivos";

export default function DatosArchivos({ archivos }) {

  return (
    <>
      <Row gutter={10}>
        <Col span={8} className="tituloTabla">
          Nombre
        </Col>

        <Col span={7} className="tituloTabla">
          Tipo
        </Col>
      </Row>

      {archivos.map((x) => {
        return (
          <ItemsArchivos archivo={x} />
        );
      })}
    </>
  );
}
