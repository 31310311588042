import { Select } from "antd";

const { Option } = Select;

export default function LabelSelect({
  opciones,
  label,
  onChange,
  value,
  name,
  placeholder,
  defaultValue
}) {
  return (
    <>
      <div className="labelImput">{label}</div>
      <Select
        placeholder={placeholder}
        showSearch
        value={value}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
        style={{ width: "100%" }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {opciones.map(({ texto, valor }, i) => (
          <Option key={i} value={valor}>
            {texto}
          </Option>
        ))}
      </Select>
    </>
  );
}
