import Create from './Create'
import List from './List'

export default function Documentos() {
  return (<>
    <Create />
    <List />
  </>  
  )
}
