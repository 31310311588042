import { typesProveedorEstadosMunicipios } from "../types/typeProveedorMunicipio";

const initialState = {
  estadosMunicipios: [],
  proveedorOrigen: [],
  proveedorDestino: [],
  proveedoresOrigenDestinoLPs: [],
};
export const proveedoresMunicipiosReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesProveedorEstadosMunicipios.storeEstadosMunicipios:
      return {
        ...state,
        estadosMunicipios: action.payload,
      }
    case typesProveedorEstadosMunicipios.proveedorMunicipiosDestino:
      return {
        ...state,
        proveedorDestino: action.payload,
      }
    case typesProveedorEstadosMunicipios.storeProvedoresMuncipiosLps:
      return {
        ...state,
        proveedoresOrigenDestinoLPs: action.payload,
      }
    case typesProveedorEstadosMunicipios.proveedorMunicipiosOrigen:
      return {
        ...state,
        proveedorOrigen: action.payload,
      }
    default:
      return state;
  }
};
