import React from 'react'
import { useSelector } from 'react-redux'
import CardPage from '../../../layout/CardPage'
import { Skele } from '../dashboard/utils/Skele'
import { ClienteCabecera } from './ClienteCabecera'
import { ClientesItem } from './ClientesItem'
import { useClientes } from './useClientes'

export const Clientes2 = () => {

    const { clientes } = useClientes()

    const { loadingReporte } = useSelector(state => state.reportesReducer)
    if (loadingReporte)
        return <Skele />

    return (
        <CardPage>

            <ClienteCabecera />
            {clientes.map((c, i) => <ClientesItem key={i} cliente={c} i={i} />)}
        </CardPage>
    )
}
