import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setListaSelected,
  startGetListaCampos,
} from "../../../context/actions/listasActions";

export default function Nombre({ lista }) {
  const dispatch = useDispatch();
  const { valor: id } = useSelector(
    (state) => state.listasReducer.listaSelected
  );
  const handleClick = () => {
    dispatch(setListaSelected(lista));
    dispatch(startGetListaCampos(lista.valor));
  };

  return (
    <div
      onClick={handleClick}
      className={`listaSelect ${id == lista.valor && "seleccionado"}`}
    >
      {lista.texto}
    </div>
  );
}
