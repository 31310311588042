import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelValorLink from "../../../../../../components/label/LabelValorLink";
import { startDownloadDocumentoOP } from "../../../../../../context/actions/ordenesPagoActions";
import { CargaPagoModal } from "./CargaPagoModal";

export const CargaPago = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { estatusOrdenPagoID, fechaCargaPago, usuarioCargaPago, ordenPagoID } =
    useSelector((state) => state.ordenesPagoReducer.ordenPago);

  const getFile = () => dispatch(startDownloadDocumentoOP(ordenPagoID, 1)); // 1 = pago

  ////////////////////
  if (estatusOrdenPagoID < 5) return <div></div>;
  if (estatusOrdenPagoID === 5)
    return (
      <>
        <BotonSubmit
          style={{ marginTop: 18 }}
          tituloBoton="Cargar Pago a Proveedor"
          onClick={() => setShowModal(true)}
        />
        <CargaPagoModal showModal={showModal} setShowModal={setShowModal} />
      </>
    );

  return (
    <LabelValorLink
      label="Carga de pago"
      valor={`${fechaCargaPago} - ${usuarioCargaPago}`}
      linkLabel="ver"
      onClick={getFile}
    />
  );
};
