import { Result, Button } from "antd";
import { useHistory } from "react-router";

export const SinServicios = () => {
  const history = useHistory()
  return (
    <Result
      status="info"
      title="No existen servicios por facturar"
      extra={[
        <Button onClick={() => history.push("/ordenesPago")} key="buy">
          Regresar
        </Button>,
      ]}
    />
  );
};
