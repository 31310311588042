import { Col, Input, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BotonDiv from "../../../../components/Boton/BotonDiv";
import {
  cleanCampaniaForm,
  cleanCampaniaName,
  startPostCampania,
} from "../../../../context/actions/campaniasActions";
import ModelPage from "../../../../layout/ModelPage";
import FormaCampania from "./FormaCampania";
import useColums from "./useColums";

////
export default function Tabla() {
  const { campaniasColumns } = useColums();
  const { campanias } = useSelector((state) => state.clientesReducer.cliente);
  const dispatch = useDispatch();
  //
  const { clienteID } = useParams();
  const [openModal, setOpenModal] = useState(false);

  const handleAgregar = () => {
    setOpenModal(true);
    dispatch(cleanCampaniaName());
  };

  const handleOk = () => {
    dispatch(startPostCampania(clienteID));
    setOpenModal(false);
  };

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(campanias);
    dispatch(cleanCampaniaForm());
  }, [campanias]);

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = campanias.filter(
        (a) =>
          a.nombre &&
          a.nombre.toLowerCase().includes(target.value.toLowerCase())
      );
      setListaFinal(newList);
    } else {
      setListaFinal(campanias);
    }
  };

  ////
  return (
    <>
      <Row>
        <Col span={12}>
          {" "}
          <Input
            onChange={filter}
            placeholder="buscar"
            style={{ width: 300, marginBottom: 10 }}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <BotonDiv tituloBoton="Agregar" onClick={handleAgregar} />
        </Col>
      </Row>
      <Table
        rowKey="campaniaID"
        columns={campaniasColumns}
        dataSource={listaFinal}
      />
      <ModelPage
        titulo="Agregar Campaña"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <FormaCampania />
      </ModelPage>
    </>
  );
}
