import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelValorLink from "../../../../../../components/label/LabelValorLink";
import { startDownloadDocumentoOP } from "../../../../../../context/actions/ordenesPagoActions";
import { CargaComplementoPagoModal } from "./CargaComplementoPagoModal";

export const CargaComplementoPago = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { estatusOrdenPagoID, fechaCargaComplementoPago, usuarioCargaComplementoPago, ordenPagoID } =
    useSelector((state) => state.ordenesPagoReducer.ordenPago);

  const getFile = () => dispatch(startDownloadDocumentoOP(ordenPagoID, 2)); // 2 = complemento de pago

  ////////////////////
  if (estatusOrdenPagoID < 6) return <div></div>;
  if (estatusOrdenPagoID === 6)
    return (
      <>
        <BotonSubmit
          style={{ marginTop: 18 }}
          tituloBoton="Cargar Complemento de Pago"
          onClick={() => setShowModal(true)}
        />
        <CargaComplementoPagoModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </>
    );

  return (
    <LabelValorLink
      label="Complemento de pago"
      valor={`${fechaCargaComplementoPago} - ${usuarioCargaComplementoPago}`}
      linkLabel="ver"
      onClick={getFile}
    />
  );
};
