
const options = (value) => {
  return [{value: value, label: 'Si cumple'}, {value: 0, label: 'No cumple'}]
}
export const optionFormAtention = [
  {
    groupName: 'Guía de entrada',
    options: [
      {
        name: 'atenderLlamada',  label: 'Atender llamada en los primeros 3 segundos', radios: options(4)
      },
      {
       name:'buenoDiasTardesNoche' , label: 'Buenos días/tardes/noches', radios: options(2)
      },
      {
       name: 'nombreAtiende', label: 'Nombre de quien atiende', radios: options(2)
      },
      {
       name:'guionCompleto' , label: 'Guíon completo', radios: options(2)
      }
    ]
  },
  {
    groupName: 'Claridad de la información',
    options: [
      {
        name: 'inseguridad',label: 'Inseguridad (titubeo)', radios: options(4)

      },
      {
       name: 'argumentosClarosYOrden', label: 'Argumentos claros y en orden', radios: options(5)
      },
      {
       name:'controlLlamda' , label: 'Control de llamada', radios: options(4)
      }
    ]
  },
  {
    groupName: 'Dicción y fluidez',
    options: [
      {
        name: 'diccion',label: 'Dicción', radios: options(3)
      },
      {
        name: 'palabrasInnecesarias',label: 'Palabras innecesarias', radios: options(3)
      }
    ]
  },
  {
    groupName: 'Volumen de voz',
    options: [
      {
       name: 'volumenRimoVoz', label: 'Volumen y rimo de la voz', radios: options(5)
      },
      {
       name: 'modificacionVoz', label: 'Modificación de voz a petición del cliente', radios: options(2)
      },
      {
        name: 'ruidosExternos',label: 'Ruidos externos (respiración) / Uso correcto del micrófono', radios: options(5)
      }
    ]
  },
  {
    groupName: 'Vocabulario',
    options: [
      {
        name: 'barbarismos', label: 'Barbarismos', radios: options(2)
      },
      {
        name: 'pleonasmos',label: 'Pleonasmos', radios: options(2)
      },
      {
       name: 'muletillas', label: 'Muletillas', radios: options(2)
      }
    ]
  },
  {
    groupName: 'Personalización',
    options: [
      {
       name: 'dirigirsePorApellido', label: 'Dirigirse al asegurado por el nombre o apellido paterno, al menos en tres ocasiones',
        radios: options(2)
      }
    ]
  },
  {
    groupName: 'Guíon de salida',
    options: [
      {
       name: 'validarDudar', label: 'Validar si no tiene dudas o algo mas por ayudar', radios: options(2)
      },

      {
        name: 'envioEncuesta',label: 'Envío de encuesta', radios: options(2)
      },
      {
        name: 'guionSalida',label: 'Guión de salida', radios: options(2)
      },
    ]
  },
  {
    groupName: 'Actitud de servicio',
    options: [
      {
       name: 'formulasCortersia', label: 'Fórmulas de cortesía', radios: options(4)
      },
      {
       name: 'actitudSituacion', label: 'Actitud (actuar ante la situación) / Sentido de urgencia', radios: options(5)
      },
      {
      name:'actitudTonoVoz',  label: 'Actitud en el tono de voz', radios: options(5)
      },
      {
       name: 'interesResolverNecesidad', label: 'Interés en resolver necesidad', radios: options(5)
      }
    ]
  },
  {
    groupName: 'Escucha  activa',
    options: [
      {
       name: 'atencionInformacionCliente', label: 'Presta atención a la información proporcionada por el cliente', radios: options(5)
      },
      {
       name: 'acompaniaAsegurado', label: 'Acompaña  explicación de asegurado', radios: options(5)
      },
    ]
  },
  {
    groupName: 'Tiempo de espera',
    options: [
      {
       name: 'solicitarEspera', label: 'Solicitar espera de forma correcta', radios: options(3),

      },
      {
       name: 'agradecerEspera', label: 'Agradecer el tiempo de espera', radios: options(3)
      },
      {
      name: 'usarMute',  label: 'Usar Hold / Mute', radios: options(5)
      },

      {
      name: 'retomarLlamada',  label: 'Retomar llamada en tiempo establecido (máximo 45s seg)', radios: options(5)
      }
    ]
  }

]

export const optionTificacion  = [
  {
   label: 'Nombre titular / Beneficiario Completo', name: 'nombreTitular', options: options(10)
  },
  {
    label: 'Número telefónico', name: 'numeroTelefonico', options: options(10)
  },
  {
    label: 'Validación de póliza ', name: 'validacionPoliza', options: options(10)
  },
  {
    label: 'Validador 1', name: 'validador1', options: options(10)
  },
  {
    label: 'Validador 2', name: 'validador2', options: options(10)
  },
  {
    label: 'Tipo de servicio', name: 'tipoServicio', options: options(5)
  },
  {
   label: 'Sondeo y manejo de la información (datos de asistencia)', name: 'sondeoManejoInformacion', options: options(10)
  },
  {
    label: 'Ubicación de origen', name: 'ubicacionOrigen', options: options(10)
  },
  {
    label: 'Ubicación destino (en caso de aplicar)', name: 'ubicacionDestino', options: options(10)
  },
  {
    label: "Comentarios adicionales al servicio", name: 'comentariosAdicionales', options: options(10)
  },
  {
    label: 'Proporcionar número de reporte', name: 'numeroReporte', options: options(5)
  }
]

export const formatBody = (values,  id) => {
  let body = {}
  body.Calificacion = Object.values(values).filter(e => typeof e == 'number').reduce((accumulator, currentValue) => {
    return accumulator + currentValue
  }, 0)
  body.Encuesta = JSON.stringify(values)
  body.CalidadServicioId = id
  console.log(body)
  return body
}
