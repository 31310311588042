import { useSelector } from "react-redux";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatNumber } from "../../../../utils/formatNumber";
import { Cuadro } from "../utils/Cuadro";
import { Spinner } from "../utils/Spinner";
import { useFacturaSolicitada } from "./useFacturaSolicitada";

export const FacturaSolicitada = () => {
  const { asistencias, costo } = useFacturaSolicitada()

  const { loadingReporte } = useSelector(state => state.reportesReducer)
  if (loadingReporte)
    return <Spinner />

  return (
    <Cuadro
      backgroundColor="#2f5e16"
      titulo="Con factura solicitada"
      cantidad={formatNumber(asistencias)}
      monto={formatCurrency(costo)}
    />
  );
};
