import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startGetAsistenciasSinPago } from '../../../context/actions/ordenesPagoActions';
import { Cargando } from './Cargando';
import { ListadoProveedores } from './ListadoProveedores';
import { SinServicios } from './SinServicios';

export const Crear2 = () => {
    const dispatch = useDispatch();
    const { asistenciasElegibles2:{asistencias}, cargandoOrdenesSinPago } = useSelector(
      (state) => state.ordenesPagoReducer
    );
    useEffect(() => {
      dispatch(startGetAsistenciasSinPago());
    }, []);
  
    if (cargandoOrdenesSinPago) return <Cargando />;

    if (asistencias.length === 0) return <SinServicios />;
  
    return (
      <ListadoProveedores />
    );
  };
  