import {Button, message, Modal, Space, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useState} from "react";
import axios from "axios";
import {URL_BASE_ORDENESPAGO} from "../../constants/url";
import {useSelector} from "react-redux";

export const ModalCargaMasiva = ({setOpen, open}) => {
  const [error, setError] = useState({})
  const [file, setFile] = useState()

  const [messageApi, contextHolder] = message.useMessage()
  const usuarioId = useSelector(state => state.userReducer.user.usuarioID)

  function beforeUpload(file) {
    setFile(file)
    return false
  }

  async function upLoadFile() {
    const fileForm = new FormData();
    fileForm.append('file', file)
    fileForm.append('usuarioId',usuarioId)
    try {
      await axios.post(`${URL_BASE_ORDENESPAGO}/postCargaMasiva`, fileForm)
      messageApi.success('Pagos aplicados correctamente')
      setError({error: false, message: "Pagos cargados correctamente"})
    } catch
      (e) {
      messageApi.error("Error al cargar pagos, intente mas tarde")
      setError({error: true, message: 'Algo  inesperado ha ocurrido, intente mas tarde'})
    }
  }

  return (
    <>
      {contextHolder}
      <Modal
        title={"Cargar archivo xlsx"}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div
          style={{height: "200px"}}
        >
          <Space
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              alignItems: "flex-start"
            }}
          >

            <Upload
              maxCount={1}
              accept='text/xlsx'
              onRemove={() => {
                setFile(null);
                setError({})
              }
              }
              beforeUpload={beforeUpload}
            >

              <Button
                type='primary'
                icon={<UploadOutlined/>}
              >
                Seleccione un archivo
              </Button>
            </Upload>

            <Button onClick={upLoadFile} icon={<UploadOutlined/>}>
              Subir Archivo
            </Button>


          </Space>

          <span
            style={{
              color: error.error ? "red" : 'green',
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop: "80px",
              fontSize: "20px"
            }}
          >
            {error.message}
        </span>
        </div>
      </Modal>
    </>
  )
}