import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ambiente, nameProject, ver } from "../constants/url";
import { startGetVer } from "../context/actions/notificationActions";

//
export const Ver = () => {
  const dispatch = useDispatch();
  const { versionBackend } = useSelector((state) => state.notificationReducer);
  useEffect(() => {
    dispatch(startGetVer());
  }, []);

  return (
    <div style={{ marginLeft: 35, marginTop: 30, color: "#999" }}>
      <div>
        F {nameProject} {ver} {ambiente}
      </div>
      <div>B {versionBackend}</div>
    </div>
  );
};
