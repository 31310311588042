import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Col, Row, Radio } from "antd";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BotonDiv from "../../../../components/Boton/BotonDiv";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import { MAPBOX_TOKEN, plOptions } from "../../../../constants/cons";
import { startPostGeo } from "../../../../context/actions/proveedoresActions";
mapboxgl.accessToken = MAPBOX_TOKEN;

export const CreatePolygon = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [arreglo, setArreglo] = useState([]);
  const [tipo, setTipo] = useState();
  const map = useRef(null);
  const { proveedorID, sucursalSelectedID } = useParams();

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: map.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-99.172651, 19.396388],
      zoom: 10,
    });

    map.current.on("contextmenu", (e) => {
      const p = [e.lngLat.lng, e.lngLat.lat];
      agrega(p);
    });
  }, []);

  const agrega = (ele) => {
    let a = arreglo;
    a.push(ele);
    setArreglo(a);
    removeLine();
    createLine();
  };

  const handleCerrar = () => {
    let ultimo = arreglo[0];
    agrega(ultimo);
  };
  const handleLimpiar = () => {
    window.self.location.reload();
  };

  const createLine = () => {
    if (arreglo.length > 1) {
      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: arreglo,
          },
        },
      });

      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#888",
          "line-width": 4,
        },
      });
    }
  };

  const removeLine = () => {
    if (map.current.getLayer("route")) {
      map.current.removeLayer("route");
      map.current.removeSource("route");
    }
  };

  const onChange3 = ({ target: { value } }) => setTipo(value);

  ///////////////////////////

  const handleEnviar = () => {
    const body = {
      geo: JSON.stringify([arreglo]),
      tipo,
      sucursalSelectedID,
    };
    console.log(body);
    dispatch(startPostGeo(body));
  };

  //////////////////////
  return (
    <>
      <Row>
        <Col span={10}>
          <h2>Dibuja Polígono</h2>
        </Col>
        <Col span={10}>
          <Radio.Group
            options={plOptions}
            onChange={onChange3}
            value={tipo}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
        <Col span={4}>
          <BotonDiv
            onClick={() =>
              history.push(
                `/proveedores/sucursalDetalle/${proveedorID}/${sucursalSelectedID}`
              )
            }
            tituloBoton="Regresar"
          ></BotonDiv>
        </Col>
      </Row>

      <div ref={map} className="map-container2" />
      <Row gutter={10} style={{ marginTop: 10 }} justify="center">
        <Col span={6}>
          <BotonSubmit tituloBoton="Cerrar polígono" onClick={handleCerrar} />
        </Col>
        <Col span={6}>
          <BotonSubmit tituloBoton="Guardar Poligono" onClick={handleEnviar} />
        </Col>
        <Col span={6}>
          <BotonSubmit tituloBoton="Empezar de nuevo" onClick={handleLimpiar} />
        </Col>
      </Row>
    </>
  );
};
