import { Select } from "antd";
import { useEstadosOptions } from "../../../../hooks/useEstadosOptions";

export const Estados = ({ handleChange, body }) => {
  const { estadosOptions } = useEstadosOptions();

  return (
    <Select
      showSearch
      optionFilterProp="label"
    value={body.estadoOrigen}
      placeholder="estados"
      style={{ width: "100%" }}
      onChange={(val)=>handleChange("estadoOrigen", val)}
      options={estadosOptions}
    />
  );
};
