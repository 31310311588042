import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Col, Row } from "antd";
import { useEffect, useRef } from "react";

import { MAPBOX_TOKEN } from "../../../../../constants/cons";
import { Sucursales } from "./sucursales/Sucursales";

mapboxgl.accessToken = MAPBOX_TOKEN;

export default function Geocerca() {
  const map = useRef(null);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: map.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-99.172651, 19.396388],
      zoom: 10,
    });
  }, []);

  ///
  return (
    <Row>
      <Col xs={24} sm={8} lg={4} style={{ paddingTop: 28 }}>
        <Sucursales map={map} />
      </Col>
      <Col xs={24} sm={16} lg={20}>
        <div ref={map} className="map-container" />
      </Col>
    </Row>
  );
}
