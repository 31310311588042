import { Row, Col } from "antd";
import InfoOrdenPagoHistorial from "./InfoOrdenPagoHistorial";
import { Acciones } from "./acciones/Acciones";

export const InfoOrdenPago = () => {
  return (
    <Row gutter={10}>
      <Col span={16}>
        <Acciones />
      </Col>

      <Col span={8}>
        <InfoOrdenPagoHistorial />
      </Col>
    </Row>
  );
};
