import React, { useEffect } from "react";
import { Row, Col } from "antd";
import CardPage from "../../layout/CardPage";

import { ListaCampos } from "./listaCampos/ListaCampos";
import { ListaNombres } from "./listaNombres/ListaNombres";
import { useDispatch } from "react-redux";
import { startGetListas } from "../../context/actions/listasActions";

export const Listas = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetListas());
  }, []);

  return (
    <>
      <CardPage titulo="Listas Desplegables">
        <Row gutter={10}>
          <Col span={6}>
            <ListaNombres />
          </Col>
          <Col span={18}>
            <ListaCampos />
          </Col>
        </Row>
      </CardPage>
    </>
  );
};
