import CardPageSin from "../../../../layout/CardPageSin";
import Accion from "./Accion";
import MenuClientes from "./MenuClientes";

export default function Folder() {
  return (
    <>
      <CardPageSin>
        <MenuClientes />
        <Accion />
      </CardPageSin>
    </>
  );
}
