import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatNumber } from "../../../../utils/formatNumber";
import { Cuadro } from "../utils/Cuadro";
import { Spinner } from "../utils/Spinner";
import { useAsistencias } from "./useAsistencias";

export const Asistencias = () => {
  const { asistencias, costo } = useAsistencias()

  const { loadingReporte } = useSelector(state => state.reportesReducer)
  if (loadingReporte)
    return <Spinner />

  return (<>
    <Cuadro
      backgroundColor={"#5e1616"}
      titulo={<><div>Asistencias</div></>}
      cantidad={formatNumber(asistencias)}
      monto={formatCurrency(costo)}
    />
  </>);
};
