import { URL_BASE_CLIENTES, URL_BASE_PROVEEDORES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { post, get } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";
import { startGetProveedor } from "./proveedoresActions";

export const changeSucursal = (target) => ({
  type: types.changeSucursal,
  payload: target,
});

//////////////////

export const startPostSucursal = (proveedorID) => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_PROVEEDORES}/AddSucursal`;
    const { sucursal } = getState().proveedoresReducer;
    const body = { ...sucursal, proveedorID };

    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetProveedor(proveedorID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post sucursal",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const setPaginaDetalleSucursal = (pagina) => ({
  type: types.setPaginaDetalleSucursal,
  payload: pagina,
});

///////////////////////
export const startGetSucursal = (proveedorSucursalID) => {
  return async (dispatch) => {
    const result = await get(
      `${URL_BASE_PROVEEDORES}/GetSucursal/${proveedorSucursalID}`
    );

    if (result.code === 0) {
      dispatch(storeSucursal(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get sucursal ",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeSucursal = (sucursal) => ({
  type: types.storeSucursal,
  payload: sucursal,
});


/////////////////////////////////////////////

export const cleanFormaSucursal = () => ({
  type: types.cleanFormaSucursal,
  payload: null
})