import { getMonthNow } from "../../utils/getMonthNow";
import { getYearNow } from "../../utils/getYearNow";
import { types } from "../types/types";

const init = {
  asistenciasSinPago: [],
  ordenesPagoPagarFiltered: [],
  ordenesPago: [],
  ordenPago: {},
  ordenPagoItems: [],
  listaAsistenciasElegibles: [],
  ordenToEmailLoading: false,
  cargandoOrdenesSinPago: true,
  enviandoProveedor: false,
  dashboard1: [],
  asistenciasElegibles2: {},
  filtroOrdenesPago: {
    mes: getMonthNow() + 1,
    anio: getYearNow(),
  },
  ordenesPagoByEstatus: [],

  ordenesPagoValidarFactura: [],
  ordenesPagoAutorizar: [],
  ordenesPagoPagar: [],
  ordenesPagoPorCargarPago: [],

  proveedoresMes: [],
  filtroProveedoresMes: {
    mes: getMonthNow() + 1,
    anio: getYearNow(),
  },
  clientesMes: [],
  filtroClientesMes: {
    mes: getMonthNow() + 1,
    anio: getYearNow(),
  },
  ordenesPagoTxtIDs: []
};
export const ordenesPagoReducer = (state = init, action) => {
  switch (action.type) {

    case types.setOrdenesPagoTxtIDs:
      return {
        ...state,
        ordenesPagoTxtIDs: action.payload
      }

    case types.clearOrdenesPagoByEstatus:
      return {
        ...state,
        ordenesPagoByEstatus: []
      }

    case types.storeOrdenesPagoValidarFactura:
      return {
        ...state,
        ordenesPagoValidarFactura: action.payload
      }

    case types.storeOrdenesPagoAutorizar:
      return {
        ...state,
        ordenesPagoAutorizar: action.payload
      }

    case types.storeOrdenesPagoPagar:
      return {
        ...state,
        ordenesPagoPagar: action.payload
      }
    
      case types.storeOrdenesPagoPorCargarPago: 
        return {
          ...state,
          ordenesPagoPorCargarPago : action.payload
        }

    case types.storeOrdenesPagoByEstatus:
      return {
        ...state,
        ordenesPagoByEstatus: action.payload
      }



    case types.setFiltroProveedoresMes:
      return {
        ...state,
        filtroProveedoresMes: {
          ...state.filtroProveedoresMes,
          ...action.payload,
        },
      };

    case types.storeProveedoresMes:
      return {
        ...state,
        proveedoresMes: action.payload,
      };

    case types.setFiltroClientesMes:
      return {
        ...state,
        filtroClientesMes: {
          ...state.filtroClientesMes,
          ...action.payload,
        },
      };

    case types.storeClientesMes:
      return {
        ...state,
        clientesMes: action.payload,
      };


    case types.setFiltroOrdenesPago:
      return {
        ...state,
        filtroOrdenesPago: {
          ...state.filtroOrdenesPago,
          ...action.payload,
        },
      };
    case types.cleanOrdenPago:
      return {
        ...state,
        ordenPago: {},
        ordenPagoItems: [],
      };

    case types.storeOrdenPagoItems:
      return {
        ...state,
        ordenPagoItems: action.payload,
      };

    case types.setAsistenciasElegibles2:
      return {
        ...state,
        asistenciasElegibles2: action.payload,
      };

    case types.storeDashboard1:
      return {
        ...state,
        dashboard1: action.payload,
      };
    case types.setEnviandoProveedor:
      return {
        ...state,
        enviandoProveedor: action.payload,
      };

    case types.setCargandoOrdenesSinPago:
      return {
        ...state,
        cargandoOrdenesSinPago: action.payload,
      };
    case types.setOrdenToEmailLoading:
      return {
        ...state,
        ordenToEmailLoading: action.payload,
      };
    case types.storeListaAsistenciasElegibles:
      return {
        ...state,
        listaAsistenciasElegibles: action.payload,
      };
    case types.storeOrdenPago:
      return {
        ...state,
        ordenPago: action.payload,
      };

    case types.storeOrdenesPago:
      return {
        ...state,
        ordenesPago: action.payload,
      };
    case types.storeAsistenciasSinPago:
      return {
        ...state,
        asistenciasSinPago: action.payload,
      };
    case types.setOrdenPagarFiltered:
      return {
        ...state,
        ordenesPagoPagarFiltered: action.payload,
      }
    default:
      return state;
  }
};
