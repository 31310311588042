export const regimeArray = [
  {value: 601, label: 'General de Ley Personas Morales'},
  {value: 603, label: 'Personas Morales con Fines no Lucrativos'},
  {value: 605, label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios'},
  {value: 606, label: 'Arrendamiento'},
  {value: 607, label: 'Régimen de Enajenación o Adquisición de Bienes'},
  {value: 608, label: 'Demás ingresos'},
  {value: 610, label: 'Residentes en el Extranjero sin Establecimiento Permanente en México'},
  {value: 611, label: 'Ingresos por Dividendos (socios y accionistas)'},
  {value: 612, label: 'Personas Físicas con Actividades Empresariales y Profesionales'},
  {value: 614, label: 'Ingresos por intereses'},
  {value: 615, label: 'Régimen de los ingresos por obtención de premios'},
  {value: 616, label: 'Sin obligaciones fiscales'},
  {value: 620, label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'},
  {value: 621, label: 'Incorporación Fiscal'},
  {value: 622, label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'},
  {value: 623, label: 'Opcional para Grupos de Sociedades'},
  {value: 624, label: 'Coordinados'},
  {value: 625, label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'},
  {value: 626, label: 'Régimen Simplificado de Confianza'},
]


export const forMoral = [
  {label: 'Razón Social', value: 'razonSocial', type: 'text'},
  {
    label: 'RFC',
    value: 'rfc',
    rules: [{required: true, message: 'El RFC es obligatorio'}, {
      min: 12,
      message: 'El RFC debe tener mínimo   12 caracteres'
    }, {max: 13, message: 'El RFC debe tener máximo 13 caracteres'}]
  },
  {label: 'Nombre de la vialidad', value: 'nombreVialidad'},
  {label: 'Número exterior', value: 'noExterior'},
  {label: 'Número interior', value: 'noInterior'},
  {label: 'Colonia', value: 'nombreColonia'},
  {label: 'Nombre entidad federativa', value: 'entidadFederativa'},
  {label: 'Nombre municipio o DT', value: 'nombreMunicipio'},
  {label: 'Contacto Nombre', value: 'contactoNombre'},
  {label: 'Código Postal', value: 'cp'},
  {label: 'Nombre comercial', value: 'nombreComercial'},
  {label: 'Correo electrónico', value: 'contactoEmail', props: {type: 'email'}},
  {label: 'Teléfono 1', value: 'contactoTelefono1'},
  // {label: 'Contacto Teléfono', value: 'contactoTelefono1'},
  {label: 'Teléfono 2', value: 'contactoTelefono2'},
  {label: 'Teléfono 3', value: 'contactoTelefono3'},
  {label: 'IVA', value: 'iva', props: {type: 'number', min: 0, step: 0.01}, type: 'number'},
  {label: 'Iva retenido', value: 'ivaRetenido', props: {type: 'number'}, type: 'number'},
  {label: 'ISR retenido', value: 'isrRetenido', props: {type: 'number'}, type: 'number'},
  {label: 'Comentarios', value: 'comentarios', type: 'text'}

]


export const forFisica = [
  {label: 'Nombre', value: 'nombre', props: {type: 'text'}},
  {label: 'Apellido paterno', value: 'apellidoPaterno'},
  {label: 'Apellido materno', value: 'apellidoMaterno'},
]


export const entidadesFederativas = [
  {label: 'Aguascalientes', value: 1},
  {label: 'Baja California', value: 2},
  {label: 'Baja California Sur', value: 3},
  {label: 'Campeche', value: 4},
  {label: 'Coahuila', value: 5},
  {label: 'Colima', value: 6},
  {label: 'Chiapas', value: 7},
  {label: 'Chihuahua', value: 8},
  {label: 'Durango', value: 9},
  {label: 'CDMX', value: 10},
  {label: 'Guanajuato', value: 11},
  {label: 'Guerrero', value: 12},
  {label: 'Hidalgo', value: 13},
  {label: 'Jalisco', value: 14},
  {label: 'México', value: 15},
  {label: 'Michoacán', value: 16},
  {label: 'Morelos', value: 17},
  {label: 'Nayarit', value: 18},
  {label: 'NuevoLeon', value: 19},
  {label: 'Oaxaca', value: 20},
  {label: 'Puebla', value: 21},
  {label: 'Querétaro', value: 22},
  {label: 'QuintanaRoo', value: 23},
  {label: 'San Luis Potosi', value: 24},
  {label: 'Sinaloa', value: 25},
  {label: 'Sonora', value: 26},
  {label: 'Tabasco', value: 27},
  {label: 'Tamaulipas', value: 28},
  {label: 'Tlaxcala', value: 29},
  {label: 'Veracruz', value: 30},
  {label: 'Yucatán', value: 31},
  {label: 'Zacatecas', value: 32},
]


export const tipoPersona = [
  {label: 'Física', value: '1'},
  {label: 'Moral', value: '2'},
  {label: 'Sin tipo', value: 0}

]


export const arrayEntidadMunicipios = [
  {
    label: 'Campeche',
    options: [
      {label: 'Campeche', value: 'Campeche'},
      {label: 'Carmen', value: 'Carmen'},
      {label: 'Champotón', value: 'Champotón'}]
  }
  ,
  {
    label: 'Chiapas',
    options: [
      {label: 'Acacoyagua', value: 'Acacoyagua'},
      {label: 'Acala', value: 'Acala'},
      {label: 'Acapetahua', value: 'Acapetahua'}]
  }
  ,
  {
    label: 'Chihuahua',
    options: [
      {label: 'Ahumada', value: 'Ahumada'},
      {label: 'Aldama', value: 'Aldama'},
      {label: 'Allende', value: 'Allende'}]
  }
  ,
  {
    label: 'Coahuila',
    options: [
      {label: 'Abasolo', value: 'Abasolo'},
      {label: 'Acuña', value: 'Acuña'},
    ]
  },
  {
    label: 'Colima',
    options: [
      {label: 'Armería', value: 'Armería'},
      {label: 'Colima', value: 'Colima'},
      {label: 'Comala', value: 'Comala'}]
  }
  ,
  {
    label: 'Durango',
    options: [
      {label: 'Canatlán', value: 'Canatlán'},
      {label: 'Canelas', value: 'Canelas'},
      {label: 'Coneto de Comonfort', value: 'Coneto de Comonfort'}]
  }
]
