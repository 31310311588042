import { URL_BASE_LISTAS } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const getBancos = () => {
  return async (dispatch) => {
    const result = await get(
      `${URL_BASE_LISTAS}/GetItemsPorNombreLista/bancos`
    );

    if (result.code === 0) {
      dispatch(storeBancos(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get bancos",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeBancos = (payload) => ({
  type: types.storeBancos,
  payload,
});

///

export const startGetListas = () => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_LISTAS}`);

    if (result.code === 0) {
      dispatch(storeListas(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get listas",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeListas = (payload) => ({
  type: types.storeListas,
  payload,
});

///////////////

const setLoadingCampos = (payload) => ({
  type: types.setLoadingCampos,
  payload,
});

export const startGetListaCampos = (listaID, mostrarLoading = true) => {
  return async (dispatch) => {
    if (mostrarLoading) dispatch(setLoadingCampos(true));

    const result = await get(`${URL_BASE_LISTAS}/GetListaItems/${listaID}`);

    if (result.code === 0) {
      dispatch(storeListaCampos(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get campos",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setLoadingCampos(false));
  };
};

const storeListaCampos = (campos) => ({
  type: types.storeListaCampos,
  payload: campos,
});

export const setListaSelected = (lista) => ({
  type: types.setListaSelected,
  payload: lista,
});

///////

export const startPostLista = (nombre) => {
  return async (dispatch) => {
    const url = `${URL_BASE_LISTAS}/AddLista`;
    const result = await post(url, { nombre });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetListas());
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Lista",
            `${result.error}`
          )
        )
      );
    }
  };
};

/////////

export const startPostCampo = (clave) => {
  return async (dispatch, getState) => {
    const { valor } = getState().listasReducer.listaSelected;
    const body = {
      clave,
      valor: clave,
      listaID: valor,
    };

    const url = `${URL_BASE_LISTAS}/AddListaItem`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetListaCampos(valor, false));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Lista",
            `${result.error}`
          )
        )
      );
    }
  };
};

///////////

export const startDeleCampo = ({ id, listaID }) => {
  return async (dispatch) => {
    const url = `${URL_BASE_LISTAS}/DeleListaItem/${id}`;
    const result = await post(url);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetListaCampos(listaID, false));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - dele campo",
            `${result.error}`
          )
        )
      );
    }
  };
};

//////////
export const startPostListaBulk = (lista) => {
  return async (dispatch, getState) => {
    const { valor: listaID } = getState().listasReducer.listaSelected;
    const body = { lista, listaID };

    const url = `${URL_BASE_LISTAS}/PostBulk`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetListaCampos(listaID, false));
    } else {
      dispatch(
        notification(composeErrorNotification("Error de conexion - post bulk"))
      );
    }
  };
};
