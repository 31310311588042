import {useEffect, useState} from "react";
import FormularioBusquedaCalida from "./FormularioBusquedaCalida";
import TableCalidad from "./TableCalidad";
import CardPage from "../../layout/CardPage";
import axios from "axios";
import {URL_BASE} from "../../constants/url";

export default function Calidad() {
  const [data, setData] = useState([]);

    const getData = async () => {
        const data = await axios.get(`${URL_BASE}/api/calidad/GetServiciosCalidad`);
        setData(data.data);
    }

    useEffect(() => {
        getData();
    }, [])

  return (
    <CardPage titulo="Calidad">
      <FormularioBusquedaCalida setData={setData} />
      <TableCalidad dataSource={data} />
    </CardPage>
  );
}
