import { PieChart } from "react-minimal-pie-chart";
import { useSelector } from "react-redux";

export const ChartTotales = () => {
  const { asistenciasTodas, asistenciasOP } = useSelector(
    (state) => state.reportesReducer.reportePorMes
  );
  if (!asistenciasTodas) return <div></div>;

  return (
    <PieChart
      animate
      data={[
        {
          title: "Con OP",
          value: asistenciasTodas.asistenciasTotalCantidad,
          color: "#bcf74f",
        },
        {
          title: "Sin OP",
          value:
            asistenciasTodas.asistenciasTotalCantidad -
            asistenciasOP.asistenciasConOPTotal,
          color: "#f7b74f",
        },
      ]}
    />
  );
};
