import React from 'react'
import { useSelector } from 'react-redux'
import { EstatusItem } from './EstatusItem'
import { estatusClienteReduce } from './estatusClienteReduce'

export const Estatus = ({ cliente }) => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const listado = estatusClienteReduce(asistenciasMes, cliente.Id)

    return (
        <div style={{  padding: 5, borderBottom: '1px solid #ccc' }}>
            {listado.map((reg, i) => <EstatusItem reg={reg} key={i} />)}
        </div>
    )
}
