import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const useEstadosOptions = () => {
  const [estadosOptions, setEstadosOptions] = React.useState([]);
  const { estadosTodos: estados } = useSelector((state) => state.reportesReducer);

  useEffect(() => {
    var lista = [];
    if (estados) {
      estados.forEach((item) => {
        lista.push({
          value: item,
          label: item,
        });
      });
    }
    setEstadosOptions(lista);
  }, [estados]);

  return { estadosOptions };
};
