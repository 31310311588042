import React from 'react'
import { Select } from 'antd';


export const SelectSearch = ({ onChange, options, label, value }) => {
    return (<>
        <div className="labelImput">{label}</div>
        <Select
            value={value}
            style={{ width: '100%' }}
            showSearch
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={options}
        />
    </>)
}
