import React from "react";
import { useSelector } from "react-redux";
import { SeccionCampos } from "./SeccionCampos";

export const ListaCampos = () => {
  const { texto: nombre } = useSelector(
    (state) => state.listasReducer.listaSelected
  );

  if (nombre === undefined) return <></>;
  return (
    <>
      <div className="tituloLista">Campos en lista: {nombre}</div>
      <SeccionCampos />
    </>
  );
};
