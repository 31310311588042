import { Col, Row } from "antd";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { startDeleteFileProveedor, startDownloadDocumentoProveedor } from "../../../../../context/actions/proveedoresFilesActions";
//
export default function Item({ file }) {
  const dispatch = useDispatch();
  const { proveedorID } = useParams();
  const { proveedorDocumentoID, url, nombre, } = file;

  const handleDownload = () =>
    dispatch(startDownloadDocumentoProveedor(proveedorDocumentoID, nombre, url));

  const handleDelete = () =>
    dispatch(startDeleteFileProveedor(proveedorDocumentoID, proveedorID));
  return (
    <Row gutter={10} className="rowTabla">
      <Col span={5}>{nombre}</Col>

      <Col span={3} style={{ textAlign: "right" }}>
        <CloudDownloadOutlined
          onClick={handleDownload}
          className="icon"
          style={{ color: "blue" }}
        />
        <DeleteOutlined
          onClick={handleDelete}
          className="icon"
          style={{ color: "#ca2228" }}
        />
      </Col>
    </Row>
  );
}
