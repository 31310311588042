import React from "react";
import LabelTextarea from "../../../components/input/LabelTextarea";

export const CopyPaste = ({ onChange, value }) => {
  return (
    <LabelTextarea
      onChange={onChange}
      label="Agregue los elementos uno en cada renglón"
      rows={10}
      value={value}
    />
  );
};
