import React from "react";
import { useSelector } from "react-redux";
import { ProveedoresItem } from "./ProveedoresItem";

export const ListadoProveedores = () => {
  const { proveedores } = useSelector((state) => state.asistenciasReducer);
  return (
    <div style={{maxHeight: 350, overflowY: 'auto'}}>
      {proveedores.map((p, i) => (
        <ProveedoresItem key={i} proveedor={p} />
      ))}
    </div>
  );
};
