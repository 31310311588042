import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import ModelPage from "../../../../../../layout/ModelPage";
import { useUploadFile } from "../../useUploadFile";
import { useParams } from "react-router";

export const CargaPagoModal = ({ showModal, setShowModal }) => {
  const { ordenPagoID } = useParams();
  const { body, props, setBody, handleClick } = useUploadFile(1, ordenPagoID); // 1 = pago

  const ok = () => {
    handleClick();
    setShowModal(false);
  };

  const cancel = () => {
    setBody({});
    setShowModal(false);
  };

  //////////////
  return (
    <ModelPage
      titulo="Cargar Comprobante de Pago"
      isVisible={showModal}
      handleOk={ok}
      handleCancel={cancel}
    >
      <div className="tituloPequeño">
        Agregue el comprobante de pago bancario <br />
        Recuerda que el nombre es OP OrdenPagoID.pdf
      </div>

      <Upload {...props} style={{ width: "100%" }}>
        <Button
          style={{ textAlign: "left", marginTop: 20 }}
          icon={<UploadOutlined />}
        >
          Seleccione documento
        </Button>
      </Upload>
      <span style={{ marginLeft: 20 }}>{body.file?.name}</span>
    </ModelPage>
  );
};
