import React from "react";
import { Row, Col } from "antd";
import ServicioRow from "./ServicioRow";

export default function ListaServicios({ servicios }) {
  return (
    <div style={{ marginTop: 10 }}>
      <Row gutter={[ 10, 10 ]} style={{ marginBottom: 10 }}>
        <Col span={2}></Col>
        <Col span={4}></Col>
        <Col span={2} style={{ textAlign: "center" }}>
          Lp1
        </Col>
         <Col span={2} style={{ textAlign: "center" }}>
        LP1m
      </Col>
        <Col span={2} style={{ textAlign: "center" }}>
        LP2
      </Col>
         <Col span={2} style={{ textAlign: "center" }}>
        LP2m
      </Col>

        <Col span={2} style={{ textAlign: "center" }}>
          LP3
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          LP3
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          Banderazo
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          x Km
        </Col>
      </Row>

      {servicios.map((servicio, i) => (
        <ServicioRow key={i} servicio={servicio} />
      ))}
    </div>
  );
}
