import React, { useState } from "react";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelValorLink from "../../../../../../components/label/LabelValorLink";
import { CargaFacturaModal } from "./CargaFacturaModal";
import { DescargaFilesModal } from "./DescargaFilesModal";

export const CargaFactura = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalFiles, setShowModalFiles] = useState(false);

  const { estatusOrdenPagoID, fechaCargaFactura, usuarioCargaFactura } =
    useSelector((state) => state.ordenesPagoReducer.ordenPago);

  //////////////
  if (estatusOrdenPagoID < 2) return <div></div>;
  if (estatusOrdenPagoID === 2)
    return (
      <>
        <BotonSubmit
          style={{ marginTop: 8 }}
          tituloBoton="Cargar Documentos de Factura"
          onClick={() => setShowModal(true)}
        />
        <CargaFacturaModal showModal={showModal} setShowModal={setShowModal} />
      </>
    );

  return (
    <>
      
      <LabelValorLink
        label="Documentos de factura"
        valor={`${fechaCargaFactura} - ${usuarioCargaFactura}`}
        linkLabel="ver"
        onClick={() => setShowModalFiles(true)}
      />

      <DescargaFilesModal
        showModal={showModalFiles}
        setShowModal={setShowModalFiles}
      />

    </>
  );
};
