import React from "react";
import { useSelector } from "react-redux";
import Documentos from "./documentos/Documentos";
import Estadisticas from "./estadisticas/Estadisticas";

import InformacionPago from "./informacionPago/InformacionPago";
import NivelesServicio from "./nivelesServicio/NivelesServicio";

import Sucursales from "./sucursales/Sucursales";
import Cobertura from "./autoAsignacion/Cobertura";

export default function Accion() {
  const { pagina } = useSelector((state) => state.proveedoresReducer);

  if (pagina === "sucursales") return <Sucursales />;
  if (pagina === "informacionPago") return <InformacionPago />;
  if (pagina === "documentos") return <Documentos />;
  //
  if (pagina === "nivelesServicio") return <NivelesServicio />;
  if (pagina === "estadisticas") return <Estadisticas />;
  if (pagina === "cobertura") return <Cobertura/>;

  return <div>pagian no encontarda</div>;
}
