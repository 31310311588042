export function UseNoRepeatArrayOP(ordenesPago,  arrayPropiedadToFilter  ){
  const properties = {}
  for (const propiedad of arrayPropiedadToFilter) {
    properties[propiedad] = [ ...new Set(ordenesPago.map(item => item?.[propiedad])) ].map(item => ({
      text: item,
      value: item
    }))
 }
   return (
    properties
  );
}

