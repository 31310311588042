import { Select } from "antd";
import React from "react";
import { useProveedoresOptions } from "../../../../hooks/useProveedoresOptions";

export const Proveedores = ({ handleChange, body }) => {
  const { proveedoresOptions } = useProveedoresOptions();

  return (
    <Select
      showSearch
      optionFilterProp="label"
      value={body.proveedorID}
      placeholder="proveedores"
      style={{ width: "100%" }}
      onChange={(val) => handleChange("proveedorID", val)}
      options={proveedoresOptions}
    />
  );
};
