import { useEffect, useState } from "react"
import { useSelector } from "react-redux"


export const useProveedores = () => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const [proveedores, setProveedores] = useState([])

    useEffect(() => {
        var result = [];
        asistenciasMes.reduce((res, value) => {
            if (!res[value.proveedorID]) {
                res[value.proveedorID] = { proveedor: value.proveedor, Id: value.proveedorID, costo: 0, cantidad: 0 };
                result.push(res[value.proveedorID])
            }
            res[value.proveedorID].costo += value.costo;
            res[value.proveedorID].cantidad++;

            return res;
        }, {})

        result.sort((a, b) => {
            return b.costo - a.costo;
        });

        setProveedores(result)

    }, [asistenciasMes])

    return { proveedores }
}
