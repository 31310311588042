import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelText from "../../../../../../components/input/LabelText";
import LabelTextarea from "../../../../../../components/input/LabelTextarea";
import { changeSucursal } from "../../../../../../context/actions/sucursalesActions";

export default function FormaSucursal() {
  const dispatch = useDispatch();
  const { nombre, telefono1, telefono2, telefono3, comentarios, horarioAtencion } = useSelector(
    (state) => state.proveedoresReducer.sucursal
  );
  const handleChange = ({ target }) =>
    dispatch(changeSucursal({ [target.name]: target.value }));
  return (
    <Row gutter={[10, 10]}>
      <Col span={12}>
        <LabelText
          label="Nombre de sucursal"
          name="nombre"
          onChange={handleChange}
          value={nombre}
        />
      </Col>
      <Col span={12}>
        <LabelText
          label="Telefono1"
          name="telefono1"
          onChange={handleChange}
          value={telefono1}
        />
      </Col>
      <Col span={12}>
        <LabelText
          label="Telefono2"
          name="telefono2"
          onChange={handleChange}
          value={telefono2}
        />
      </Col>
      <Col span={12}>
        <LabelText
          label="Telefono3"
          name="telefono3"
          onChange={handleChange}
          value={telefono3}
        />
      </Col>
      <Col span={12}>
        <LabelTextarea
          label="Comentarios de la sucursal"
          name="comentarios"
          value={comentarios}
          rows={1}
          onChange={handleChange}
        />
      </Col>
      <Col span={12}>
        <LabelText
          label="Horario Atencion"
          name="horarioAtencion"
          value={horarioAtencion}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
}
