import AudioPlayer from "./audioPlayer/AudioPlayer";
import {Input} from "antd";

export default function QuejaUsuario({audio, texto, tipoContacto, fecha}) {
  return (
    <>
      <p>{fecha}</p>

      {
        audio ?
          <AudioPlayer audio={audio}/>
          :
          <h4 style={{fontStyle: "italic"}}>{`"${texto}"`}</h4>
      }

      <p>El usuario quiere ser contactado por {tipoContacto}</p>
    </>
  )
}