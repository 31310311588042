import { Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import { SelectAccesoBackOffice } from "../../../../components/select/SelectAccesoBackOffice";
import { startAddAcesoBackOffice } from "../../../../context/actions/accesoBackOfficeActions";

export const UsuarioModulosCrear = () => {
  const dispatch = useDispatch();
  const [modulo, setModulo] = useState();

  const handleClick = () => {
    console.log(modulo);
    if (modulo) dispatch(startAddAcesoBackOffice(modulo));
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <SelectAccesoBackOffice onChange={(val) => setModulo(val)} />
        </Col>
        <Col span={5} style={{ paddingTop: 26 }}>
          <BotonDivL onClick={handleClick} tituloBoton="Agregar" />
        </Col>
      </Row>
    </>
  );
};
