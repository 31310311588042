import { Col, Input, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { startUpdateNombreTipoAsisrtencia } from "../../../../context/actions/tiposAsistenciaActions";
import { useParams } from "react-router-dom";

export default function Cabecera() {
  const dispatch = useDispatch();
  const { tipoAsistenciaID } = useParams();
  //
  const { nombre } = useSelector(
    (state) => state.tiposAsistenciaReducer.tipoAsistenciaSelected
  );
  const [name, setName] = useState("");

  useEffect(() => {
    setName(nombre);
  }, [nombre]);

  const hanldeKey = ({ keyCode }) => {
    if (keyCode === 13) {
      const body = { nombre: name, tipoAsistenciaID };
      dispatch(startUpdateNombreTipoAsisrtencia(body));
    }
  };
  return (
    <Row style={{ marginBottom: 20 }}>
      <Col span={22}>
        <Input
          value={name}
          onChange={({ target }) => setName(target.value)}
          onKeyDown={hanldeKey}
        />
      </Col>
      <Col span={2}>
        <Tooltip title="Presiona enter para guardar cambios">
          <QuestionCircleOutlined
            style={{
              fontSize: 18,
              marginTop: 6,
              marginLeft: 10,
              color: "#999",
            }}
          />
        </Tooltip>
      </Col>
    </Row>
  );
}
