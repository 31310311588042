import { Col, Row, Select } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { startGetProveedoresServicio } from "../../context/actions/asignacionActions";

export const SelectProveedor = ({ setProveedorID, a: { servicioID } }) => {
  const dispatch = useDispatch();
  const [opciones, setOpciones] = useState([]);

  const { proveedoresServicio, loadingProveedoresServicio } = useSelector(
    (state) => state.asignacionReducer
  );
  useEffect(() => {
    dispatch(startGetProveedoresServicio(servicioID));
  }, []);

  useEffect(() => {
    let arreglo = [];
    proveedoresServicio.map((p) => {
      arreglo = [
        ...arreglo,
        { value: p.proveedorID, label: p.nombreComercial },
      ];
    });
    setOpciones(arreglo);
  }, [proveedoresServicio]);

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Select
            placeholder="Seleccione proveedor"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(val) => setProveedorID(val)}
            disabled={loadingProveedoresServicio}
            loading={loadingProveedoresServicio}
            options={opciones}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </>
  );
};
