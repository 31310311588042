import { Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetAsistenciasMes } from "../../context/actions/reportesActions";
import { Controles } from "./dashboard/utils/Controles";
import { Dashboard } from "./dashboard/Dashboard";
import { Proveedores2 } from "./proveedores2/Proveedores2";
import { Clientes2 } from "./clientes2/Clientes2";

export const ReportesGeneral = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetAsistenciasMes());
  }, [dispatch]);

  const items = [
    {
      key: "1",
      label: `Proveedores`,
      children: <Proveedores2 />,
    },
    {
      key: "2",
      label: `Clientes`,
      children: <Clientes2 />,
    },
  ];
  return (
    <>
      <Controles />
      <Dashboard />
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};
