import { validarEmail } from "./validaEmail";

export const validaUsuario = ({
  nombre,
  email,
  password,
  password2,
  usuarioID,
}) => {
  console.log({ email });
  if (nombre === "" || nombre === undefined)
    return {
      hasError: true,
      message: "El nombre es necesario",
    };

  if (email === "" || email === undefined) {
    return {
      hasError: true,
      message: "El email es necesario",
    };
  } else if (!validarEmail(email)) {
    return {
      hasError: true,
      message: "Formato incorrecto de email",
    };
  }

  if (usuarioID !== undefined && usuarioID !== "") {
    // editar
    if (password !== "" && password !== undefined) {
      if (password.length < 6) {
        return {
          hasError: true,
          message: "Al menos 6 caracteres contraseña",
        };
      } else if (password !== password2) {
        return {
          hasError: true,
          message: "Las contraseñas no coinciden",
        };
      }
    }
  } else {
    // crear
    if (password === "" || password === undefined) {
      return {
        hasError: true,
        message: "La contraseña es necesaria",
      };
    } else if (password.length < 6) {
      return {
        hasError: true,
        message: "Al menos 6 caracteres contraseña",
      };
    } else if (password !== password2) {
      return {
        hasError: true,
        message: "Las contraseñas no coinciden",
      };
    }
  }

  return {
    hassError: false,
  };
};
