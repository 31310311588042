import { Button, Drawer, Form, Input, notification, Select } from "antd";
import { formatBody, optionTificacion } from "../../../utils/optionsFormAtention";
import { URL_BASE_CALIDAD } from "../../../constants/url";
import { post } from "../../../utils/fetch";

export default function FormularioCalificacionTipificacion({setOpen, open, id, data}) {
  const defaultValues =  JSON.parse(data?.encuesta || '{}')
  const { TextArea} = Input
  const [form] = Form.useForm();
  const openNotificationWithIcon = (type, title, content) => {
    notification[type]({
      message: title,
      description:content
    });
  };
  const onFinish = async (values) => {
    const body = formatBody(values, id)
    console.log({body})
    const data =  await post(`${URL_BASE_CALIDAD}/PostEncuestaTipificacion`, body)
    console.log({data: data})
    if(data?.code === -1 ){
      openNotificationWithIcon('error', "Error al enviar la encuesta", "Ha ocurrido un error intenta mas tarde")
      form.resetFields()
    }else {
      openNotificationWithIcon('success', "Encuesta guardada correctamente", "Su encuesta se ha guardado correctamente")
      setOpen(false)
    }

  };


  // const marginTop = -25;

  return (
    <Drawer
      title="Calificar Tipificación"
      placement="right"
      onClose={() => setOpen(false)}
      open={open}
    >
      <Form onFinish={onFinish} form={form} scrollToFirstError
            initialValues={defaultValues}
            layout={'vertical'}
      >
        {optionTificacion.map(item => (
          <>
            <Form.Item key={item.name}>
              <div className="inputLabel" style={{fontSize: 14}}>
                {item.label}
              </div>
              <Form.Item
                name={item.name}
                rules={[{required: true, message: "Ingresa la respuesta"}]}
              >
                <Select options={item.options}  disabled={Object.values(defaultValues).length > 0} size="small" />
              </Form.Item>
            </Form.Item>
          </>
        ))}

        <div className="inputLabel" style={{fontSize: 14}}>
          Observaciones
        </div>
        <Form.Item name={'observaciones'} >
          <TextArea disabled={Object.values(defaultValues).length > 0}  />
        </Form.Item>

        <Button  disabled={Object.values(defaultValues).length > 0} type="primary" htmlType="submit">
          Enviar
        </Button>
      </Form>
    </Drawer>
  );
}
