import { useSelector } from "react-redux";
import Enviar from "./Enviar";
import ListaServicios from "./ListaServicios";

export default function Servicios() {
  const { tipoAsistenciaSelected } = useSelector(
    (state) => state.proveedoresReducer
  );

  return (
    <>
      <div className="tituloServicios">{tipoAsistenciaSelected.nombre}</div>
      <ListaServicios servicios={tipoAsistenciaSelected.servicios} />
      <Enviar />
    </>
  );
}
