import { Col, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LabelValor from "../../../../components/label/LabelValor";
import { CalificarEstrellas } from "../../../proveedores/detalle/infoProveedor/CalificarEstrellas";
import { LogoEmpresa } from "./LogoEmpresa";

export default function Info() {
  const dispatch = useDispatch();
  const {
    razonSocial,
    nombreComercial,
    rfc,
    contactoNombre,
    contactoEmail,

    contactoTelefono1,
    contactoTelefono2,
    contactoTelefono3,
    comentarios,
    tipoTelefono1,
    tipoTelefono2,
    tipoTelefono3,
    direccionFisica,
  } = useSelector((state) => state.clientesReducer.cliente);

  ////////////
  return (
    <>
      <Row>
        <Col span={20}>
          <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
            <Col span={6}>
              <LabelValor label="Nombre comercial" valor={nombreComercial} />
            </Col>
            <Col span={6}>
              <LabelValor label="Razón social" valor={razonSocial} />
            </Col>
            <Col span={6}>
              <LabelValor label="RFC" valor={rfc} />
            </Col>
          </Row>
          <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
            <Col span={6}>
              <LabelValor label="Contacto" valor={contactoNombre} />
            </Col>

            <Col span={6}>
              <LabelValor label="Email" valor={contactoEmail} />
            </Col>
          </Row>

          <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
            <Col span={6}>
              <LabelValor
                label={`Teléfono ${tipoTelefono1}`}
                valor={contactoTelefono1}
              />
            </Col>
            <Col span={6}>
              <LabelValor
                label={`Teléfono ${tipoTelefono2}`}
                valor={contactoTelefono2}
              />
            </Col>
            <Col span={6}>
              <LabelValor
                label={`Teléfono ${tipoTelefono3}`}
                valor={contactoTelefono3}
              />
            </Col>
          </Row>
          <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <LabelValor label="Direción física" valor={direccionFisica} />
            </Col>
          </Row>
          <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <LabelValor label="Comentarios" valor={comentarios} />
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <LogoEmpresa />
        </Col>
      </Row>
    </>
  );
}
