import { Select } from "antd";

const { Option } = Select;

export default function TipoIvaSelect({
  label,
  onChange,
  value,
  name,
  placeholder,
}) {
  return (
    <>
      <div className="labelImput">{label}</div>
      <Select
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        style={{ width: "100%" }}
      >
        <Option value="0%">0%</Option>
        <Option value="8%">8%</Option>
        <Option value="16%">16%</Option>
      </Select>
    </>
  );
}
