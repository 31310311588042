import { Button, Col, Row } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import LabelTextarea from '../../../../../../components/input/LabelTextarea'
import { startAutorizaOP2, startRechazarOP } from '../../../../../../context/actions/ordenesPagoActions'
import ModelPage from '../../../../../../layout/ModelPage'

export const AutorizarPago2Modal = ({ showModal, setShowModal }) => {
    const dispatch = useDispatch()
    const { ordenPagoID } = useParams()
    const [pagoProcede, setPagoProcede] = useState(false)
    const [comentario, setComentario] = useState("")


    const handleOK = () => {

        if (pagoProcede === 0)
            dispatch(startRechazarOP({ ordenPagoID, comentario }))
        if (pagoProcede === 1)
            dispatch(startAutorizaOP2(ordenPagoID))

        setShowModal(false)
        setComentario("")
    }
    // 
    return (
        <ModelPage
            titulo="Confirmar Pago 2 nivel"
            isVisible={showModal}
            handleOk={handleOK}
            handleCancel={() => setShowModal(false)}
        >

            <Row>
                <Col span={12}>
                    <Button onClick={() => setPagoProcede(0)} danger>
                        Pago NO procede
                    </Button>
                </Col>
                <Col span={12}>
                    <Button onClick={() => setPagoProcede(1)}>
                        Pago SI procede
                    </Button>
                </Col>
            </Row>

            {pagoProcede === 0 && (
                <>
                    <div className="tituloPequeño" style={{ marginTop: 10 }}>
                        El pago NO procede, se borrarán los documentos cargados, y se regresá al estado inicial <br />
                    </div>
                    <LabelTextarea
                        value={comentario}
                        name="comentario"
                        onChange={({ target }) => setComentario(target.value)}
                        style={{ marginTop: 10 }}
                        label="Porque no procede"
                    />
                </>
            )}

            {pagoProcede === 1 && (
                <>
                    <div className="tituloPequeño" style={{ marginTop: 10 }}>
                        El pago Si procede, click en OK para continuar a la siguente fase <br />
                    </div>

                </>
            )}

        </ModelPage>
    )
}
