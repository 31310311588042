import { Button, Col, Row } from "antd";
import { Fade } from "react-reveal";
import BotonCard from "../components/Boton/BotonCard";
import BotonDiv from "../components/Boton/BotonDiv";

export default function CardPage({
  children,
  titulo,
  tituloBoton,
  accionBoton,
  tituloBoton2,
  accionBoton2,
  style
}) {
  return (
    <Fade>
      <div style={{ marginBottom: 15 }}>
        <Row>
          <Col span={18} className="card__titulo_container">
            <div className="card__titulo">{titulo}</div>
          </Col>
          <Col span={3} className="card__accion">
            {tituloBoton2 && (
              <BotonDiv onClick={accionBoton2} tituloBoton={tituloBoton2} />
            )}
          </Col>
          <Col span={3} className="card__accion">
            {tituloBoton && (
              <BotonDiv onClick={accionBoton} tituloBoton={tituloBoton} />
            )}
          </Col>
        </Row>

        <div className="card__contenido" style={{...style}}>{children}</div>
      </div>
    </Fade>
  );
}
