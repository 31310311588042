import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelValor from "../../../../../../components/label/LabelValor";
import { ConfirmarFacturaModal } from "./ConfirmarFacturaModal";

export const ConfirmarFactura = () => {
  const [showModal, setShowModal] = useState(false);

  const { estatusOrdenPagoID, fechaSolicitudPago, usuarioSolicitudPago } =
    useSelector((state) => state.ordenesPagoReducer.ordenPago);

  if (estatusOrdenPagoID < 3) return <div></div>;
  if (estatusOrdenPagoID === 3)
    return (
      <>
        <BotonSubmit
          style={{ marginTop: 18 }}
          tituloBoton="Confirmar Factura"
          onClick={() => setShowModal(true)}
        />
        <ConfirmarFacturaModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </>
    );

  return (
    <LabelValor
      label="Solicitud de pago"
      valor={`${fechaSolicitudPago} - ${usuarioSolicitudPago}`}
    />
  );
};
