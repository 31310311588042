import { Tooltip } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import BotonDivL from '../../../../components/Boton/BotonDivL'
import { startQuitarProveedor } from '../../../../context/actions/asistenciasActions'
import ModelPage from '../../../../layout/ModelPage'
import { Forma } from './Forma'

export const QuitaProveedor = () => {
    const { estatusID } = useSelector(
        (state) => state.asistenciasReducer.asistencia
    );
    const { asistenciaID } = useParams();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);

    if (estatusID < 3 || estatusID > 5) return <Tooltip title="No es posble quitar">Quitar Proveedores</Tooltip>;

    const handleOk = () => {
        dispatch(startQuitarProveedor(asistenciaID));
        setOpenModal(false);
    };


    return (
        <>
            <BotonDivL
                tituloBoton="Quitar Proveedores"
                onClick={() => setOpenModal(true)}
            />
            <ModelPage
                width={800}
                titulo="Quitar Proveedores"
                handleOk={handleOk}
                handleCancel={() => setOpenModal(false)}
                isVisible={openModal}
            >
                <Forma />
            </ModelPage>
        </>
    )
}
