import { asignacionReducer } from "../reducers/asignacionReducer";
import { asistenciasReducer } from "../reducers/asistenciasReducer";
import { authErrReducer } from "../reducers/authErrReducer";
import { campaniasReducer } from "../reducers/campaniasReducer";
import { clientesReducer } from "../reducers/clientesReducer";
import { listasReducer } from "../reducers/listasReducer";
import { loadingReducer } from "../reducers/loadingReducer";
import { mapReducer } from "../reducers/mapReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { ordenesPagoReducer } from "../reducers/ordenesPagoReducer";
import { proveedoresReducer } from "../reducers/proveedoresReducer";
import { reportesReducer } from "../reducers/reportesReducer";
import { tiposAsistenciaReducer } from "../reducers/tiposAsistenciaReducer";
import { userReducer } from "../reducers/userReducer";
import { usuariosReducer } from "../reducers/usuariosReducer";
import { proveedoresMunicipiosReducer } from "../reducers/proveedorMunicipiosReducer";
import { appReducer } from "../reducers/appReducer";
import { opServiciosReducer } from "../reducers/opServiciosReducer";

export const allReducers = {
  authErrReducer: authErrReducer,
  userReducer: userReducer,
  notificationReducer: notificationReducer,
  clientesReducer: clientesReducer,
  proveedoresReducer: proveedoresReducer,
  tiposAsistenciaReducer: tiposAsistenciaReducer,
  campaniasReducer: campaniasReducer,
  loadingReducer: loadingReducer,
  listasReducer: listasReducer,
  mapReducer: mapReducer,
  usuariosReducer: usuariosReducer,
  asistenciasReducer: asistenciasReducer,
  ordenesPagoReducer: ordenesPagoReducer,
  asignacionReducer: asignacionReducer,
  reportesReducer,
  proveedoresMunicipiosReducer,
  appReducer,
  opServiciosReducer,
};
