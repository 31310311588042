import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { startLoadDocumentos } from "../../../context/actions/campaniasActions";
import { setPaginaCliente, startGetCliente } from "../../../context/actions/clientesActions";
import Campanias from "./campanias/Campanias";
import Folder from "./folder/Folder";
import InfoCliente from "./infoCliente/InfoCliente";

export default function Detalle() {
  const dispatch = useDispatch();
  const { clienteID } = useParams();
  useEffect(() => {
    dispatch(startGetCliente(clienteID));
    dispatch(startLoadDocumentos(clienteID));
    dispatch(setPaginaCliente("campanias"));
  }, []);

  return (
    <>
      <InfoCliente />
      <Folder />
    </>
  );
}
