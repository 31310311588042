import { URL_BASE_USUARIOS } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

////////////////////////////////////////////////////////////
export const startGetAcesoBackOffice = () => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().usuariosReducer.usuario;
    const url = `${URL_BASE_USUARIOS}/GetAcesoBackOffice/${usuarioID}`;

    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeAccesoBackOffice(result.data));
    } else {
      dispatch(notification(composeErrorNotification("GetAcesoBackOffice")));
    }
  };
};

const storeAccesoBackOffice = (payload) => ({
  type: types.storeAccesoBackOffice,
  payload,
});

export const cleanAccesoBackOffice = () => ({
  type: types.cleanAccesoBackOffice,
  payload: null,
});
////////////////////////////////////////////////////////////

export const startAddAcesoBackOffice = (modulo) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().usuariosReducer.usuario;
    const body = { usuarioID, modulo };
    const url = `${URL_BASE_USUARIOS}/AddAcesoBackOffice`;

    const result = await post(url, body);
    if (result.code === 0) {
      console.log(result.data);
      dispatch(notification(composeSuccessNotification(result.data)));

      dispatch(startGetAcesoBackOffice());
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetAcesoBackOffice", result.error)
        )
      );
    }
  };
};

////////////////////

export const startRemoveAcesoBackOffice = (id) => {
  return async (dispatch) => {
    const url = `${URL_BASE_USUARIOS}/RemoveAcesoBackOffice/${id}`;

    const result = await get(url);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetAcesoBackOffice());
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetAcesoBackOffice", result.error)
        )
      );
    }
  };
};
