import { Input } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { clearProveedoresByTexto, startGetProveedoresByTexto } from "../../../../context/actions/asistenciasActions";

export const BuscaProveedor = () => {
  const dispatch = useDispatch();
  const [texto, setTexto] = useState("");

  const handleChange = ({ target: { value } }) => {
    setTexto(value);
    if (value.length > 2) {
      dispatch(startGetProveedoresByTexto(value));
      return
    }
    dispatch(clearProveedoresByTexto());

  };
  return (<div style={{marginBottom: 10}}>
  <div className="tituloLista">Proveedores</div>
    <Input
      placeholder="buscar proveedor"
      value={texto}
      onChange={handleChange}
    />
  </div>);
};
