import { URL_BASE_CLIENTES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, postFile } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetClientes = () => {
  return async (dispatch) => {
    const result = await get(URL_BASE_CLIENTES);
    if (result.code === 0) {
      dispatch(storeClientes(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Clientes",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeClientes = (payload) => ({
  type: types.storeClientes,
  payload,
});

/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const startPostCliente = () => {
  return async (dispatch, getState) => {
    const { cliente } = getState().clientesReducer;
    const result = await post(URL_BASE_CLIENTES, cliente);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification("Datos guadados")));
      dispatch(startGetClientes());
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Clientes",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const setValueCliente = (payload) => ({
  type: types.setValueCliente,
  payload,
});

export const cleanClienteForm = () => ({
  type: types.cleanClienteForm,
  payload: null,
});

////////////////////////////////////////////////////////////////

export const startGetCliente = (clienteID) => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_CLIENTES}/${clienteID}`);

    if (result.code === 0) {
      dispatch(storeCliente(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Cliente " + clienteID,
            `${result.error}`
          )
        )
      );
    }

    //
  };
};

const storeCliente = (payload) => ({
  type: types.storeCliente,
  payload,
});

//////////////////////////////


export const setPaginaCliente = (pagina) => ({
  type: types.setPaginaCliente,
  payload: pagina,
});

/////////

export const startPostLogo = ({ clienteID, file }) => {
  return async (dispatch) => {
    dispatch(setShowLogo(false));
    const url = `${URL_BASE_CLIENTES}/PostLogo/${clienteID}`;
    const form = new FormData();
    form.append("file", file);
    const result = await postFile(url, form);

    if (result.code === 0) {
      dispatch(startGetCliente(clienteID));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - post logo",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setShowLogo(true));
  };
};

const setShowLogo = (payload) => ({
  type: types.setShowLogo,
  payload,
});
