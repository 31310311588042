import browserSignature from 'browser-signature';
import { consLogged } from "../../constants/consLogged";
import { URL_BASE_ALLOWEDBROWSER, URL_LOGIN } from "../../constants/url";
import { get, post } from "../../utils/fetch";
import parseJwt from "../../utils/parseJwt";
import { types } from "../types/types";
import { setLoginErr, setRegisterDeviceErr } from "./authErrActions";

export const startLogin = (body) => {
  return async (dispatch) => {
    dispatch(setLoginErr(null));
    const respopnse = await post(URL_LOGIN, { ...body, app: "backoffice" });
    const browser = await dispatch(startVerifyBrowser());

    if (browser) {
      if (respopnse.code !== -1) {
        localStorage.setItem("token", respopnse.data);
        dispatch(storeUserData(parseJwt(respopnse.data)));
        dispatch(setLogged(consLogged.LOGGED));
      } else {
        dispatch(setLoginErr(respopnse.error));
      }
    }

  };
};



export const verificaLogin = () => {
  return async (dispatch) => {
    //console.log("verificaLogin")

    const token = localStorage.getItem("token");
    const browser = await dispatch(startVerifyBrowser());

    if (browser) {
      if (token) {
        const u = parseJwt(token);
        dispatch(storeUserData(parseJwt(token)));
        dispatch(setLogged(consLogged.LOGGED));
      } else {

        dispatch(setLogged(consLogged.NOTLOGGED));
      }
    } else {
      dispatch(setLogged(consLogged.NOTLOGGED));
    }



  };
};

const storeUserData = (payload) => ({
  type: types.storeUserData,
  payload,
});

const setLogged = (payload) => ({
  type: types.setLogged,
  payload,
});

export const startLogoff = () => {
  return (dispatch) => {
    dispatch(setLogged(consLogged.NOTLOGGED));
    localStorage.removeItem("token");
  };
};


//////

export const startAllowBrowser = body => {
  return async (dispatch) => {
    // dispatch(setRegisterDeviceErr(null))

    const url = `${URL_BASE_ALLOWEDBROWSER}/AllowBrowser`
    const response = await post(url, body);


    if (response.code !== -1) {
      localStorage.setItem("signature", response.data);
      console.log(response.data)
      window.location.reload()

    } else {
      if (response.error.status)
        await dispatch(setRegisterDeviceErr("Error in post"));
      else
        await dispatch(setRegisterDeviceErr(response.error));
      return false
    }

  }
}


export const startVerifyBrowser = () => {
  return async (dispatch) => {
    const signature = localStorage.getItem("signature");
    if (!signature) {
      await dispatch(setLoginErr("Device not registered"));
      return false
    }

    const url = `${URL_BASE_ALLOWEDBROWSER}/IsBrowserAllowed/${signature}`
    const respopnse = await get(url);

    if (respopnse.code !== -1) {
      return true
    } else {
      await dispatch(setLoginErr(respopnse.error));
      return false
    }


  }
}