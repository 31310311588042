import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import { startPostProveedorServicios } from "../../../../../../context/actions/proveedoresActions";

export default function Enviar() {
  const dispatch = useDispatch();
  const { proveedorSucursalID, tipoAsistenciaID } = useParams();
  const handleClick = () => {
    console.log("Enviar")
    dispatch(
      startPostProveedorServicios({ proveedorSucursalID, tipoAsistenciaID })
    );
  };
  return (
    <div className="contenedorBotonSubmit" style={{ maxWidth: 250 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Guardar Cambios" />
    </div>
  );
}
