import { Row, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { startDeleCampo } from "../../../context/actions/listasActions";

/////
export const CampoItem = ({ item }) => {
  const dispatch = useDispatch();
  const handleDelete = () => dispatch(startDeleCampo(item));

  ///////
  return (
    <Row gutter={10} style={{ marginBottom: 5 }}>
      <Col span={8} style={{ paddingLeft: 10 }} className="item">
        {item.clave}
      </Col>
      <Col span={2}>
        <DeleteOutlined
          onClick={handleDelete}
          className="icon"
          style={{ color: "#ca2228" }}
        />
      </Col>
    </Row>
  );
};
