import { useHistory } from "react-router";
import { FilePdfOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { message, Space, Tooltip } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useDispatch } from "react-redux";
import { startDownloadDocumentoOP } from "../../../context/actions/ordenesPagoActions";
import axios from "axios";
import { URL_BASE_ORDENESPAGO } from "../../../constants/url";
import { FilterTAble } from "../../../components/datePickerFilter/FilterTAble";
import { DatePickerFilter } from "../../../components/datePickerFilter/DatePickerFilter";

export const useColumns = (shitchState, handleSwitch, arrayProveedores) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = ({ ordenPagoID }) =>
    history.push(`/ordenesPago/detalle/${ordenPagoID}`);

  const handlePDF = ({ ordenPagoID }) =>
    dispatch(startDownloadDocumentoOP(ordenPagoID, 5)); // orden de pago
  const toggleVisible = async ({ ordenPagoID }) => {
    try {

      await axios.post(`${URL_BASE_ORDENESPAGO}/setEstatus/${ordenPagoID}`, {})
      message.success('Se ha cambiado el estatus de la orden de pago')
    } catch (e) {
      message.error('Error al cambiar el estatus de la orden de pago')
    }
  }
  const columns = [
    {
      title: "OP",
      dataIndex: "ordenPagoID",
      key: "ordenPagoID",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor",
      key: "proveedor",
      ...FilterTAble('proveedor', arrayProveedores?.proveedor)
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
      ...FilterTAble('cliente', arrayProveedores?.cliente)
    },
    {
      title: "Creacion",
      dataIndex: "fechaCreacion",
      key: "fechaCreacion",
      ...DatePickerFilter("fechaCreacion"),
    },
    {
      title: "Factura Solicitada",
      dataIndex: "fechaSolicitudFactura",
      key: "fechaSolicitudFactura",
      ...DatePickerFilter("fechaSolicitudFactura"),
    },
    {
      title: "Factura Cargada",
      dataIndex: "fechaCargaFactura",
      key: "fechaCargaFactura",
      ...DatePickerFilter("fechaCargaFactura"),
    },
    {
      title: "Pago Solicitado",

      width: 200,
      dataIndex: "fechaSolicitudPago",
      key: "fechaSolicitudPago",
      ...DatePickerFilter("fechaSolicitudPago"),
    },

    {
      title: "Servicios",
      dataIndex: "numeroServicios",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>{record.numeroServicios}</div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>{formatCurrency(record.total)}</div>
      ),
    },
    {
      title: "",
      width: 175,
      render: (text, record) => (
        <>
          <Space>
            <Tooltip title='Abrir'>
              <EyeOutlined
                onClick={() => handleClick(record)}
                style={{ color: "#ca2228", fontSize: 16 }}
                className='eyeIcon'
              />
            </Tooltip>
            {record.fechaSolicitudFactura !== "-" && (
              <Tooltip title='PDF'>
                <FilePdfOutlined
                  onClick={() => handlePDF(record)}
                  style={{ color: "#ca2228", fontSize: 14 }}
                  className='eyeIcon'
                />
              </Tooltip>
            )}


            <Tooltip title={'Cambiar status visible/no visible'}>
              <EyeInvisibleOutlined
                onClick={() => {
                  toggleVisible(text).then(() => handleSwitch(shitchState))
                }}
                style={{ fontSize: "14px", color: text.estatusOrdenPagoFiltro ? "red" : "green" }}
              />
            </Tooltip>

          </Space>
        </>
      ),
    },
  ];

  return { columns };
};
