import { Col, Row, Space, Tooltip } from "antd";
import { CheckCircleTwoTone, EyeOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Checkbox } from "antd";
import { opTxtAddOrRemove } from "../../../context/actions/ordenesPagoActions";
import ButtonPagar from "../ButtonPagar";

export const OrdenesItem = ({ o }) => {
  const [pagado, setPagado] = useState(o.estaPagado);

  const dispatch = useDispatch();
  const history = useHistory();

  //
  const change = (target) => {
    const body = {
      value: target.name,
      action: target.checked,
    };
    dispatch(opTxtAddOrRemove(body));
  };

  //
  return (
    <Row gutter={10} className="rowItemElemento">
      <Col span={1}>
        <Checkbox
          name={o.ordenPagoID}
          onChange={({ target }) => change(target)}
        />
      </Col>
      <Col span={1}>{o.ordenPagoID}</Col>
      <Col span={2}>{o.fechaCargaFactura}</Col>
      <Col span={2}>{o.dias}</Col>
      <Col span={5}>{o.razonSocial}</Col>
      <Col span={3}>{o.banco}</Col>
      <Col span={2}>{o.cliente}</Col>
      <Col span={2} style={{ textAlign: "center" }}>
        {o.servicios}
      </Col>
      <Col span={2} style={{ textAlign: "center" }}>
        {formatCurrency(o.subTotal)}
      </Col>
      <Col span={2} style={{ textAlign: "center" }}>
        {formatCurrency(o.total)}
      </Col>
      <Col span={2} style={{ textAlign: "center" }}>
        <Space>
          <Tooltip title="Abrir" placement="bottom">
            <EyeOutlined
              onClick={() =>
                history.push(`/ordenesPago/detalle/${o.ordenPagoID}`)
              }
              className="eyeIcon"
              style={{ color: "#ca2228", fontSize: 16 }}
            />
          </Tooltip>
        </Space>

        <Space>
          {!pagado ? (
            <ButtonPagar
              tipo="Icon"
              model={{ ordenPagoID: o.ordenPagoID }}
              setPagado={setPagado}
            />
          ) : (
            <Tooltip title="Pagado" placement="bottom" color="#1890FF">
              <CheckCircleTwoTone
                twoToneColor="#1890FF"
                style={{ fontSize: 15, marginLeft: 15 }}
              />
            </Tooltip>
          )}
        </Space>
      </Col>
    </Row>
  );
};
