import React from 'react'
import { useSelector } from 'react-redux'
import { EstatusItem } from './EstatusItem'
import { estatusProveedorReduce } from './estatusProveedorReduce'

export const Estatus = ({ proveedor }) => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const listado = estatusProveedorReduce(asistenciasMes, proveedor.Id)

    return (
        <div style={{ padding: 5 }}>
            {listado.map((reg, i) => <EstatusItem reg={reg} key={i} />)}
        </div>
    )
}
