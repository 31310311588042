import { Tag } from "antd";

////
export const TagSelector = ({
  tag: { estatusID, estatus, estatusFinal, estatusFinalID },
}) => {
  ///
  let color = "";
  let texto = "";

  ///
  if (estatusFinalID === 4 || estatusFinalID === 5) {
    color = "#b81004";
    texto = estatusFinal;
  } else {
    texto = estatus;
    if (estatusID === 1 || estatusID === 2) color = "#b3b3b3";
    if (estatusID === 3) color = "#bfcaff";
    if (estatusID === 4) color = "#4f63c2";
    if (estatusID === 5) color = "#030e45";
    if (estatusID === 6) color = "#23960b";
  }

  ////
  return (
    <Tag
      color={color}
      style={{ width: "100%", textAlign: "center", borderRadius: 5 }}
    >
      {texto}
    </Tag>
  );
};
