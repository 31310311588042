import { Input } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Proveedor } from './Proveedor';

export const ListadoProveedores = () => {
  const [listaFinal, setListaFinal] = useState([])
  const { proveedores } = useSelector(
    (state) => state.ordenesPagoReducer.asistenciasElegibles2
  );

  useEffect(() => {
    setListaFinal(proveedores)
  }, [proveedores])

  const handleChange = ({ target }) => {
    if (target.value.lenght === 0) setListaFinal(proveedores)

    const texto = target.value.toLowerCase()
    const nuevaSalida = proveedores.filter(a => a.proveedorNombre.toLowerCase().includes(texto))
    setListaFinal(nuevaSalida)
  }
  return (
    <div>
      <div style={{ marginBottom: 20 }}><Input placeholder='buscar proveedor' onChange={handleChange} /></div>
      {listaFinal.map((p, i) => <Proveedor key={i} proveedor={p} />)}
    </div>
  )
}
