import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanProveedorForm,
} from "../../../context/actions/proveedoresActions";
import FormProveedor from "../../../components/formas/FormProveedor";

////////////////
export default function Agregar() {
  const dispatch = useDispatch();
  const { proveedor } = useSelector((state) => state.proveedoresReducer);

  useEffect(() => {
    dispatch(cleanProveedorForm());
  }, [dispatch]);

  ////////////////
  return <FormProveedor datos={proveedor} />;
}
