import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const useProveedoresOptions = () => {
  const [proveedoresOptions, setProveedoresOptions] = React.useState([]);
  const { proveedores } = useSelector((state) => state.proveedoresReducer);

  useEffect(() => {
    var lista = [];
    if (proveedores) {
      proveedores.forEach((item) => {
        lista.push({
          value: item.proveedorID,
          label: item.nombreComercial,
        });
      });
    }
    setProveedoresOptions(lista);
  }, [proveedores]);

  return { proveedoresOptions };
};
