import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { formatCurrency } from '../../../utils/formatCurrency'
import { ClienteDetalle } from './clienteDetalle/ClienteDetalle'


export const ClientesItem = ({ cliente, i }) => {
    const [show, setShow] = useState(false)

    return (<>
        <Row gutter={10} className="rowItemElemento" onClick={() => setShow(!show)}>
            <Col span={1} style={{ textAlign: 'center' }}>{i + 1}</Col>
            <Col span={8}>{cliente.cliente}</Col>
            <Col span={2} style={{ textAlign: 'center' }}>{cliente.cantidad}</Col>
            <Col span={4} style={{ textAlign: 'right' }}>{formatCurrency(cliente.costo)}</Col>
            <Col span={4} style={{ textAlign: 'right' }}>{formatCurrency(cliente.costo / cliente.cantidad)}</Col>
        </Row>

        {show && (<ClienteDetalle cliente={cliente} />)}
    </>)
}
