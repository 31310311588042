import { Result, Spin } from "antd";

export const Cargando = () => {

  return (
    <Result
      status="warning"
      title="Estamos buscando servicios..."
      extra={[<Spin size="large" />]}
    />
  );
};
