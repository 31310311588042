import React, { useEffect } from 'react'
import CardPage from '../../../layout/CardPage'
import { Listado } from './listado/Listado'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import { useDispatch } from 'react-redux'
import { startGetServiciosPorValidar } from '../../../context/actions/opServiciosActions'

export const ServiciosPendientes = () => {
    const dispatch = useDispatch()
    const [show, setShow] = React.useState(true)
    useEffect(() => {
        dispatch(startGetServiciosPorValidar())
    }, [dispatch])

    return (
        <CardPage>
            {show &&
                <Alert
                    onClick={() => setShow(false)}
                    message="En esta sección se muestran los servicios pendientes de validación"
              
                    type="info"
                    showIcon
                    icon={<InfoCircleOutlined />}
                    style={{cursor: 'pointer', marginBottom: 20}}
                />
            }
            <Listado />
        </CardPage>
    )
}
