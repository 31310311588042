import ServicioRow from "./ServicioRow";
import { Row, Col } from "antd";

export default function ListaServicios({ servicios }) {
  return (
    <div style={{ marginTop: 10 }}>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={2}>Activo</Col>
        <Col span={5}>Nombre</Col>
        <Col span={3} style={{ textAlign: "center" }}>
          Cobertura Tope
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          Eventos
        </Col>
       
        <Col span={3} style={{ textAlign: "center" }}>
          Costo
        </Col>
        <Col span={2
        } style={{ textAlign: "center" }}>
          Km
        </Col>
        <Col span={5} style={{ textAlign: "center" }}>
          Condiciones
        </Col>
      </Row>

      {servicios.map((servicio, i) => (
        <ServicioRow key={i} servicio={servicio} />
      ))}
    </div>
  );
}
