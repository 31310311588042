import { Input, Switch, Table } from "antd";
import { useEffect, useState } from "react";
import CardPage from "../../../layout/CardPage";
import { useColumns } from "./useColumns";
import axios from "axios";
import { URL_BASE_ORDENESPAGO } from "../../../constants/url";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { UseNoRepeatArrayOP } from "../../../hooks/UseNoRepeatArrayOP";
import { StatsTable } from '../StatsTable';
import { getStats } from '../../../utils/getStatsOP';

export const Autorizar = () => {

  const [ listaFinal, setListaFinal ] = useState([]);
  const [ switchState, setSwitchState ] = useState(false);
  const filters = UseNoRepeatArrayOP(listaFinal,["cliente", "proveedor"])

  const getAutorizarByStatus = async (obj) => {
    const { data } = await axios.post(`${URL_BASE_ORDENESPAGO}/getOrdenesPorAutorizar`, obj)
    setListaFinal(data)
  }
  useEffect(() => {
    getAutorizarByStatus({ verPendientes: false })
  }, []);

  const handleSwitch = (checked) => {
    setSwitchState(checked)
    if (checked) {
      return getAutorizarByStatus({ verPendientes: true })

    }
    return getAutorizarByStatus({ verPendientes: false })
  }
//

  const { columns } = useColumns(switchState, handleSwitch, filters);
  function debounce(func, timeout = 200) {
    let timer;
    return (...arg) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, arg);
      }, timeout);
    };
  }

  function onChangeInput(event) {
    if (event.target.value === "") return getAutorizarByStatus({ verPendientes: false })
    void getAutorizarByStatus({ ordenPagoId: event.target.value })
  }

  const change = debounce(onChangeInput);

  //
  const {totalCash, totalItems} = getStats(listaFinal)
  return (
    <CardPage>


      <div style={{ display: 'flex', gap: '30px', alignItems: "center", marginBottom: '30px' }}>

        <div style={{ width: "200px" }}>
          <Input onChange={change} placeholder={"Buscar Por Id"} />
        </div>

        <div>
          <p style={{ margin: 0, display: "inline-block", marginRight: "10px" }}>Ver todas</p>
          <Switch
            size='small'
            defaultChecked={switchState}
            onChange={handleSwitch}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </div>
        <div>
        <StatsTable totalCash={totalCash} totalOrdenes={totalItems}/>
        </div>
      </div>


      <Table
        size='small'
        rowClassName='size12'
        rowKey='ordenPagoID'
        dataSource={listaFinal}
        columns={columns}
      />
    </CardPage>
  )
    ;
};
