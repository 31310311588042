import { Select } from "antd";
import { useClientesOptions } from "../../../../hooks/useClientesOptions";

export const Clientes = ({ handleChange, body }) => {
  const { clientesOptions } = useClientesOptions();

  return (
    <Select
      showSearch
      optionFilterProp="label"
      value={body.clienteID}
      placeholder="clientes"
      style={{ width: "100%" }}
      onChange={(val) => handleChange("cienteID", val)}
      options={clientesOptions}
    />
  );
};
