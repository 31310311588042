import { Col, Row, Space } from "antd";
import React, { useState } from "react";
import { Estados } from "./_Estados";
import { Clientes } from "./_Clientes";
import { Proveedores } from "./_Proveedores";
import { Fechas } from "./_Fechas";
import { Submit } from "./_Submit";
import { useDispatch } from "react-redux";
import { startGetReportePorMes } from "../../../../context/actions/reportesActions";
import { useVerTodo } from "./useVerTodo";
import { Hide } from "./_Hide";

// mes = current mes

const mes = new Date().getMonth();
const anio = new Date().getFullYear();

export const Controles = () => {
  const dispatch = useDispatch();
  const [body, setBody] = useState({ mes, anio });

  const { handleVerTodo, canHide } = useVerTodo();

  const handleChange = (ele, val) => {
    setBody({ ...body, [ele]: val });
  };
  const submit = () => {
    dispatch(startGetReportePorMes(body));
  };

  return (
    <Row style={{ marginBottom: 20 }} gutter={[10, 10]}>
      <Col xs={8} lg={6}>
        <Estados handleChange={handleChange} body={body} />
      </Col>
      <Col xs={8} lg={6}>
        <Clientes handleChange={handleChange} body={body} />
      </Col>
      <Col xs={8} lg={6}>
        <Proveedores handleChange={handleChange} body={body} />
      </Col>
      <Col xs={16} lg={12}>
        <Fechas handleChange={handleChange} body={body} />
      </Col>
      <Col xs={8} lg={6}>
        <Space align="center">
          <Submit submit={submit} body={body} />
          {canHide && (
            <Col xs={8} lg={6}>
              <Hide handleVerTodo={handleVerTodo} />
            </Col>
          )}
        </Space>
      </Col>
    </Row>
  );
};
