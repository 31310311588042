import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import { SelectAnios } from "../../../../components/select/SelectAnios";
import { SelectMes } from "../../../../components/select/SelectMes";
import {
  setFiltroOrdenesPago,
  startGetOrdenesPago,
  startGetOrdenesPagoAutorizar,
  startGetOrdenesPagoPagar,
  startGetOrdenesValidarFactura,
} from "../../../../context/actions/ordenesPagoActions";

export const ControlFecha = () => {
  const dispatch = useDispatch();
  const { mes, anio } = useSelector(
    (state) => state.ordenesPagoReducer.filtroOrdenesPago
  );

  const onChangeMes = (val) => {
    dispatch(setFiltroOrdenesPago({ mes: val }));
    dispatch(startGetOrdenesPagoAutorizar());
    dispatch(startGetOrdenesValidarFactura());
    dispatch(startGetOrdenesPagoPagar());
  }

  return (
    <Row gutter={10}>
      <Col span={8}>
        <SelectMes
          value={mes}
          onChange={onChangeMes}
        />
      </Col>
      <Col span={8}>
        <SelectAnios
          value={anio}
          onChange={(val) => dispatch(setFiltroOrdenesPago({ anio: val }))}
        />
      </Col>
      <Col span={8}>
        <BotonDivL
          onClick={() => dispatch(startGetOrdenesPago())}
          tituloBoton="Filtrar"
        />
      </Col>
    </Row>
  );
};
