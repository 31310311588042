import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonDivL from "../../../components/Boton/BotonDivL";
import {
  filterOrdenPagoPagar,
  setOrdenPagoPagarFiltered,
  startGetOrdenPagoXLSByEstatus
} from "../../../context/actions/ordenesPagoActions";
import { formatCurrency } from "../../../utils/formatCurrency";
import { Button, Select, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ModalCargaMasiva } from "../../../components/modaPagos/ModalCargaMasiva";

export const Controles = () => {
  const [ open, setOpen ] = useState(false)
  const dispatch = useDispatch();


  const { ordenesPagoPagar } = useSelector((state) => state.ordenesPagoReducer);

  // Obtener clientes unicos
  const clientesRepetidos = ordenesPagoPagar.ordenes?.map((o) => o.cliente);
  const clientes = [...new Set(clientesRepetidos)];
  const options = clientes.map((c) => ({ label: c, value: c }));

  const handleSelect = (value) => {
    dispatch(filterOrdenPagoPagar(value))
  }


  const onClear = () => {
    dispatch(setOrdenPagoPagarFiltered(null))
  }
  const handleClick = () => {
    dispatch(startGetOrdenPagoXLSByEstatus(5));
  };

  return (
    <>
      <ModalCargaMasiva open={open} setOpen={setOpen} />
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between", width: 350 }}
      >
        <p style={{ color: "#1890FF" }}>
          Total Ordenes:{" "}
          <span style={{ color: "black" }}>
            {ordenesPagoPagar.countOrdenes}
          </span>
        </p>

        <p style={{ color: "#1890FF" }}>
          Total Por Pagar:{" "}
          <span style={{ color: "black" }}>
            {formatCurrency(ordenesPagoPagar.totalPorPagar)}
          </span>
        </p>
      </div>

      <Select
        onClear={onClear} allowClear showSearch options={options} placeholder={'Seleccione un cliente'}
        onSelect={handleSelect}
      />
      <Space direction='horizontal'>

        <BotonDivL onClick={handleClick} tituloBoton='Descargar XLS' />
        <Button type='text'  onClick={() => setOpen(true)} danger icon={<UploadOutlined />}>
         Aplicar pagos
        </Button>
      </Space>
    </div>

    </>
  );
};
