import { Cuadro } from "../utils/Cuadro";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatNumber } from "../../../../utils/formatNumber";
import { usePagoEfectuado } from "./usePagoEfectuado";
import { useSelector } from "react-redux";
import { Spinner } from "../utils/Spinner";

export const PagoEfectuado = () => {
  const { asistencias, costo } = usePagoEfectuado()
  const { loadingReporte } = useSelector(state => state.reportesReducer)
  if (loadingReporte)
    return <Spinner />

  return (
    <Cuadro
      backgroundColor={"#595313"}
      titulo={<><div>Con pago efectuado</div></>}
      cantidad={formatNumber(asistencias)}
      monto={formatCurrency(costo)}
    />
  );
};
