import React from 'react'
import { Info } from './_Info'
import { Controles } from './_Controles'
import { Historial } from './_Historial'

import Loading from '../../../../../components/loading/Loading'
import { useSelector } from 'react-redux'

export const ContenidoDawer = ({ setOpen, servicioPorValidarID }) => {
    const { loading } = useSelector(state => state.opServiciosReducer)


    //
    if (loading)
        return <Loading texto="Cargando Datos" />

    return (
        <div>
            <Info />
            <Controles setOpen={setOpen} servicioPorValidarID={servicioPorValidarID} />
            <Historial />
        </div>
    )
}
