import React from 'react'
import img from '../../assets/emptyData.png'

export const SinRegistros = () => {
  return (
    <div className='SinRegistros'>
      <img src={img} className='SinRegistrosImg' alt='Sin registros' />
     <div>Sin registros</div> </div>
  )
}
