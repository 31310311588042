import { Cuadro } from "../utils/Cuadro";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatNumber } from "../../../../utils/formatNumber";
import { useSelector } from "react-redux";
import { Spinner } from "../utils/Spinner";
import { usePagoSolicitado } from "./usePagoSolicitado";

export const PagoSolicitado = () => {
  const { asistencias, costo } = usePagoSolicitado()

  const { loadingReporte } = useSelector(state => state.reportesReducer)
  if (loadingReporte)
    return <Spinner />

  return (
    <Cuadro
      backgroundColor={"#595313"}
      titulo={<><div>Con pago solicitado</div></>}
      cantidad={formatNumber(asistencias)}
      monto={formatCurrency(costo)}
    />
  );
};
