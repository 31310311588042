import { Col, Row, Tooltip } from "antd";
import { ToolOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addGeo } from "../addGeo";
import { removeGeo } from "../removeGeo";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

export const SucursalItem = ({ sucursal, map }) => {
  
  const { proveedorID } = useParams();
  const history = useHistory();
  const [sLp1, setLp1] = useState(false);
  const [sLp2, setLp2] = useState(false);

  const laSucursal = useSelector(
    (state) => state.proveedoresReducer.proveedor.sucursalSelected
  );

  const handleLp = (tipo) => {
    if (tipo === "lp1") {
      const newStatus = !sLp1;
      setLp1(newStatus);
      if (newStatus) add("lp1", sucursal.geo1);
      else remove("lp1");
    } else {
      const newStatus = !sLp2;
      setLp2(newStatus);
      if (newStatus) add("lp2", sucursal.geo2);
      else remove("lp2");
    }
  };

  const add = (tipo, geo) => {
    const config = {
      map,
      sourceName: `source_${tipo}_${sucursal.proveedorSucursalID}`,
      fillName: `fillName_${tipo}_${sucursal.proveedorSucursalID}`,
      geo,
      fillColor: tipo === "lp1" ? "#676cf5" : "#ba1155",
    };

    addGeo(config);
  };

  const remove = (tipo) => {
    const config = {
      map,
      sourceName: `source_${tipo}_${sucursal.proveedorSucursalID}`,
      fillName: `fillName_${tipo}_${sucursal.proveedorSucursalID}`,
    };
    removeGeo(config);
  };

  const createPoligon = () =>
    history.push(
      `/proveedores/createPolygon/${proveedorID}/${sucursal.proveedorSucursalID}`
    );

  ////
  return (
    <div
      className={`sucursalItem ${
        laSucursal?.sucursalSelectedID === sucursal.proveedorSucursalID
          ? "sucursalItemSelected"
          : ""
      }`}
    >
      {sucursal.nombre}
      <Row gutter={5}>
        <Col
          span={8}
          onClick={() => handleLp("lp1")}
          className={`botnGeo ${sLp1 && "sucursalItemSelected"}`}
        >
          <Tooltip title={sLp1 ? "Ocultar" : "Mostrar"}>Lp1</Tooltip>
        </Col>
        <Col
          onClick={() => handleLp("lp2")}
          span={8}
          className={`botnGeo ${sLp2 && "sucursalItemSelected"}`}
        >
          <Tooltip title={sLp2 ? "Ocultar" : "Mostrar"}>Lp2</Tooltip>
        </Col>
        <Col span={8} onClick={createPoligon} className="botnGeo">
          <Tooltip title="Cambiar datos">
            <ToolOutlined />
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};
