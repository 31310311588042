import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import LabelText from "../../../../../../components/input/LabelText";
import { startOrdenToEmail } from "../../../../../../context/actions/ordenesPagoActions";
import ModelPage from "../../../../../../layout/ModelPage";

export const SolicitudFacturaModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { ordenPagoID } = useParams();
  const { proveedorEmail } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );
  const [emails, setEmails] = useState();

  const handleOK = () => {
    const body = { emails, ordenPagoID, NewEstatusOrdenPagoID: 2 }; // 2 = solicitud factura estatus
    dispatch(startOrdenToEmail(body));
    setShowModal(false);
  };

  useEffect(() => {
    setEmails(proveedorEmail);
  }, [proveedorEmail]);

  ///////////////////////
  return (
    <ModelPage
      titulo="Solicitar Factura a Proveedor"
      isVisible={showModal}
      handleOk={handleOK}
      handleCancel={() => setShowModal(false)}
    >
      <div className="tituloPequeño">
        Agregue un correo electrónico para enviar la orden de pago <br />
        Puede agregar más de un email separado por coma
      </div>
      <LabelText
        label="Email(s)"
        name="emails"
        onChange={({ target }) => setEmails(target.value)}
        value={emails}
      />

    </ModelPage>
  );
};
