import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  pickTipoAsistencia,
  startLoadCampaniaServicios,
} from "../../../../context/actions/campaniasActions";

export default function Tipo({ tipo }) {
  const dispatch = useDispatch();
  const { campaniaID } = useParams();
  const { tipoAsistenciaSelected } = useSelector(
    (state) => state.campaniasReducer
  );
  const handleClick = async () => {
    const body = {
      campaniaID: Number(campaniaID),
      tipoAsistenciaID: tipo.tipoAsistenciaID,
    };

    await dispatch(pickTipoAsistencia(tipo));
    dispatch(startLoadCampaniaServicios(body));
  };

  return (
    <div
      onClick={handleClick}
      className={`listaSelect ${
        tipoAsistenciaSelected.tipoAsistenciaID === tipo.tipoAsistenciaID &&
        "seleccionado"
      }`}
    >
      {tipo.nombre}
    </div>
  );
}
