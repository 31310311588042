import { Col, Row } from 'antd'
import React from 'react'
import LabelValor from '../../../../../components/label/LabelValor'
import { Row10 } from '../../../../../components/row10/Row10'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../../../utils/formatCurrency'
import { dateFromString } from '../../../../../utils/dateFromString'

export const Info = () => {

  const { servicioPorValidar: s } = useSelector(state => state.opServiciosReducer)

  //
  return (
    <div>
      <Row10>
        <Col span={8}>
          <LabelValor label="Asistencia" valor={s.asistenciaID} />
        </Col>
        <Col span={8}>
          <LabelValor label="Estatus Final" valor={s.estatusFinal}/>
        </Col>
        <Col span={8}>
          <LabelValor label="Fecha Servicio" valor={dateFromString(s.fechaLlamadaIniciada).soloFecha}/>
        </Col>
      </Row10>
      <Row10>


      </Row10>
      <Row10>
        <Col span={8}>
          <LabelValor label="Cliente" valor={s.cliente}/>
        </Col>
        <Col span={8}>
          <LabelValor label="Campaña" valor={s.campania} />
        </Col>
        <Col span={8}>
          <LabelValor label="Fecha Conclusión" valor={dateFromString(s.fechaConcluido).soloFecha} />
        </Col>

      </Row10>
      <Row10>
        <Col span={8}>
          <LabelValor label="Proveedor" valor={s.proveedor} />
        </Col>

        <Col span={8}>
          <LabelValor label="Area" valor={s.localCarretero} />
        </Col>
        <Col span={8}>
          <LabelValor label="Servicio" valor={s.servicio} />
        </Col>

        <Col span={12}>
          <LabelValor label="Origen" valor={`${s.municipioOrigen} en ${s.estadoOrigen}`}  />
        </Col>
        <Col span={12}>
          <LabelValor label="Destino" valor={`${s.municipioDestino} en ${s.estadoDestino}`} />
        </Col>
      </Row10>
      <Row10>
        <Col span={8}>
          <LabelValor label="Km" valor={s.km} />
        </Col>

        <Col span={8}>
          <LabelValor label="Costo" valor={formatCurrency(s.costo)} />
        </Col>

      </Row10>
    </div>
  )
}
