import { useSelector } from "react-redux";
import { RowItem } from "./RowItem";

export const AsistenciasOrdenPagoRows = () => {
  const { ordenPagoItems, ordenPago: {estatusOrdenPagoID} } = useSelector(
    (state) => state.ordenesPagoReducer
  );

  if (!ordenPagoItems) return <></>;

  return (
    <div>
      {ordenPagoItems.map((o, i) => (
        <RowItem rowItem={o} key={i} estatusOrdenPagoID={estatusOrdenPagoID} />
      ))}
    </div>
  );
};
