import { typesOpServicios } from "../types/typesOpServicios";

const init = {
  loading:false,
  serviciosPorValidar: [],
  servicioPorValidar:{}
 
};
export const opServiciosReducer = (state = init, action) => {
  switch (action.type) {

    case typesOpServicios.storeServiciosPorValidar:
      return {
        ...state,
        serviciosPorValidar: action.payload
      }

      case typesOpServicios.storeServicioPorValidar:
        return {
          ...state,
          servicioPorValidar: action.payload
        }

        case typesOpServicios.setLoading:
          return {
            ...state,
            loading: action.payload
          }
    
  

    default:
      return state;
  }
};
