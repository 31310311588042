import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import {
  entidadesFederativas,
  forFisica,
  forMoral,
  regimeArray,
} from "../../utils/provedorUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  startPostProveedor,
  startUpdateProveedor,
} from "../../context/actions/proveedoresActions";
import React, { useEffect } from "react";

const FormProveedor = ({ datos }) => {
  const tiposPersona = [
    { value: 1, label: "Física" },
    { value: 2, label: "Moral" },
  ];

  const dispatch = useDispatch();

  const { usuarioID } = useSelector((state) => state.userReducer.user);

  const onFinishFailed = (errorInfo) => {
    console.log("Falló el envío del formulario:", errorInfo);
  };

  const [form] = Form.useForm();
  const selectedPerson = Form.useWatch("tipoPersona", form);
  useEffect(() => {
    if (datos?.rfc === undefined) {
      form.resetFields();
    }
  }, [datos, form]);

  const onFinish = (values) => {
    console.log(values);

    //agregar el tipo de persona
    values.iva = Number(values.iva);
    values.ivaRetenido = Number(values.ivaRetenido);
    values.isrRetenido = Number(values.isrRetenido);
    values.usuarioIdCreacion = usuarioID;
    if (datos.proveedorID) {
      values.proveedorID = datos.proveedorID;
      values.tipoPersona = datos.tipoPersona;
      dispatch(startUpdateProveedor(values));
      return;
    }
    dispatch(startPostProveedor(values));
    form.resetFields();
  };
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className={"form-proveedor"}
        layout={"vertical"}
        initialValues={{ ...datos }}
      >
        <Form.Item
          label={"Tipo de persona"}
          name={"tipoPersona"}
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Select
            options={tiposPersona}
            disabled={datos?.tipoPersona}
            placeholder="Tipo de persona"
            style={selectedPerson ? { width: "100%" } : { width: "33%" }}
          />
        </Form.Item>
        {selectedPerson && (
          <>
            {/*Si es persona física se agregan estos campos*/}
            {selectedPerson === 1 &&
              forFisica.map((item) => {
                const props = item.props;
                return (
                  <Form.Item
                    label={item.label}
                    name={item.value}
                    rules={item.rules && item.rules}
                    key={item.label}
                  >
                    <Input {...props} />
                  </Form.Item>
                );
              })}

            {forMoral.map((item) => {
              const props = item.props;
              if (item.value === "entidadFederativa") {
                return (
                  <Form.Item
                    name={item.value}
                    label={item.label}
                    key={item.label}
                  >
                    <Select
                      placeholder={"Entidad Federativa"}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      options={entidadesFederativas}
                    />
                  </Form.Item>
                );
              }
              if (item.name === "comentarios") {
                return (
                  <Form.Item
                    label={item.label}
                    name={item.name}
                    key={item.label}
                  >
                    <Input.TextArea {...props} />
                  </Form.Item>
                );
              }
              return (
                <Form.Item
                  label={item.label}
                  name={item.value}
                  key={item.label}
                  rules={item.rules && item.rules}
                >
                  <Input {...props} type={item?.type ? item.type : "text"} />
                </Form.Item>
              );
            })}
            <Form.Item label={"Régimen Fiscal"} name={"regimenFiscalId"}>
              <Select
                placeholder={"Regimen"}
                defaultValue={datos?.regimen}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                // options={regimeArray}
              >
                {regimeArray.map((item) => {
                  return (
                    <Select.Option
                      value={item.value}
                      key={item.value}
                      label={item.label}
                    >
                      {`(${item.value}) ${item.label}`}{" "}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Row gutter={10}>
              <Col span={6}>
                <Form.Item
                  name={"esProveedorInterno"}
                  label={"Proveedor interno"}
                >
                  <Select
                    placeholder="Proveedor interno"
                    defaultValue={datos?.esProveedorInterno}
                  >
                    <Select.Option value={true}>Si</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={"esProveedorCritico"}
                  label={"Proveedor Crítico"}
                >
                  <Select
                    placeholder="Proveedor crítico"
                    defaultValue={datos?.esProveedorCritico}
                  >
                    <Select.Option value={true}>Si</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {datos.rfc ? "Actualizar" : "Guardar"}
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
};

export default FormProveedor;
