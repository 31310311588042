import React from "react";
import { ProveedoresItem } from "./_ProveedoresItem";
import { useSelector } from "react-redux";

export const Proveedores = () => {
  const [open, setOpen] = React.useState(true);
  const {
    asistenciasDia: { proveedores },
  } = useSelector((state) => state.reportesReducer);

  //
  return (
    <div>
      <div
        onClick={() => setOpen(!open)}
        className="tituloCabeceraRound"
        style={{ fontSize: 16, fontWeight: 600, cursor: "pointer" }}
      >
        Proveedores
      </div>
      <div style={{ display: open ? "" : "none" }}>
        {proveedores && (
          <div style={{ paddingRight: 4, height: 240, overflowY: "auto", overflowX: "hidden" }}>
            {proveedores.map((proveedor, i) => (
              <ProveedoresItem key={i} proveedor={proveedor} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
