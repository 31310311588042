import { Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Loading from "../../layout/Loading";
import { AsistenciaItem } from "./AsistenciaItem";

export const AsistenciasListado = () => {
  const { asistenciasAsignables, loadingAsistencias } = useSelector(
    (state) => state.asignacionReducer
  );

  if (loadingAsistencias) return <Loading />;
  return (
    <>
      <div
        style={{ marginBottom: 10, textAlign: "right" }}
        className="subtituloMenos"
      >
        Registros: {asistenciasAsignables.length}
      </div>
      <Row gutter={[10, 10]}>
        {asistenciasAsignables.map((a, i) => (
          <AsistenciaItem a={a} key={i} />
        ))}
      </Row>
    </>
  );
};
