import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import BotonEliminar from "../../../../components/Boton/BotonEliminar";
import { startDeleCampo } from "../../../../context/actions/tiposAsistenciaActions";

///
export default function Campo({ campo }) {
  const { id, servicioID, label, esRequerido, tipo, lista } = campo;
  const dispatch = useDispatch();
  const handleClick = () => dispatch(startDeleCampo(id, servicioID));
  ///
  return (
    <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
      <Col span={5} className="renglon">
        {label}
      </Col>
      <Col span={5} className="renglon">
        {esRequerido ? "Si" : "No"}
      </Col>
      <Col span={5} className="renglon">
        {tipo}
      </Col>
      <Col span={5} className="renglon">
        {lista}
      </Col>
      <Col span={2}>
        <BotonEliminar onClick={handleClick} />
      </Col>
    </Row>
  );
}
