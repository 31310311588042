import { Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import LabelSelect from "../../../../components/select/LabelSelect";
import { serviciosTextoValor } from "../../../../utils/serviciosTextoValor";
import { tiposTextoValor } from "../tiposTextoValor";

export const Forma = ({ onChange }) => {
  const [opcionesServicios, setOpcionesServicios] = useState([]);
  const { tipoAsistencia, servicio } = useSelector(
    (state) => state.asistenciasReducer.servicioAsistencia
  );
  const { tiposAsistencia } = useSelector(
    (state) => state.tiposAsistenciaReducer
  );

  const opciones = tiposTextoValor(tiposAsistencia || []);

  const changeTipoAsistencia = (tipoAsistenciaID) => {
    const nuevos = tiposAsistencia.filter(
      (s) => s.tipoAsistenciaID === tipoAsistenciaID
    );
    const salida = serviciosTextoValor(nuevos[0].servicios);
    setOpcionesServicios(salida);
  };

  //////////
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        Servicio actual: {tipoAsistencia} / {servicio}
      </div>
      <Row gutter={10}>
        <Col span={12}>
          <LabelSelect
            label="Tipo de asistencia"
            onChange={changeTipoAsistencia}
            opciones={opciones}
            name="tipoAsistencia"
          />
        </Col>
        <Col span={12}>
          <LabelSelect
            disabled={opcionesServicios.length === 0}
            label="Servicio"
            onChange={onChange}
            opciones={opcionesServicios}
            name="servicioID"
          />
        </Col>
      </Row>
    </>
  );
};
