import { notificationInitial } from "../initial/notificationInitial";
import { types } from "../types/types";

const init = {
  cliente: {},
  clientes: [],
  pagina: "campanias",
  showLogo: true,
};

//
export const clientesReducer = (state = init, action) => {
  switch (action.type) {
    case types.setShowLogo:
      return {
        ...state,
        showLogo: action.payload,
      };
    case types.storeClientes:
      return {
        ...state,
        clientes: action.payload,
      };

    case types.setValueCliente:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          [action.payload.name]: action.payload.value,
        },
      };

    case types.cleanClienteForm:
      return {
        ...state,
        cliente: {},
      };

    case types.storeCliente:
      return {
        ...state,
        cliente: action.payload,
      };

    case types.setPaginaCliente:
      return {
        ...state,
        pagina: action.payload,
      };

    default:
      return state;
  }
};
