import { Col } from "antd";
import React from "react";

export const LatLng = ({ latitud, longitud, zoom }) => {
  if (latitud === undefined || latitud === null) return <div>lat err</div>;
  if (longitud === undefined || longitud === null) return <div>lng err</div>;
  return (
    <Col xs={24} md={7} xl={8} className="infoMapa line-30">
      {latitud.toFixed(4)},{longitud.toFixed(4)} ({zoom})
    </Col>
  );
};
