import { Col, Input } from "antd";
import React from "react";

export const InputTextSerach = ({ handleSearch, textoBusca }) => {
  return (
    <Col xs={24} md={7} xl={4}>
      <Input
        value={textoBusca}
        placeholder="buscar..."
        onChange={handleSearch}
        style={{ borderRadius: 3 }}
      />
    </Col>
  );
};
