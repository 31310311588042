import React from "react";
import { useSelector } from "react-redux";
import LabelValor from "../../../../../../components/label/LabelValor";

export const FechaCreacion = () => {
  const { fechaCreacion, usuarioCreacion } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );

  return (
    <LabelValor
      label="Fecha de creación"
      valor={`${fechaCreacion} - ${usuarioCreacion}`}
    />
  );
};
