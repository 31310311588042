import { URL_BASE_TIPOSASISTENCIA } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, put } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";
import {
  startGetTiposAsistencia,
  startSelectTipoAsistencia,
} from "./tiposAsistenciaActions";

export const changeServicio = (target) => ({
  type: types.changeServicio,
  payload: target,
});

export const setServicioSelected = (payload) => ({
  type: types.setServicioSelected,
  payload,
});

export const startPutServicio = (tipoAsistenciaID) => {
  return async (dispatch, getState) => {
    const { servicioSelected } = getState().tiposAsistenciaReducer;
    const url = `${URL_BASE_TIPOSASISTENCIA}/PutServicio/${servicioSelected.id}`;
    const body = { ...servicioSelected, tipoAsistenciaID };

    console.log(body);
    const result = await put(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      await dispatch(startGetTiposAsistencia());
      await dispatch(startGetTiposAsistencia());
      dispatch(startSelectTipoAsistencia(tipoAsistenciaID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - put servicio",
            `${result.error}`
          )
        )
      );
    }
  };
};

////

export const cleanServicioSelected = () => ({
  type: types.cleanServicioSelected,
  payload: null,
});
