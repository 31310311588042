import React from 'react'
import { useSelector } from 'react-redux'

export const Historial = () => {
    const { servicioPorValidar: s } = useSelector(state => state.opServiciosReducer)


    return (
        <div style={{ maxHeight: 400, overflowY: 'auto' }}>
            <div className='historial'>Historial</div>
            {s.historial && s.historial.map((h, i) => (<div key={i}>
                <div className='historialHora'>{h.fecha} - {h.usuarioNombre}</div>
                <div className='historialTexto'>{h.comentarios} </div>
            </div>))}
        </div>
    )
}
