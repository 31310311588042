import { Button, Col, Row, Upload } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import BotonDiv from "../../../../../components/Boton/BotonDiv";
import LabelText from "../../../../../components/input/LabelText";
import { URL_BASE_PROVEEDORES } from "../../../../../constants/url";
import { postFile } from "../../../../../utils/fetch";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../../../../utils/composeNotification";
import { notification } from "../../../../../context/actions/notificationActions";
import { startLoadDocumentosProveedor } from "../../../../../context/actions/proveedoresFilesActions";

export default function Create() {
  const dispatch = useDispatch();
  const [body, setBody] = useState({});
  const { proveedorID } = useParams();

  //
  const handleChange = ({ target }) =>
    setBody({ ...body, [target.name]: target.value });

    const camposEror = () => {
      let salida = false;
      if (
        estaVacioNull(body.nombre, "nombre") ||
        estaVacioNull(body.file, "file")
      )
        salida = true;

      console.log({ salida });
      return salida;
    };

  const estaVacioNull = (valor, campo) => {
    console.log(campo, valor);
    if (valor === "" || valor === null || valor === undefined) {
      return true;
    } else return false;
  };

  
  const handleClick = async () => {
    if (camposEror()) {
      dispatch(
        notification(composeErrorNotification("Error en forma - Faltan campos"))
      );
      return;
    }

    let formdata = new FormData();
    formdata.append("file", body.file);
    formdata.append("filename", "file");
    formdata.append("mimetype", "application/pdf");

    formdata.append("Nombre", body.nombre);
    formdata.append("ProveedorID", proveedorID);

    const url = `${URL_BASE_PROVEEDORES}/PostDocumento/`;
    const result = await postFile(url, formdata);
    console.log(result);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startLoadDocumentosProveedor(proveedorID));
      setBody({});
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post documento p",
            `${result.error}`
          )
        )
      );
    }
  };

  const props = {
    beforeUpload: (file) => {
      setBody({ ...body, file });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  return (
    <>
      <Row style={{ marginTop: 20 }} gutter={10}>
        <Col span={4}>
          <LabelText
            name="nombre"
            value={body.nombre}
            onChange={handleChange}
            label="Nombre"
          />
        </Col>

        <Col span={8}>
          <Upload {...props} style={{ width: "100%" }}>
            <Button
              style={{ textAlign: "left", marginTop: 20 }}
              icon={<UploadOutlined />}
            >
              Seleccione documento
            </Button>
          </Upload>
        </Col>
        <Col span={2} style={{ marginTop: 20 }}>
          <BotonDiv tituloBoton="Agregar" onClick={handleClick} />
        </Col>
      </Row>
      <div style={{ minHeight: 5, marginTop: 10 }}>
        {" "}
        {body.file && body.file.name}
      </div>
    </>
  );
}
