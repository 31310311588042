import { Input, Select } from "antd";
const { Option } = Select;

//////
export const LabelTelefono = ({
  label,
  onChange,
  onChangeTipo,
  name,
  value,
  valueTipo,
}) => {
  const handleChange = (e) => {
    const body = {
      target: {
        name,
        value: e.target.value,
      },
    };

    onChange(body);
  };

  const selectBefore = (
    <Select
      value={valueTipo}
      className="select-before"
      onChange={onChangeTipo}
      style={{ width: 80 }}
    >
      <Option value="Fijo">Fijo</Option>
      <Option value="Movil">Movil</Option>
    </Select>
  );

  ////
  return (
    <>
      <div className="labelImput">{label}</div>
      <Input
        name={name}
        value={value}
        onChange={handleChange}
        addonBefore={selectBefore}
      />
    </>
  );
};
