import { types } from "../types/types";
import { URL_BASE_ASIGNACION } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, postFile, postGetFile } from "../../utils/fetch";
import { notification } from "./notificationActions";

export const startGetAsistenciasAsignables = (notLoading) => {
  return async (dispatch) => {
    if (!notLoading) dispatch(setLoadingAsistencias(true));
    const url = `${URL_BASE_ASIGNACION}/GetAsistenciasAsignables`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeAsistenciasAsignables(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetAsistenciasAsignables",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setLoadingAsistencias(false));
  };
};

const storeAsistenciasAsignables = (asistencias) => ({
  type: types.storeAsistenciasAsignables,
  payload: asistencias,
});

/////////////
export const startGetProveedoresServicio = (servicioID) => {
  return async (dispatch) => {
    dispatch(setLoadingProveedores(true));

    const url = `${URL_BASE_ASIGNACION}/GetProveedoresServicio/${servicioID}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeProveedoresServicio(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetAsistenciasAsignables",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setLoadingProveedores(false));
  };
};

const storeProveedoresServicio = (data) => ({
  type: types.storeProveedoresServicio,
  payload: data,
});

/// cargas
export const setLoadingAsistencias = (state) => ({
  type: types.setLoadingAsistencias,
  payload: state,
});

export const setLoadingProveedores = (state) => ({
  type: types.setLoadingProveedores,
  payload: state,
});

//////////

export const startSugiereProveedor = (proveedorID, asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const body = { proveedorID, asistenciaID, usuarioID };

    const url = `${URL_BASE_ASIGNACION}/SugiereProveedor`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(startGetAsistenciasAsignables(true));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("SugiereProveedor", `${result.error}`)
        )
      );
    }
  };
};
