import axios from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import {URL_BASE_CALIDAD} from "../../../constants/url";
import CardPage from "../../../layout/CardPage";
import FormularioCalificacionTipificacion from "./FormularioCalificacionServicio";
import DatosAsistencia from "./datosServicio/DatosAsistencia";
import DatosHistorial from "./datosServicio/DatosHistorial";
import DatosLlamada from "./datosServicio/DatosLlamadas";
import DatosArchivos from "./datosServicio/datosArchivos/DatosArchivos";
import FormAtencion from "../../../components/formas/FormAtencion";
import {Alert} from "antd";
import {BorderOuterOutlined} from "@ant-design/icons";
import AudioPlayer from "../queja/audioPlayer/AudioPlayer";
import QuejaUsuario from "../queja/QuejaUsuario";

export default function CalificarServicio() {
  const [data, setData] = useState();
  const {asistencia} = useParams();
  const [open, setOpen] = useState(false);
  const [openCalidad, setOpenCalidad] = useState(false)

  const getData = async () => {
    const data = await axios.get(
      `${URL_BASE_CALIDAD}/GetDatosServicio/${asistencia}`
    );

    setData(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data) return <Loading></Loading>;

  console.log(data)

  return (
    <>
      {
        (data.calidadServicio?.audio || data.calidadServicio?.texto) &&

        <Alert message="Queja del Usuario"
               description={<QuejaUsuario audio={data.calidadServicio?.audio}
                                          texto={data.calidadServicio?.texto}
                                          tipoContacto={data.calidadServicio?.tipoContacto}
                                          fecha={data.calidadServicio?.fechaQueja}/>}
               type="error" showIcon/>
      }

      <CardPage titulo="Datos de asistencia" accionBoton={() => setOpen(true)} accionBoton2={() => {
        setOpenCalidad(true)
      }} tituloBoton2={'Calificar Atención'} tituloBoton={'Calificar Tipificación'}>
        <DatosAsistencia data={data}/>
      </CardPage>

      <div style={{display: "flex", gap: 15}}>
        <div style={{width: '50%'}}>
          <CardPage style={{overflowY: 'scroll', minHeight: '300px', maxHeight: '300px'}} titulo="Llamadas">
            <DatosLlamada llamadas={data.llamadas}/>
          </CardPage>
        </div>

        <div style={{width: '50%'}}>
          <CardPage style={{overflowY: 'scroll', minHeight: '300px', maxHeight: '300px'}} titulo="Archivos">
            <DatosArchivos archivos={data.archivos}/>
          </CardPage>
        </div>
      </div>

      <CardPage style={{overflowY: 'scroll', minHeight: '300px', maxHeight: '300px'}} titulo="Historial">
        <DatosHistorial historial={data.historial}/>
      </CardPage>

      <FormularioCalificacionTipificacion id={data.calidadServicio.calidadServicioId}
                                          data={data?.calidadServicio?.encuestaTipificacion} setOpen={setOpen}
                                          open={open}/>

      <FormAtencion openCalidad={openCalidad} id={data.calidadServicio.calidadServicioId}
                    data={data?.calidadServicio?.encuestaAtencion} setOpenCalidad={setOpenCalidad}/>
    </>
  );
}
