import { useEffect, useState } from "react";
import { URL_BASE_ORDENESPAGO } from "../../../constants/url";
import { notification } from "../../../context/actions/notificationActions";
import { composeErrorNotification } from "../../../utils/composeNotification";
import { get } from "../../../utils/fetch";
import { formatCurrency } from "../../../utils/formatCurrency";
import { Table } from "antd";

const renderMes = (datosMes) =>
  datosMes ? (
    <>
      <p>{datosMes.count}</p>
      <p>{formatCurrency(datosMes.subtotal)}</p>
    </>
  ) : (
    0
  );

const colums = [
  {
    title: "",
    dataIndex: "leftMes",
    key: "leftMes",
    align: "center",
  },
  {
    title: "Total Pagado",
    dataIndex: "totalPagado",
    key: "totalPagado",
    render: (total) => formatCurrency(total),
    align: "center",
  },
  {
    title: "Subtotal",
    dataIndex: "subtotal",
    key: "subtotal",
    render: (subtotal) => formatCurrency(subtotal),
    align: "center",
  },
  {
    title: "Ordenes Pagadas",
    dataIndex: "ordenesPagadas",
    key: "ordenesPagadas",
    align: "center",
  },
  {
    title: "Asistencias Pagadas",
    dataIndex: "asistenciasPagadas",
    key: "asistenciasPagadas",
    align: "center",
  },
  {
    title: "Enero",
    dataIndex: "enero",
    key: "enero",
    render: renderMes,
    align: "center",
  },
  {
    title: "Febrero",
    dataIndex: "febrero",
    key: "febrero",
    render: renderMes,
    align: "center",
  },
  {
    title: "Marzo",
    dataIndex: "marzo",
    key: "marzo",
    render: renderMes,
    align: "center",
  },
  {
    title: "Abril",
    dataIndex: "abril",
    key: "abril",
    render: renderMes,
    align: "center",
  },
  {
    title: "Mayo",
    dataIndex: "mayo",
    key: "mayo",
    render: renderMes,
    align: "center",
  },
  {
    title: "Junio",
    dataIndex: "junio",
    key: "junio",
    render: renderMes,
    align: "center",
  },
  {
    title: "Julio",
    dataIndex: "julio",
    key: "julio",
    render: renderMes,
    align: "center",
  },
  {
    title: "Agosto",
    dataIndex: "agosto",
    key: "agosto",
    render: renderMes,
    align: "center",
  },
  {
    title: "Septiembre",
    dataIndex: "septiembre",
    key: "septiembre",
    render: renderMes,
    align: "center",
  },
  {
    title: "Octubre",
    dataIndex: "octubre",
    key: "octubre",
    render: renderMes,
    align: "center",
  },
  {
    title: "Noviembre",
    dataIndex: "noviembre",
    key: "noviembre",
    render: renderMes,
    align: "center",
  },
  {
    title: "Diciembre",
    dataIndex: "diciembre",
    key: "diciembre",
    render: renderMes,
    align: "center",
  },
];

const getNameMes = (month) => {
  switch (month) {
    case 1:
      return "Enero";

    case 2:
      return "Febrero";

    case 3:
      return "Marzo";

    case 4:
      return "Abril";

    case 5:
      return "Mayo";

    case 6:
      return "Junio";

    case 7:
      return "Julio";

    case 8:
      return "Agosto";

    case 9:
      return "Septiembre";

    case 10:
      return "Octubre";

    case 11:
      return "Noviembre";

    case 12:
      return "Diciembre";

    default:
      break;
  }
};

export default function Grafica() {
  const [reporte, setReporte] = useState([]);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  const getData = async () => {
    const url = `${URL_BASE_ORDENESPAGO}/GetPagosByMes`;

    const result = await get(url);
    if (result.code === 0) {
      setReporte(result.data.groupMes);
      setTotal(result.data.totalPagado);
      setSubtotal(result.data.subtotal);
    } else {
      notification(composeErrorNotification("GetPagosByMes"));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (reporte.length === 0) {
    return <></>;
  }

  const getReporteByMes = (month) => {
    const value = reporte.find((x) => x.mes == month);

    return {
      key: month,
      leftMes: getNameMes(month),
      totalPagado: value != null ? value.total : 0,
      subtotal: value != null ? value.subtotal : 0,
      asistenciasPagadas: value != null ? value.countAsistencias : 0,
      ordenesPagadas: value != null ? value.countOrdenes : 0,
      enero:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 1)
          : 0,
      febrero:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 2)
          : 0,
      marzo:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 3)
          : 0,
      abril:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 4)
          : 0,
      mayo:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 5)
          : 0,
      junio:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 6)
          : 0,
      julio:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 7)
          : 0,
      agosto:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 8)
          : 0,
      septiembre:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 9)
          : 0,
      octubre:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 10)
          : 0,
      noviembre:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 11)
          : 0,
      diciembre:
        value != null
          ? value.countAsistenciasPorMes.find((x) => x.mes == 12)
          : 0,
    };
  };

  const data = [];

  for (let index = 1; index <= 12; index++) {
    const value = getReporteByMes(index);

    data.push(value);
  }

  return (
    <>
      <Table
        size="small"
        pagination={false}
        scroll={{ x: 1300 }}
        bordered
        columns={colums}
        dataSource={data}
        footer={() => {
          return (<div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: 300,
              }}
            >
              <p style={{ color: "#1890FF" }}>
                Total:{" "}
                <span style={{ color: "black" }}>
                  {formatCurrency(total)}
                </span>
              </p>

              <p style={{ color: "#1890FF" }}>
                Subtotal:{" "}
                <span style={{ color: "black" }}>
                  {formatCurrency(subtotal)}
                </span>
              </p>
            </div>
          </div>);
        }}
      />
    </>
  );
}
