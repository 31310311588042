import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BotonDiv from "../../../../components/Boton/BotonDiv";
import { cleanServicioSelected } from "../../../../context/actions/serviciosActions";
import Tipo from "./Tipo";

export default function Servicios() {
  const dispatch = useDispatch();
 
  const { servicios } = useSelector(
    (state) => state.tiposAsistenciaReducer.tipoAsistenciaSelected
  );

  const handleClick = () => dispatch(cleanServicioSelected());

  
  return (
    <>
      <Row>
        <Col span={12}>
          <div className="subtituloMenos">Servicios</div>
        </Col>
        <Col span={12}>
          <BotonDiv onClick={handleClick} tituloBoton="nuevo" />
        </Col>
      </Row>

      {servicios.map((s, i) => (
        <Tipo key={i} servicio={s} />
      ))}
    </>
  );
}
