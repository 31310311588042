import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

export const AsistenciasOrdenPagoCabecera = () => {
  const { iva, ivaRetenido, isrRetenido } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );
  return (
    <Row className="tituloLista" gutter={[10, 10]}>
      <Col span={2}>
        <div className="tituloCabeceraRound">ID</div>
      </Col>
      <Col span={2}>
        <div className="tituloCabeceraRound">fecha</div>
      </Col>
      <Col span={2}>
        <div className="tituloCabeceraRound">SAT</div>
      </Col>
      <Col span={3}>
        <div className="tituloCabeceraRound">Servicio</div>
      </Col>
      
      <Col span={3}>
        <div className="tituloCabeceraRound">Final - Tipo</div>
      </Col>
      <Col span={2}>
        <div className="tituloCabeceraRound">Monto</div>
      </Col>
      <Col span={2}>
        <div className="tituloCabeceraRound">
          IVA <span style={{ fontSize: 12 }}>+{iva}%</span>
        </div>
      </Col>
      <Col span={2}>
        <div className="tituloCabeceraRound">
          IVAr <span style={{ fontSize: 12 }}>-{ivaRetenido}%</span>
        </div>
      </Col>
      <Col span={2}>
        <div className="tituloCabeceraRound">
          ISRr <span style={{ fontSize: 12 }}>-{isrRetenido}%</span>
        </div>
      </Col>
      <Col span={2}>
        <div className="tituloCabeceraRound">Total</div>
      </Col>

      <Col span={2}></Col>
    </Row>
  );
};
