import { AsistenciasOrdenPagoCabecera } from "./AsistenciasOrdenPagoCabecera";
import { AsistenciasOrdenPagoRows } from "./AsistenciasOrdenPagoRows";
import { SelectAsistenciasElegibles } from "./SelectAsistenciasElegibles";

export const AsistenciasOrdenPago = () => {
  return (
    <>
      <SelectAsistenciasElegibles />
      <AsistenciasOrdenPagoCabecera />
      <AsistenciasOrdenPagoRows />
    </>
  );
};
