import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useSelector } from "react-redux";

export const ChartDesglose = () => {
  const { asistenciasTodas, asistenciasOP } = useSelector(
    (state) => state.reportesReducer.reportePorMes
  );

  if (!asistenciasTodas) return <div></div>;

  return (
    <PieChart
      animate
      data={[
        {
          title: "Con OP",
          value:
            (100 * asistenciasOP.asistenciasConOP) /
            asistenciasOP.asistenciasConOPTotal,
          color: "#f76e4f",
        },
        {
          title: "Con Factura",
          value:
            (100 * asistenciasOP.asistenciasConFactura) /
            asistenciasOP.asistenciasConOPTotal,

          color: "#f7b74f",
        },
        {
          title: "Autorizado",
          value:
            (100 * asistenciasOP.asistenciasAutorizadas) /
            asistenciasOP.asistenciasConOPTotal,

          color: "#f7f14f",
        },
        {
          title: "Pagado",
          value:
            (100 * asistenciasOP.asistenciasPagadas) /
            asistenciasOP.asistenciasConOPTotal,

          color: "#bcf74f",
        },
      ]}
    />
  );
};
