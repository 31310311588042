import { EyeOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

export default function ItemsArchivos({ archivo }) {
  return (
    <Row className="rowTabla">
      <Col span={9}>{archivo.nombre}</Col>

      <Col span={9}>{archivo.tipo}</Col>
    </Row>
  );
}
