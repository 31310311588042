import React, { useState } from 'react'
import CardPage from '../../../layout/CardPage'
import { ProveedorAsistencias } from './ProveedorAsistencias';

export const Proveedor = ({proveedor}) => {
  const [show, setShow] = useState(false);

  return (
<CardPage
      titulo={proveedor.proveedorNombre}
      tituloBoton={show ? "ocultar" : "mostrar"}
      accionBoton={() => setShow(!show)}
    >
        {show && (<ProveedorAsistencias proveedorID={proveedor.proveedorID} />)}
    </CardPage>
  )
}
