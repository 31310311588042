import { Col, Row } from 'antd'


export const ProveedorCabecera = () => {
    return (
        <Row gutter={10} className="tituloLista">
            <Col span={1}></Col>
            <Col span={8} style={{ textAlign: 'center' }}>Proveedor</Col>
            <Col span={2} style={{ textAlign: 'center' }}>Servicios</Col>
            <Col span={4} style={{ textAlign: 'center' }}>Costo total</Col>
            <Col span={4} style={{ textAlign: 'center' }}>Promedio</Col>
        </Row>
    )
}
