import { Col, DatePicker, Input, Row, Select, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BotonDivLeft2 from "../../components/Boton/BotonDivLeft2";
import {
  setFiltroAsistencia,
  startGetAsistencias,
  startGetXLS,
} from "../../context/actions/asistenciasActions";
import { startGetClientes } from "../../context/actions/clientesActions";

const { RangePicker } = DatePicker;

////////
export const Controles = ({ filter }) => {
  const { asistenciasLoading } = useSelector(
    (state) => state.asistenciasReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetClientes());
  }, []);

  //Traer clientes
  const clientes = useSelector((state) => state.clientesReducer.clientes);
  const listaSelectClientes = clientes.map((c) => ({
    clienteID: c.clienteID,
    nombreComercial: c.nombreComercial,
  }));

  //Traer proveedores
  const proveedores = useSelector(
    (state) => state.proveedoresReducer.proveedores
  );
  const listaSelectProveedores = proveedores.map((p) => ({
    proveedorID: p.proveedorID,
    nombreComercial: p.nombreComercial,
  }));

  //Traer filtro de asistencias
  const stateFiltro = useSelector(
    (state) => state.asistenciasReducer.filtroAsistencia
  );

  const onChangeFechas = (fechas) => {
    const body = {
      ...stateFiltro,
      fechaInicio: fechas[0].hour(0).minute(0).second(0).format(),
      fechaFin: fechas[1].hour(23).minute(59).second(0).format(),
    };

    dispatch(setFiltroAsistencia(body));
  };

  const onChangeProveedores = (value) => {
    const body = {
      ...stateFiltro,
      proveedorId: value,
    };

    dispatch(setFiltroAsistencia(body));
  };

  const onChangeCliente = (value) => {
    console.log(value);

    const body = {
      ...stateFiltro,
      clienteId: value,
    };

    dispatch(setFiltroAsistencia(body));
  };

  const onSubmit = () => dispatch(startGetAsistencias());
  const XLS = () => dispatch(startGetXLS());

  function debonce(func, timeout = 700) {
    let timer;

    return (...arg) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, arg);
      }, timeout);
    };
  }

  const change = debonce(filter);

  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={4}>
        <Input placeholder="Buscar..." onChange={change} />
      </Col>
      <Col span={1} style={{ paddingTop: 4 }}>
        {asistenciasLoading && <Spin size="small" />}
      </Col>
      <Col span={1}></Col>
      <Col span={6}>
        <RangePicker
          placeholder={["Inicio", "Fin"]}
          onChange={onChangeFechas}
        />
      </Col>
      <Col span={4}>
        <Select
          allowClear
          showSearch
          fieldNames={{ label: "nombreComercial", value: "proveedorID" }}
          style={{ width: "100%" }}
          placeholder="Proveedores"
          options={listaSelectProveedores}
          filterOption={(input, option) =>
            (option?.nombreComercial ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={onChangeProveedores}
        />
      </Col>
      <Col span={4}>
        <Select
          allowClear
          showSearch
          fieldNames={{ label: "nombreComercial", value: "clienteID" }}
          style={{ width: "100%" }}
          placeholder="Cliente"
          options={listaSelectClientes}
          filterOption={(input, option) =>
            (option?.nombreComercial ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={onChangeCliente}
        />
      </Col>
      <Col span={2} style={{ paddingTop: 3 }}>
        <BotonDivLeft2 onClick={onSubmit} tituloBoton="Filtrar" />
      </Col>
      <Col span={2} style={{ paddingTop: 3 }}>
        <BotonDivLeft2 onClick={XLS} tituloBoton="XLS" />
      </Col>
    </Row>
  );
};
