import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  setProveedorSelected,
  startGetAsistenciaSucursalesByProveedorID,
} from "../../../../context/actions/asistenciasActions";

export const ProveedoresItem = ({
  proveedor: { nombreComercial, proveedorID },
}) => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const { proveedorSelected } = useSelector(
    (state) => state.asistenciasReducer
  );
  const handleClick = () => {
    console.log(proveedorID);

    dispatch(
      startGetAsistenciaSucursalesByProveedorID({
        proveedorID,
        asistenciaID,
      })
    );
    dispatch(setProveedorSelected(proveedorID));
  };

  return (
    <div
      onClick={handleClick}
      className={`proveedorItem ${
        proveedorID === proveedorSelected && "proveedorSelected"
      }`}
    >
      {nombreComercial}
    </div>
  );
};
