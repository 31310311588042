import {
  URL_BASE_PROVEEDORES,
  URL_BASE_PROVEEDORES_XLS,
  URL_BASE_XLS,
  URL_GENERICOS,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, getFile, post, postGetFile } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetProveedores = () => {
  return async (dispatch) => {
    const result = await get(URL_BASE_PROVEEDORES);

    if (result.code === 0) {
      dispatch(storeProveedores(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Proveedores",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeProveedores = (payload) => ({
  type: types.storeProveedores,
  payload,
});

///////////////////////////////////////////////////////////////////////////////

export const startGetProveedor = (proveedorID) => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_PROVEEDORES}/${proveedorID}`);

    if (result.code === 0) {
      dispatch(storeProveedor(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Proveedor",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeProveedor = (payload) => ({
  type: types.storeProveedor,
  payload,
});
///////////////////////////////////////////////////////////////////////////////

export const cambiarEstatusProveedor = (proveedorID) => {
  return async (dispatch, geState) => {
    dispatch(setCambiandoEstatus(true));
    const {usuarioID} = geState().userReducer.user;
    const url = `${URL_BASE_PROVEEDORES}/ProveedorEstatus/${proveedorID}/${usuarioID}`;
    console.log(url);
    const result = await get(url);
    if (result.code === 0) {
      dispatch(startGetProveedor(proveedorID));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("cambiarEstatusProveedor", `${result.error}`)
        )
      );
    }

    dispatch(setCambiandoEstatus(false));
  };
};

const setCambiandoEstatus = (payload) => ({
  type: types.setCambiandoEstatus,
  payload,
});

///////////////

export const startGetBancos = () => {
  return async (dispatch) => {

    const url = `${URL_BASE_PROVEEDORES}/GetBancos`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeBancos(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetBancos", `${result.error}`)
        )
      );
    }


  };
};

const storeBancos = (payload) => ({
  type: types.storeBancos,
  payload,
});

///////////////
export const startUpdateEstrella = (body) => {
  return async (dispatch) => {
    const result = await post(`${URL_BASE_PROVEEDORES}/UpdateEstrella`, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetProveedor(body.proveedorID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post info pago",
            `${result.error}`
          )
        )
      );
    }
  };
};

///////////////////////////////////////////////////////////////////////////////

export const startPostProveedor = (body) => {
  console.log(body)
  return async (dispatch, getState) => {
    // const { proveedor } = getState().proveedoresReducer;

    console.log({bodyAction: body});

    const result = await post(URL_BASE_PROVEEDORES, body);
    console.log({result});



    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification("Datos guardados")));
      dispatch(startGetProveedores());
    } else {
      dispatch(
        notification(
          composeErrorNotification("Post Proveedor", `${result.error}`)
        )
      );
    }
  };
};

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const startUpdateProveedor = (values) => {
  return async (dispatch, getState) => {
    // const { proveedor } = getState().proveedoresReducer;
    const result = await post(
      `${URL_BASE_PROVEEDORES}/UpdateProveedor`,
      values
    );

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetProveedor(values.proveedorID));
    } else {
      console.log(result.error)
      dispatch(
        notification(
          composeErrorNotification("UpdateProveedor", `${result.error}`)
        )
      );
    }
  };
};

////////////////////////////////////////////////////////
export const setValueProveedor = (payload) => ({
  type: types.setValueProveedor,
  payload,
});

export const cleanProveedorForm = () => ({
  type: types.cleanProveedorForm,
  payload: null,
});

export const setPagina = (payload) => ({
  type: types.setPagina,
  payload,
});

export const pickTipoAsistenciaProveedor = (tipo) => ({
  type: types.pickTipoAsistenciaProveedor,
  payload: tipo,
});
////////////////////////////

export const initPostServiciosProveedor = (payload) => ({
  type: types.initPostServiciosProveedor,
  payload,
});

const updatePostServiciosFullProveedor = (payload) => ({
  type: types.updatePostServiciosFullProveedor,
  payload,
});

export const startLoadProveedorServicios = (body) => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_PROVEEDORES}/GetSucursalServicios/${body.proveedorSucursalID}/${body.tipoAsistenciaID}`;
    const result = await get(url, body);

    if (result.code === 0) {
      // dispatch(initPostServiciosProveedor(body));
      dispatch(updatePostServiciosFullProveedor(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get campania servicios",
            `${result.error && result.error}`
          )
        )
      );
    }
  };
};

////////////////////////////

export const startPostInfoPago = () => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_PROVEEDORES}/UpdateInfoPago`;
    const {proveedor} = getState().proveedoresReducer;

    if (proveedor.banco.length > 0 && proveedor.clabe.length !== 18) {
      dispatch(
        notification(
          composeErrorNotification(
            "La clabe debe ser de 18 dígitos"
          )
        )
      )
      return;
    }

    const result = await post(url, proveedor);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post info pago",
            `${result.error}`
          )
        )
      );
    }
  };
};
////////////////////////////
export const updateServicioIDProveedor = (body) => {
  return (dispatch, getState) => {
    const {servicios} = getState().proveedoresReducer.postServicios;

    console.log({servicios});
    let newServicios = [];
    if (body.accion) {
      newServicios = [
        ...servicios,
        {
          servicioID: body.servicioID,
          lp1: 0,
          lp1m: 0,
          lp2: 0,
          lp2m: 0,
          banderazo: 0,
          xkm: 0,
        },
      ];
    } else {
      newServicios = servicios.filter((s) => s.servicioID !== body.servicioID);
    }
    dispatch(updateServiciosProveedor(newServicios));
  };
};

export const updateServicioDatoProveedor = (body) => {
  return (dispatch, getState) => {
    const {servicios} = getState().proveedoresReducer.postServicios;
    let newServicios = servicios.map((s) => {
      if (s.servicioID === body.servicioID) {
        return {...s, ...body};
      } else {
        return {...s};
      }
    });

    dispatch(updateServiciosProveedor(newServicios));
  };
};

const updateServiciosProveedor = (payload) => ({
  type: types.updateServiciosProveedor,
  payload,
});

////////////////////////////

export const startPostProveedorServicios = ({proveedorSucursalID}) => {
  return async (dispatch, getState) => {
    const {
      postServicios,
      tipoAsistenciaSelected: {tipoAsistenciaID},
    } = getState().proveedoresReducer;

    const url = `${URL_BASE_PROVEEDORES}/PostSucursalServicios`;

    const body = {
      ...postServicios,
      proveedorSucursalID,
      tipoAsistenciaID,
    };
    console.log({body});
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Servicios prov",
            `${result.error}`
          )
        )
      );
    }
  };
};

///
export const startPostGeo = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_PROVEEDORES}/PostGeo`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Geo",
            `${result.error}`
          )
        )
      );
    }
  };
};

///////
export const setSucursalSelected = (sucursal) => ({
  type: types.setSucursalSelected,
  payload: sucursal,
});

///////
export const startPostPin = (body) => {
  return async (dispatch, getState) => {
    const {proveedorID} = getState().proveedoresReducer.proveedor;
    const url = `${URL_BASE_PROVEEDORES}/PostPin`;
    const result = await post(url, body);
    console.log(body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetProveedor(proveedorID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Geo",
            `${result.error}`
          )
        )
      );
    }
  };
};

////////////////////////////////////////////////////////////
export const startGetEstados = () => {
  return async (dispatch) => {
    const url = `${URL_GENERICOS}/GetEstados`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeEstados(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get estados",
            `${result.error && result.error}`
          )
        )
      );
    }
  };
};

const storeEstados = (estados) => ({
  type: types.storeEstados,
  payload: estados,
});

export const startGetMunicipio = (estado) => {
  return async (dispatch) => {
    const url = `${URL_GENERICOS}/GetMunicipiosByEstado/${estado}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeMunicipios(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get municipios",
            `${result.error && result.error}`
          )
        )
      );
    }
  };
};

const storeMunicipios = (municipios) => ({
  type: types.storeMunicipios,
  payload: municipios,
});

export const setFiltroProveedores = (elemento) => ({
  type: types.setFiltroProveedores,
  payload: elemento,
});

export const clearFiltroProveedores = () => ({
  type: types.clearFiltroProveedores,
  payload: null,
});

export const startPostFiltroProveedores = () => {
  return async (dispatch, getState) => {
    const {filtroProveedores} = getState().proveedoresReducer;

    const url = `${URL_BASE_PROVEEDORES}/GetProveedoresFiltered`;
    const result = await post(url, filtroProveedores);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification("Filtro correcto")));
      dispatch(storeProveedores(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Filtro proveedores",
            `${result.error}`
          )
        )
      );
    }
  };
};

/////

export const startGetProveedoresXLS = () => {
  return async (dispatch) => {
    const url = `${URL_BASE_PROVEEDORES_XLS}/GetProveedoresXLS`;
    const result = await postGetFile(url, "Proveedores.xls");

    if (result.code === 0) {
      //
    } else {
      dispatch(
        notification(
          composeErrorNotification("Get xls proveedores", `${result.error}`)
        )
      );
    }
  };
};

export const startGetDocumentoPago = (proveedorID, tipo) => {
  return async (dispatch, getState) => {
    let nombreFinal;
    let fileExt;
    const { caratulaEdoCtaUri, constanciaSituacionUri, nombreComercial } =
      getState().ordenesPagoReducer.ordenPago;
    const url = `${URL_BASE_PROVEEDORES}/GetDocumentoPago/${proveedorID}/${tipo}`;

    try {
      if (tipo === "CaratulaEdoCtaUri") {
        fileExt = caratulaEdoCtaUri.split(".").pop();
        nombreFinal = `CaratulaEdoCta_${nombreComercial}.${fileExt}`;
      }
      if (tipo == "ConstanciaSituacionUri") {
        fileExt = constanciaSituacionUri.split(".").pop();
        nombreFinal = `CSF_${nombreComercial}.${fileExt}`;
      }
    } catch (e) {
      dispatch(
        notification(composeErrorNotification("Archivo no encontrado", ""))
      );

      return;
    }

    const result = await getFile(url, nombreFinal);

    if (result.code === -1) {
      dispatch(
        notification(
          composeErrorNotification("startGetDocumentoPago", `${result.data}`)
        )
      );
    }
  };
};


export const startGetDocumentoPago2 = (proveedorID, tipo) => {
  return async (dispatch, getState) => {
    let nombreFinal;
    let fileExt;
    const { caratulaEdoCtaUri, constanciaSituacionUri, nombreComercial } =
      getState().proveedoresReducer.proveedor;
    const url = `${URL_BASE_PROVEEDORES}/GetDocumentoPago/${proveedorID}/${tipo}`;

    try {
      if (tipo === "CaratulaEdoCtaUri") {
        fileExt = caratulaEdoCtaUri.split(".").pop();
        nombreFinal = `CaratulaEdoCta_${nombreComercial}.${fileExt}`;
      }
      if (tipo == "ConstanciaSituacionUri") {
        fileExt = constanciaSituacionUri.split(".").pop();
        nombreFinal = `CSF_${nombreComercial}.${fileExt}`;
      }
    } catch (e) {
      dispatch(
        notification(composeErrorNotification("Archivo no encontrado", ""))
      );

      return;
    }

    const result = await getFile(url, nombreFinal);

    if (result.code === -1) {
      dispatch(
        notification(
          composeErrorNotification("startGetDocumentoPago", `${result.data}`)
        )
      );
    }
  };
};

