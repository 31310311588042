import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CardPage from "../../../layout/CardPage";
import {
  startGetOrdenPago,
} from "../../../context/actions/ordenesPagoActions";
import { AsistenciasOrdenPago } from "./asistenciasOrdenPago/AsistenciasOrdenPago";
import { EstatusOrdenPago } from "./estatusOrdenPago/EstatusOrdenPago";
import { InfoOrdenPago } from "./infoOrdenPago/InfoOrdenPago";
import { Divider } from "antd";

export const Detalle = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ordenPagoID } = useParams();
  useEffect(() => {
    dispatch(startGetOrdenPago(ordenPagoID));
  }, []);

  return (
    <CardPage
      titulo={`Orden de Pago: ${ordenPagoID}`}
      tituloBoton="Regresar"
      accionBoton={() => history.push("/ordenesPago")}
    >
      <EstatusOrdenPago />
      <InfoOrdenPago />

      <Divider />
      <AsistenciasOrdenPago />
    </CardPage>
  );
};
