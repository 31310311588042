import React from "react";
import { useSelector } from "react-redux";
import Tipo from "./Tipo";

export default function TiposAsistencia() {
  const { tiposAsistencia } = useSelector(
    (state) => state.tiposAsistenciaReducer
  );

  return (
    <>
      {tiposAsistencia.map((t, i) => (
        <Tipo key={i} tipo={t} />
      ))}
    </>
  );
}
