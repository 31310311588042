import { Col, Row, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import { startDownloadDocumentoOP } from "../../../../../../context/actions/ordenesPagoActions";
import { startGetDocumentoPago } from "../../../../../../context/actions/proveedoresActions";
import ModelPage from "../../../../../../layout/ModelPage";

//////////////
const optionsDownloads = [
  { value: 0, label: "PDF" },
  { value: 3, label: "XML" },
  { value: 4, label: "Validación" },
  { value: 5, label: "OP" },
  { value: 'ConstanciaSituacionUri', label: "CSF" },
  { value: 'CaratulaEdoCtaUri', label: "Carátula" },
];
export const DescargaFilesModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { ordenPagoID, proveedorID, fechaPagoRequerido } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );
  const [optionDownload, setOptionDownload] = useState([0,3,4, 5])

  // 0 factura pdf, 3 factura xml, 4 validacion sat
  const descargaFile = (tipo) =>
    dispatch(startDownloadDocumentoOP(ordenPagoID, tipo));

  const download3 = ( ) => {
    optionDownload.forEach((tipo) => {
      if ( typeof  tipo === 'number') {
        descargaFile(tipo)
      }
      if ( typeof  tipo === 'string') {
        dispatch(startGetDocumentoPago(proveedorID, tipo))
      }
    })
  }
  ////////////
  return (
    <ModelPage
      titulo="Descargar documentos"
      isVisible={showModal}
      width={600}
      handleOk={() => setShowModal(false)}
      handleCancel={() => setShowModal(false)}
    >
        <Row gutter={10} >
          <Col>Pagar el: {fechaPagoRequerido}</Col>
        </Row>

      <Row gutter={24} style={{marginTop: 10, display:"flex", width: '600px'}}>
        <Col span={6}>
          <BotonSubmit tituloBoton="PDF" onClick={() => descargaFile(0)} />
        </Col>
        <Col span={6}>
          <BotonSubmit tituloBoton="XML" onClick={() => descargaFile(3)} />
        </Col>
        <Col span={6}>
          <BotonSubmit
            tituloBoton="Validación"
            onClick={() => descargaFile(4)}
          />
        </Col>

      </Row>
      <Row gutter={24} style={{margin: '20px'}}>

        <Col span={16}>
          <Select onChange={(values) => setOptionDownload(values)} style={{width: '100%'}} defaultValue={[0, 3, 4, 5]}  mode="multiple" allowClear options={optionsDownloads}  />
        </Col>

        <Col span={8}>
          <BotonSubmit
            tituloBoton="Descargar"
            onClick={() => download3()}
          />
        </Col>
      </Row>

      <Row gutter={10} style={{marginTop: 10}} justify="center">

        <Col span={8}>
          <BotonSubmit  tituloBoton="CSF" onClick={() => dispatch(startGetDocumentoPago(proveedorID, "ConstanciaSituacionUri"))} />
        </Col>

        <Col span={8}>
          <BotonSubmit tituloBoton="Carátula" onClick={() => dispatch(startGetDocumentoPago(proveedorID, "CaratulaEdoCtaUri"))} />
        </Col>
       
        
      </Row>
    </ModelPage>
  );
};
