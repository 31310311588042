import { Button, Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import LabelTextarea from "../../../../../../components/input/LabelTextarea";
import {
  startOrdenToEmail,
  startRechazarFactura,
} from "../../../../../../context/actions/ordenesPagoActions";
import ModelPage from "../../../../../../layout/ModelPage";

export const ConfirmarFacturaModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { ordenPagoID } = useParams()

  const [comentario, setComentario] = useState("");
  const [facturaProcede, setFacturaProcede] = useState();

  const handleOK = () => {
    if (facturaProcede === 1) {
      // si
      const body = {
        ordenPagoID,
        NewEstatusOrdenPagoID: 4,
      }; // 4 = estatus solicitud de pago

      dispatch(startOrdenToEmail(body));
    }
    if (facturaProcede === 0) {
      const body = { comentario, ordenPagoID };
      dispatch(startRechazarFactura(body));
    }

    setShowModal(false);
  };


  return (
    <ModelPage
      titulo="Confirmar Factura"
      isVisible={showModal}
      handleOk={handleOK}
      handleCancel={() => setShowModal(false)}
    >
      <Row>
        <Col span={12}>
          <Button onClick={() => setFacturaProcede(0)} danger>
            Factura NO procede
          </Button>
        </Col>
        <Col span={12}>
          <Button onClick={() => setFacturaProcede(1)}>
            Factura SI procede
          </Button>
        </Col>
      </Row>

      {facturaProcede === 0 && (
        <>
          <div className="tituloPequeño" style={{ marginTop: 10 }}>
            La factura NO procede, se borrarán los documentos cargados <br />
          </div>
          <LabelTextarea
            value={comentario}
            name="comentario"
            onChange={({ target }) => setComentario(target.value)}
            style={{ marginTop: 10 }}
            label="Porque no procede"
          />
        </>
      )}

      {facturaProcede === 1 && (
        <>
          <div className="tituloPequeñoNegro" style={{ marginTop: 10 }}>
            La factura esta autorizada, se procede a solicitar pago<br />
            Si la orden proviene del portal de proveedor se autorizará automáticamente

          </div>

        </>
      )}

    </ModelPage>
  );
};
