import { Col, Row } from "antd";
import { Asistencias } from "./asistencias/_Asistencias";
import { Proveedores } from "./proveedores/_Proveedores";
import { Estatus } from "./estatus/_Estatus";
import { Servicios } from "./servicios/_Servicios";

export const Listados = () => {
  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={24} md={24} xl={8}>
          <Proveedores />
        </Col>
        <Col xs={24} md={24} xl={8}>
          <Servicios />
        </Col>
        <Col xs={24} md={24} xl={8}>
          <Estatus />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <Asistencias />
        </Col>
      </Row>
    </>
  );
};
