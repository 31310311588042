import { Input, Table, Row, Col, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";
import { XLSBoton } from "./XLSBoton";
import { useHistory } from "react-router";

////
export default function Tabla() {
  const history = useHistory();
  const { proveedoresColumns } = useColums();
  const { proveedores } = useSelector((state) => state.proveedoresReducer);


  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(proveedores);
    filtraActivo("Activos");
    filtraInterno("Internos");
  }, [proveedores]);

  ///

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = proveedores.filter(
        (a) =>
          (a.nombreComercial &&
            a.nombreComercial
              .toLowerCase()
              .includes(target.value.toLowerCase())) ||
          (a.proveedorID &&
            a.proveedorID.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.razonSocial &&
            a.razonSocial.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.contactoNombre &&
            a.contactoNombre
              .toLowerCase()
              .includes(target.value.toLowerCase())) ||
          (a.contactoTelefono &&
            a.contactoTelefono
              .toLowerCase()
              .includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      setListaFinal(proveedores);
    }
  };

  const filtraActivo = (state) => {
    let newList = [];
    if (state === "Activos") {
      newList = proveedores.filter((a) => a.estaActivo);
      setListaFinal(newList);
    } else if (state === "Inactivos") {
      newList = proveedores.filter((a) => !a.estaActivo);
      setListaFinal(newList);
    } else {
      setListaFinal(proveedores);

      console.log({ state });
    }
  };

  const filtraInterno = (state) => {
    let newList = [];
    if (state === "Internos") {
      newList = proveedores.filter((a) => a.esProveedorInterno);
      setListaFinal(newList);
    } else if (state === "Externos") {
      newList = proveedores.filter((a) => !a.esProveedorInterno);
      setListaFinal(newList);
    } else {
      setListaFinal(proveedores);
    }
  };
  ////

  const { Backoffice_DescargarProveedores } = useSelector(
    (state) => state.userReducer.user
  );

  const handleClick = ({ proveedorID }) =>
    history.push(`/proveedores/detalle/${proveedorID}`);

  return (
    <>
      <Row gutter={10}>
        <Col span={6}>
          <Input
            onChange={filter}
            placeholder="buscar"
            style={{ marginBottom: 10 }}
            size="small"
          />
        </Col>
        <Col span={3}>
          <Select
          defaultValue={"Activos"}
            style={{ width: "100%" }}
            onChange={filtraActivo}
            options={[
              { label: "Todos", value: "xx" },
              { label: "Activos", value: "Activos" },
              { label: "Inactivos", value: "Inactivos" },
            ]}
          ></Select>
        </Col>
        <Col span={3}>
          <Select
          defaultValue={"Internos"}
            onChange={filtraInterno}
            style={{ width: "100%" }}
            options={[
              { label: "Todos", value: "xx" },
              { label: "Internos", value: "Internos" },
              { label: "Extenos", value: "Extenos" },
            ]}
          ></Select>
        </Col>
        <Col span={2}>({listaFinal.length})</Col>

        <Col span={6} style={{ paddingTop: 3 }}>
          {Backoffice_DescargarProveedores && <XLSBoton />}
        </Col>
      </Row>

      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: () =>
              history.push(`/proveedores/detalle/${record.proveedorID}`),
          };
        }}
        rowKey="proveedorID"
        columns={proveedoresColumns}
        dataSource={listaFinal}
        size="small"
        style={{ cursor: "pointer" }}
      />
    </>
  );
}
