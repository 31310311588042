import { Input } from "antd";
import React from "react";

export default function LabelText({
  label,
  name,
  onChange,
  value,
  placeholder,
  disabled,
  defaultValue
}) {
  const handleChange = (e) => {
    const body = {
      target: {
        name,
        value: e.target.value,
      },
    };

    onChange(body);
  };
  return (
    <>
      <div className="labelImput">{label}</div>
      <Input
        disabled={disabled}
        name={name}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </>
  );
}
