import React from "react";
import { GeneralesFecha } from "./_GeneralesFecha";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

export const Generales = () => {
  const {
    asistenciasDia: { fecha, totalAsistencias, sumaAsistencias },
  } = useSelector((state) => state.reportesReducer);
  //
  return (
    <div style={{ fontWeight: 600 }}>
      <GeneralesFecha />
      <Row>
        <Col xs={12} sm={6} lg={3} style={{ color: "orange" }}>
          Fecha:
        </Col>
        <Col xs={12} sm={8} lg={6}>
          {fecha}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} lg={3} style={{ color: "orange", fontWeight: 600 }}>
          Asistencias:
        </Col>
        <Col xs={12} sm={8} lg={6}>
          {totalAsistencias}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} lg={3} style={{ color: "orange", fontWeight: 600 }}>
          Costo:
        </Col>
        <Col xs={12} sm={8} lg={6}>
          {sumaAsistencias}
        </Col>
      </Row>
      <div style={{ fontSize: 12, color: "#999" }}>* sin impuestos</div>
    </div>
  );
};
