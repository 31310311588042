import { types } from "../types/types";

const init = {
  cambiandoEstatus: false,
  sucursalSelected: null,
  proveedores: [],
  proveedor: {
    proveedorSucursales: [],
  },
  pagina: "sucursales",
  sucursal: {},
  tipoAsistenciaSelected: {
    servicios: [],
    nombre: "",
  },
  postServicios: {
    servicios: [],
  },
  proveedorFiles: [],
  estados: [],
  municipios: [],
  filtroProveedores: {},
  paginaDetalleSucursal: "tiposAsistencia",
  bancos: [],
};
export const proveedoresReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeBancos:
      return {
        ...state,
        bancos: action.payload,
      };
    case types.setCambiandoEstatus:
      return {
        ...state,
        cambiandoEstatus: action.payload,
      };

    case types.cleanFormaSucursal:
      return {
        ...state,
        sucursal: {},
      };
    case types.storeSucursal: {
      return {
        ...state,
        sucursal: action.payload,
      };
    }
    case types.setPaginaDetalleSucursal:
      return {
        ...state,
        paginaDetalleSucursal: action.payload,
      };

    case types.clearFiltroProveedores:
      return {
        ...state,
        filtroProveedores: {},
      };
    case types.setFiltroProveedores:
      return {
        ...state,
        filtroProveedores: {
          ...state.filtroProveedores,
          ...action.payload,
        },
      };
    case types.storeMunicipios:
      return {
        ...state,
        municipios: action.payload,
      };

    case types.storeEstados:
      return {
        ...state,
        estados: action.payload,
      };

    case types.setSucursalSelected:
      return {
        ...state,
        sucursalSelected: action.payload,
      };
    case types.storeFilesProveedor:
      return {
        ...state,
        proveedorFiles: action.payload,
      };
    case types.updatePostServiciosFullProveedor:
      return {
        ...state,
        postServicios: {
          servicios: action.payload,
        },
      };

    case types.initPostServiciosProveedor:
      return {
        ...state,
        postServicios: {
          ...state.postServicios,
          ...action.payload,
        },
      };

    case types.updateServiciosProveedor:
      return {
        ...state,
        postServicios: {
          ...state.postServicios,
          servicios: action.payload,
        },
      };

    case types.pickTipoAsistenciaProveedor:
      return {
        ...state,
        tipoAsistenciaSelected: action.payload,
      };
    case types.storeProveedor:
      return {
        ...state,
        proveedor: action.payload,
      };

    case types.storeProveedores:
      return {
        ...state,
        proveedores: action.payload,
      };

    case types.setValueProveedor:
      return {
        ...state,
        proveedor: {
          ...state.proveedor,
          [action.payload.name]: action.payload.value,
        },
      };

    case types.cleanProveedorForm:
      return {
        ...state,
        proveedor: {},
      };

    case types.setPagina:
      return {
        ...state,
        pagina: action.payload,
      };

    case types.changeSucursal:
      return {
        ...state,
        sucursal: {
          ...state.sucursal,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
