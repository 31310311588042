import React from "react";

export default function LabelValorLink({ label, valor, linkLabel, onClick }) {
  return (
    <>
      <div className="label">{label}</div>
      <div className="valor">
        {valor}{" "}
        <span
          onClick={onClick}
          style={{
            float: "right",
            marginRight: 5,
            color: "#ca2228",
            cursor: "pointer",
          }}
        >
          {linkLabel}
        </span>
      </div>
    </>
  );
}
