import { URL_BASE_ORDENESPAGO_BUSCAR, URL_BASE_REPORTES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, getFile, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startBuscaOrdenPagoID = (ordenPagoID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO_BUSCAR}/BuscaOrdenPagoID/${ordenPagoID}`;
    const result = await get(url);
    if (result.code === 0) {
      return { ok: true };
    } else {
      dispatch(notification(composeErrorNotification(result.error)));
      return { ok: false };
    }
  };
};

export const startBuscaAsistenciaID = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO_BUSCAR}/BuscaAsistenciaID/${asistenciaID}`;
    const result = await get(url);
    if (result.code === 0) {
      return { ok: true, ordenPagoID: result.data };
    } else {
      dispatch(notification(composeErrorNotification(result.error)));
      return { ok: false };
    }
  };
};

export const startGetDashboard1 = (mes, anio) => {
  return async (dispatch) => {
    const url = `${URL_BASE_REPORTES}/GetDashboard1/${mes}/${anio}`;
    const result = await get(url);
    if (result.code === 0) {

      dispatch(storeDashboard1(result.data));
    } else {
      dispatch(notification(composeErrorNotification(result.error)));
    }
  };
};

const storeDashboard1 = (dashboard1) => ({
  type: types.storeDashboard1,
  payload: dashboard1,
});
