import { useDispatch, useSelector } from "react-redux";
import { OrdenesItemPorCargarPago } from "./OrdenesItemPorCargarPago";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import { startDownloadTxtExcelOrdenesPago, startDownloadTxtOrdenesPago } from "../../../context/actions/ordenesPagoActions";

export const OrdenesListaPorCargarPago = () => {
    const dispatch = useDispatch();
    const { ordenesPagoPorCargarPago } = useSelector((state) => state.ordenesPagoReducer);
  
    return (
      <div>
        {ordenesPagoPorCargarPago.ordenes?.map((o, i) => (
          <OrdenesItemPorCargarPago key={i} o={o} />
        ))}
        <div
          style={{ display: "flex", justifyContent: "space-between", width: 400, marginTop: 20, marginBottom: 20 }}
        >
          <BotonSubmit
            onClick={() => dispatch(startDownloadTxtOrdenesPago())}
            tituloBoton="Generar Archivo TXT"
          />
  
          <BotonSubmit
            style={{backgroundColor: "#17ad32", color: "white", border: "none"}}
            onClick={() => dispatch(startDownloadTxtExcelOrdenesPago())}
            tituloBoton="Generar Archivo TXT Excel"
          />
        </div>
      </div>
    );
  };