import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { formatDate } from "../../../../utils/formatDate";

////
export default function useColums() {
  const history = useHistory();

  const handleClick = ({ campaniaID, clienteID }) =>
    history.push(`/clientes/detalleCampania/${clienteID}/${campaniaID}`);

  const campaniasColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Prefijo de ID",
      dataIndex: "abreviatura",
    },
    {
      title: "Afiliados Proyectados",
      dataIndex: "afiliadosProyectados",
    },
    {
      title: "Inicio",
      render: (text, record) => formatDate(record.fechaInicio),
    },
    {
      title: "Vencimiento",
      render: (text, record) => formatDate(record.fechaVencimiento),
    },

    {
      title: "Esta Activa",
      width: 150,
      render: (text, record) => (
        <>{record.estaActiva ? <span>Si</span> : <span>No</span>}</>
      ),
    },
    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined
          onClick={() => handleClick(record)}
          style={{ color: "#ca2228" }}
          className="eyeIcon"
        />
      ),
    },
  ];

  return { campaniasColumns };
}
