import { URL_BASE_LISTAS, URL_BASE_TIPOSASISTENCIA } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { dele, get, post, put } from "../../utils/fetch";
import { types } from "../types/types";
import { setLoadingCampos } from "./loadingActions";
import { notification } from "./notificationActions";

export const startGetTiposAsistencia = () => {
  return async (dispatch) => {
    const result = await get(URL_BASE_TIPOSASISTENCIA);

    if (result.code === 0) {
      dispatch(storeTiposAsistencia(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Tipos Asistencia",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeTiposAsistencia = (payload) => ({
  type: types.storeTiposAsistencia,
  payload,
});

export const startPostTipoAsistencia = () => {
  return async (dispatch, getState) => {
    const { nombre } = getState().tiposAsistenciaReducer.tipoAsistencia;
    const body = { nombre };

    const result = await post(URL_BASE_TIPOSASISTENCIA, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification("Datos guadados")));
      dispatch(startGetTiposAsistencia());
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post Tipo asistencia",
            `${result.error}`
          )
        )
      );
    }
  };
};

////////////////////////////////////////////////////////
export const setValueTipoAsistencia = (payload) => ({
  type: types.setValueTipoAsistencia,
  payload,
});

export const cleanTipoAsistenciaForm = () => ({
  type: types.cleanTipoAsistenciaForm,
  payload: null,
});

///////////////////////////////////////////////////////////////////

export const startSelectTipoAsistencia = (tipoAsistenciaID) => {
  return (dispatch, getState) => {
    const { tiposAsistencia } = getState().tiposAsistenciaReducer;
    let salida = {};
    tiposAsistencia.map((t) => {
      if (t.tipoAsistenciaID == tipoAsistenciaID) {
        salida = { ...t };
      }
    });
    dispatch(storeTipoAsistenciaSelected(salida));
  };
};

const storeTipoAsistenciaSelected = (payload) => ({
  type: types.storeTipoAsistenciaSelected,
  payload,
});

////////////////////////////////////////////////

export const startUpdateNombreTipoAsisrtencia = (body) => {
  return async (dispatch) => {
    const result = await put(URL_BASE_TIPOSASISTENCIA, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get listas",
            `${result.error}`
          )
        )
      );
    }
  };
};

//////////////////////////////////////////////////////

export const startGetListas = () => {
  return async (dispatch) => {
    const result = await get(URL_BASE_LISTAS);

    if (result.code === 0) {
      dispatch(storeListas(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get listas",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeListas = (payload) => ({
  type: types.storeListas,
  payload,
});

////////////////////////////////////////////////////////

export const startPostCampos = (body) => {
  return async (dispatch) => {
    const result = await post(`${URL_BASE_TIPOSASISTENCIA}/PostCampos`, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetTiposAsistencia());
      dispatch(startLoadCampos(body.servicioID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post campos",
            `${result.error}`
          )
        )
      );
    }
  };
};

////////////////////////////////////////////////////////////

export const startLoadCampos = (servicioID) => {
  return async (dispatch) => {
    dispatch(setLoadingCampos(true));
    const result = await get(
      `${URL_BASE_TIPOSASISTENCIA}/GetCampos/${servicioID}`
    );

    if (result.code === 0) {
      dispatch(storeCampos(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - get campos",
            `${result.error}`
          )
        )
      );
    }

    dispatch(setLoadingCampos(false));
  };
};

const storeCampos = (payload) => ({
  type: types.storeCampos,
  payload,
});

////////
export const startDeleCampo = (id, servicioID) => {
  return async (dispatch) => {
    const result = await dele(`${URL_BASE_TIPOSASISTENCIA}/DeleCampo/${id}`);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startLoadCampos(servicioID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Delete campos",
            `${result.error}`
          )
        )
      );
    }
  };
};
