import { useState } from "react";
import { useDispatch } from "react-redux";
import { URL_BASE_PROVEEDORES } from "../../../../../constants/url";
import { notification } from "../../../../../context/actions/notificationActions";
import { startGetProveedor } from "../../../../../context/actions/proveedoresActions";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../../../../utils/composeNotification";

import { postFile } from "../../../../../utils/fetch";

export const useUploadFile2 = (proveedorID) => {
  const dispatch = useDispatch();

  const [body, setBody] = useState({});

  const camposEror = () => {
    let salida = false;
    if (estaVacioNull(body.constanciaSituacion)) salida = true;
    if (estaVacioNull(body.caratulaEdoCta)) salida = true;
    return salida;
  };

  const estaVacioNull = (valor) => {
    if (valor === "" || valor === null || valor === undefined) {
      return true;
    } else return false;
  };

  const props1 = {
    beforeUpload: (constanciaSituacion) => {
      setBody({ ...body, constanciaSituacion });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };
  const props2 = {
    beforeUpload: (caratulaEdoCta) => {
      setBody({ ...body, caratulaEdoCta });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const handleClick = async () => {
    if (camposEror()) {
      return;
    }

    let formdata = new FormData();
    formdata.append("mimetype", "application/pdf|");
    formdata.append("constanciaSituacion", body.constanciaSituacion);
    formdata.append("caratulaEdoCta", body.caratulaEdoCta);
    formdata.append("proveedorID", proveedorID);

    const url = `${URL_BASE_PROVEEDORES}/PostDocumentosPago/`;
    const result = await postFile(url, formdata);
    console.log(result);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetProveedor(proveedorID));
      setBody({});
    } else {
      dispatch(
        notification(
          composeErrorNotification("PostDocumentoPago", `${result.error}`)
        )
      );
    }
  };

  return { body, props1, props2, handleClick };
};
