import { Col, Row } from "antd";
import React from "react";
import { formatCurrency } from "../../utils/formatCurrency";
import { formatCurrencyDecimals } from "../../utils/formatCurrencyDecimals";

export const CajaRow3 = ({ nombre, cantidad, monto }) => {
  return (
    <Row style={{ borderBottom: "1px solid #f1f1f1" }}>
      <Col span={16}>{nombre}</Col>
      <Col span={2} style={{ textAlign: "center" }}>
        {cantidad}
      </Col>
      <Col span={6} style={{ textAlign: "right" }}>
        {formatCurrencyDecimals(monto, 2)}
      </Col>
    </Row>
  );
};
