export const serviciosTextoValor = (arr) => {
  let salida = [];
  arr.map(({nombre, id}) => {
    salida = [
      ...salida,
      {
        texto: nombre,
        valor: id,
      },
    ];
  });

  return salida;
};
