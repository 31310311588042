import { Skeleton } from 'antd'

export const Skele = () => {
    return (<>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />

    </>)
}
