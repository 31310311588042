import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";

////
export default function Tabla() {
  const { tiposAsistenciaColumns } = useColums();
  const { tiposAsistencia } = useSelector((state) => state.tiposAsistenciaReducer);

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(tiposAsistencia);
  }, [tiposAsistencia]);

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = tiposAsistencia.filter(
        (a) =>
          (a.nombre &&
            a.nombre
              .toLowerCase()
              .includes(target.value.toLowerCase())) 
      );
      setListaFinal(newList);
    } else {
      setListaFinal(tiposAsistencia);
    }
  };

  ////
  return (
    <>
      <Input
        onChange={filter}
        placeholder="buscar"
        style={{ width: 300, marginBottom: 10 }}
      />
      <Table
        rowKey="tipoAsistenciaID"
        columns={tiposAsistenciaColumns}
        dataSource={listaFinal}
      />
    </>
  );
}
