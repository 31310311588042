import { useSelector } from "react-redux";
import { Select } from "antd";
import { useEffect, useState } from "react";

const { Option } = Select;

export const ProveedoresSelect = ({ value, onChange }) => {
  const [opciones, setOpciones] = useState([]);
  const { proveedores } = useSelector((state) => state.proveedoresReducer);

  useEffect(() => {
    var temporal = [
      {
        value: "",
        label: " - Proveedores Todos - ",
      },
    ];
    proveedores.map((p) => {
      temporal = [
        ...temporal,
        {
          value: p.proveedorID,
          label: (p.estaActivo ? "" : "x ") + p.nombreComercial,
        },
      ];
    });
    setOpciones(temporal);
  }, [proveedores]);

  //
  return (
    <Select
      placeholder="Seleccione proveedor"
      showSearch
      value={value}
      onChange={onChange}
      style={{ width: "100%" }}
      options={opciones}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};
