export const validaFile = (files) => {
  if (files.length > 0) {
    const file = files[0];
    let fileExt = file.name.split(".").pop();
    fileExt = fileExt.toLowerCase();
    console.log(fileExt);
    if (fileExt === "png" || fileExt === "jpg" || fileExt === "gif")
      return true;
    else return false;
  }
};
