import { Row, Col, Input, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import BotonDiv2 from "../../../components/Boton/BotonDiv2";
import {
  startPostCampo,
  startPostListaBulk,
} from "../../../context/actions/listasActions";
import { convertirLista } from "../../../utils/convertirLista";
import { CopyPaste } from "./CopyPaste";

export const SeccionCamposCrear = () => {
  const [lista, setLista] = useState("");
  const [show, setShow] = useState(false);
  const [clave, setClave] = useState("");
  const dispatch = useDispatch();
  const handleClick = () => {
    if (clave !== "") dispatch(startPostCampo(clave));
    setClave("");
  };

  const handleChange = ({ target }) => setLista(target.value);
  const handleOK = () => {
    const newLista = convertirLista(lista);
    dispatch(startPostListaBulk(newLista));
    setShow(false);
    setLista("");
  };

  return (
    <Row gutter={10} style={{ marginTop: 10, marginBottom: 25 }}>
      <Col span={6}>
        <Input
          name={clave}
          onChange={({ target }) => setClave(target.value)}
          value={clave}
          placeholder="elemento de lista..."
        />
      </Col>
      <Col span={3}>
        <BotonDiv2 onClick={handleClick} tituloBoton="Agregar" />
      </Col>
      <Col span={9}>
        <BotonDiv2 onClick={() => setShow(true)} tituloBoton="Copy-Paste" />
      </Col>
      <Modal
        title="Agregar Elementos a la Lista"
        open={show}
        onOk={handleOK}
        onCancel={() => setShow(false)}
      >
        <CopyPaste onChange={handleChange} value={lista} />
      </Modal>
    </Row>
  );
};
