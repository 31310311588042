import { Divider } from 'antd'
import React from 'react'
import { Estatus } from './Estatus'
import { LocalCarretero } from './LocalCarretero'
import { Servicios } from './Servicios'

export const ProveedorDetalle = ({ proveedor }) => {

    return (
        <div style={{ padding: 1, backgroundColor: '#f1f1f1', border: '1px solid orange' }}>
            <Servicios proveedor={proveedor} />
            <LocalCarretero proveedor={proveedor} />
            <Estatus proveedor={proveedor} />

        </div>
    )
}
