import { Timeline } from "antd";
import dayjs from "dayjs";

export default function DatosLlamada({ llamadas }) {
  return (
    <Timeline mode="left">
      {llamadas.map((l, i) => (
        <Timeline.Item color={"gray"} key={l.id}>
          <p>
            <span style={{ color: "#4c66cf" }}>
              {dayjs(l.fechaLlamada).format("DD-MM-YYYY HH:mm")}
            </span>{" "}
            {"->"} <span style={{ color: "#e62929" }}>{l.nombreUsuario}</span>
            {"->"} <span style={{ color: "#4ba3a3" }}>{l.tipoLlamada}</span>{" "}
            {"->"} <span style={{ color: "#5e5756" }}>{l.nombreLlamada}</span>
          </p>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}
