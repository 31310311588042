import React from "react";
import { useSelector } from "react-redux";
import Geocerca from "./geocerca/Geocerca";
import { Pines } from "./pines/Pines";
import TiposAsistencia from "./tiposAsistencia/TiposAsistencia";

export const CuerpoFolder = () => {
  const { paginaDetalleSucursal } = useSelector(
    (state) => state.proveedoresReducer
  );

  if (paginaDetalleSucursal === "tiposAsistencia") return <TiposAsistencia />;
  if (paginaDetalleSucursal === "geocerca") return <Geocerca />;
  if (paginaDetalleSucursal === "pines") return <Pines />;

  return <div>no encontrada</div>;
};
