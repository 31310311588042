import React, { useEffect } from "react";
import CardPageSin from "../../../../layout/CardPageSin";
import Accion from "./Accion";
import MenuProveedor from "./MenuProveedor";


export default function Folder() {

  return (
    <>
      <CardPageSin>
        <MenuProveedor />
        <Accion />
      </CardPageSin>
    </>
  );
}
