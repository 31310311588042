import React from "react";
import ListHeader from "./ListHeader";
import ListItems from "./ListItems";

export default function List() {
  return (
    <>
      <ListHeader />
      <ListItems />
    </>
  );
}
