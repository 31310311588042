export const addGeo = ({ map, sourceName, fillName, geo, fillColor }) => {
  var fill = map.current.getLayer(fillName);

  if (typeof fill === "undefined") {
    map.current.addSource(sourceName, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: validateEmpty(geo),
        },
      },
    });

    ///
    map.current.addLayer({
      id: fillName,
      type: "fill",
      source: sourceName, // reference the data source
      layout: {},
      paint: {
        "fill-color": fillColor, // blue color fill
        "fill-opacity": 0.5,
      },
    });
  }
};

const validateEmpty = (string) => {
  if (string !== undefined && string !== "" && string !== null)
    return JSON.parse(string);

  return [];
};
