import { Rate } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { startUpdateEstrella } from "../../../../context/actions/proveedoresActions";

export const CalificarEstrellas = ({ estrellas }) => {
  const dispatch = useDispatch();
  const { proveedorID } = useParams();
  const handleChange = (estrellas) => {
    const body = {
      estrellas,
      proveedorID,
    };
    dispatch(startUpdateEstrella(body));
  };
  return (
    <div style={{ marginTop: 15, textAlign: "center" }}>
      <Rate value={estrellas} onChange={handleChange} /> {estrellas}
    </div>
  );
};
