import { useHistory } from "react-router";
import { DatePickerFilter } from "../../../components/datePickerFilter/DatePickerFilter";
import { FilePdfOutlined, EyeOutlined } from "@ant-design/icons";
import { DatePicker, Space, Tooltip } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useDispatch } from "react-redux";
import { startDownloadDocumentoOP } from "../../../context/actions/ordenesPagoActions";
import { formatDateProPlayer } from "../../../utils/formatDateProPlayer";
import { FilterTAble } from "../../../components/datePickerFilter/FilterTAble";

export const useColumns = (arrayRazones) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { RangePicker } = DatePicker;
  const handleClick = ({ ordenPagoID }) =>
    history.push(`/ordenesPago/detalle/${ordenPagoID}`);

  const handlePDF = ({ ordenPagoID }) =>
    dispatch(startDownloadDocumentoOP(ordenPagoID, 5)); // orden de pago

  const columns = [
    {
      title: "OP",
      dataIndex: "ordenPagoID",
      key: "ordenPagoID",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor",
      key: "proveedor",
      ...FilterTAble("proveedor", arrayRazones?.proveedor),
    },
    {
      title: 'Razón Social',
      dataIndex: "razonSocial",
      key: "razonSocial",
      ...FilterTAble("razonSocial", arrayRazones?.razonSocial),
    },
    {
      title: "Cliente",
      dataIndex: "clienteNombre",
      key: "clienteNombre",
      ...FilterTAble("clienteNombre", arrayRazones?.clienteNombre),
    },
    {
      title: "Creacion",
      dataIndex: "fechaCreacion",
      name: "fechaCreacion",
      key: "fechaCreacion",
       ...DatePickerFilter("fechaCreacion"),
    },
    {
      title: "Factura Solicitada",
      dataIndex: "fechaSolicitudFactura",
      key: "fechaSolicitudFactura",
      ...DatePickerFilter("fechaSolicitudFactura"),
    },
    {
      title: "Factura Cargada",
      dataIndex: "fechaCargaFactura",
      key: "fechaCargaFactura",
      ...DatePickerFilter("fechaCargaFactura"),
    },
    {
      title: "Pago Solicitado",
      dataIndex: "fechaSolicitudPago",
      key: "fechaSolicitudPago",
      ...DatePickerFilter("fechaSolicitudPago"),
    },
    {
      title: "Pago Autorizado",
      dataIndex: "fechaAutorizacionPago",
      key: "fechaAutorizacionPago",
      ...DatePickerFilter("fechaAutorizacionPago"),
    },
    {
      title: "Pago Cargado",
      dataIndex: "fechaCargaPago",
      name: "fechaCargaPago",
      key: "fechaCargaPago",
      ...DatePickerFilter("fechaCargaPago"),
    },
    {
      title: "Servicios",
      dataIndex: "numeroServicios",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>{record.numeroServicios}</div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>{formatCurrency(record.total)}</div>
      ),
    },
    {
      title: "",
      width: 60,
      render: (text, record) => (
        <>
          <Space>
            <Tooltip title='Abrir'>
              <EyeOutlined
                onClick={() => handleClick(record)}
                style={{ color: "#ca2228", fontSize: 16 }}
                className='eyeIcon'
              />
            </Tooltip>
            {record.fechaSolicitudFactura !== "-" && (
              <Tooltip title='PDF'>
                <FilePdfOutlined
                  onClick={() => handlePDF(record)}
                  style={{ color: "#ca2228", fontSize: 14 }}
                  className='eyeIcon'
                />
              </Tooltip>
            )}
          </Space>
        </>
      ),
    },
  ];

  return { columns };
};
