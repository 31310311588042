import {
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { startGetAcesoBackOffice } from "../../../context/actions/accesoBackOfficeActions";
import { selectUsuario } from "../../../context/actions/usuariosActions";

////
export default function useColums() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (usuario) => {
    dispatch(selectUsuario(usuario));
    dispatch(startGetAcesoBackOffice());
  };

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Cabina",
      dataIndex: "esCabina",
      render: (text, record) => getIcon(text),
    },
    {
      title: "Admin",
      dataIndex: "esBackoffice",
      render: (text, record) => getIcon(text),
    },
    {
      title: "Cliente",
      dataIndex: "esCliente",
      render: (text, record) => getIcon(text),
    },
    {
      title: "Proveedor",
      dataIndex: "esProveedor",
      render: (text, record) => getIcon(text),
    },

    {
      title: "Creado",
      dataIndex: "fechaCreacion",
    },
    {
      title: "Ultimo login",
      dataIndex: "ultimoLogin",
    },

    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined
          onClick={() => handleClick(record)}
          style={{ color: "#ca2228" }}
          className="eyeIcon"
        />
      ),
    },
  ];

  return { columnas };
}

const getIcon = (boleano) => {
  if (boleano === true)
    return (
      <div style={{ textAlign: "center" }}>
        <CheckCircleOutlined style={{ color: "green" }} />
      </div>
    );
  return (
    <div style={{ textAlign: "center" }}>
      <CloseCircleOutlined style={{ color: "red" }} />
    </div>
  );
};
