export const fnDivideFeature = (feature) => {
  const { context, place_name_es } = feature;
  let salida = {
    direccionOrigen: place_name_es,
  };

  let municipioOrigen = "";
  let estadoOrigen = "";

  // locality solo en el CDMX
  const localities = context.filter((ele) => ele.id.includes("locality"));
  if (localities.length > 0) {
    municipioOrigen = localities[0].text;

    const estadosCDMX = context.filter((ele) => ele.id.includes("place"));
    estadosCDMX.length > 0
      ? (estadoOrigen = estadosCDMX[0].text)
      : (estadoOrigen = "");
  } else {
    // es afuera del cdmx
    const municipios = context.filter((ele) => ele.id.includes("place"));
    municipios.length > 0
      ? (municipioOrigen = municipios[0].text)
      : (municipioOrigen = "");

    const estados = context.filter((ele) => ele.id.includes("region"));
    estados.length > 0 ? (estadoOrigen = estados[0].text) : (estadoOrigen = "");
  }

  const codigos = context.filter((ele) => ele.id.includes("postcode"));
  codigos.length > 0
    ? (salida.cpOrigen = codigos[0].text)
    : (salida.cpOrigen = "");

  console.log(context);

  return { ...salida, estadoOrigen, municipioOrigen };
};
