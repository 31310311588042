import { CloudUploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Tooltip } from "antd";
import { CargaPagoModalBoton } from "./CargaPagoModalBoton";

export const CargaPagoBoton = ({ ordenPagoID, setPagoCargado }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Tooltip title="Cargar Pago" placement="bottom" color="#1890FF">
        <CloudUploadOutlined
         className="eyeIcon"
         style={{ color: "#ca2228", fontSize: 16, marginLeft: 10 }}
          onClick={() => setShowModal(true)}
        />
      </Tooltip>

      <CargaPagoModalBoton
        ordenPagoID={ordenPagoID}
        setPagoCargado={setPagoCargado}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};
