import { useState } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
//
import CardPage from "../../../../layout/CardPage";
import Info from "./Info";
import FormaCliente from "../../formaCliente/FormaCliente";
import { startPostCliente } from "../../../../context/actions/clientesActions";
import { useDispatch } from "react-redux";

export default function InfoCliente() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleRegresar = () => history.push("/clientes");

  const handleActualizar = () => {
    dispatch(startPostCliente());
    setOpenModal(false);
  };

  return (
    <CardPage
      titulo="Información de Cliente"
      tituloBoton="Regresar"
      accionBoton={handleRegresar}
      tituloBoton2="Editar"
      accionBoton2={() => setOpenModal(true)}
    >
      <Info />
      <Modal
        open={openModal}
        onOk={handleActualizar}
        onCancel={() => setOpenModal(false)}
        title="Editar Cliente"
      >
        <FormaCliente />
      </Modal>
    </CardPage>
  );
}
