import { Input } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
    startChangeCosto,
    startChangeProveedor,
} from "../../../../context/actions/asistenciasActions";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import ModelPage from "../../../../layout/ModelPage";
import { correosCabiarCosto } from "../../../../constants/cons";

export const CambioCosto = () => {
    const {costo} = useSelector((state) => state.asistenciasReducer.asistencia);
    const email = useSelector((state) => state.userReducer.user.email);
   
    const {asistenciaID} = useParams();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [costoNuevo, setCostoNuevo] = useState(costo);
    const costoMayorRol = useSelector(state => state.userReducer.user.Backoffice_CostoMayor)
    const handleOk = () => {
        const esMenor = costoNuevo < costo


        const body = {
            asistenciaID,
            costo: Number(costoNuevo),
        };

        if (esMenor) {
            if (costoNuevo && costoNuevo >= 0) dispatch(startChangeCosto(body));
        } else {
            if (costoNuevo && costoNuevo >= 0 && (correosCabiarCosto.includes(email) ||
                costoMayorRol)) dispatch(startChangeCosto(body));
        }
        setOpenModal(false);
    };


    return (
        <>
            <BotonDivL
                tituloBoton='Cambiar Costo'
                onClick={() => setOpenModal(true)}
            />
            <ModelPage
                titulo='Cambiar Costo'
                handleOk={handleOk}
                handleCancel={() => setOpenModal(false)}
                isVisible={openModal}
            >
                <Input
                    value={costoNuevo}
                    onChange={({target}) => setCostoNuevo(target.value)}
                />
            </ModelPage>
        </>
    );
};
