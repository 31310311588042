import { useEffect, useState } from "react"
import { useSelector } from "react-redux"


export const useClientes = () => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const [clientes, setClientes] = useState([])
    useEffect(() => {
        var result = [];
        asistenciasMes.reduce((res, value) => {
            if (!res[value.clienteID]) {
                res[value.clienteID] = {
                    cliente: value.cliente, Id: value.clienteID, costo: 0, cantidad: 0
                };
                result.push(res[value.clienteID])
            }
            res[value.clienteID].costo += value.costo;
            res[value.clienteID].cantidad++;

            return res;
        }, {})

        result.sort((a, b) => {
            return b.costo - a.costo;
        });

        setClientes(result)

    }, [asistenciasMes])

    return { clientes }
}
