import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setServicioSelected } from "../../../../context/actions/serviciosActions";
import { startLoadCampos } from "../../../../context/actions/tiposAsistenciaActions";

export default function Tipo({ servicio }) {
  const dispatch = useDispatch();
  const { id } = useSelector(
    (state) => state.tiposAsistenciaReducer.servicioSelected
  );
  const handleClick = () => {
    dispatch(setServicioSelected(servicio))
    dispatch(startLoadCampos(servicio.id))
  };

  return (
    <div
      onClick={handleClick}
      className={`listaSelect ${id == servicio.id && "seleccionado"}`}
    >
      {servicio.nombre}
    </div>
  );
}
