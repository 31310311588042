import { Row, Col } from "antd";

export const ListaItemTitulo = () => {
  return (
    <Row>

      <Col span={2} className="tituloGrid">
        ID
      </Col>
      <Col span={2} className="tituloGrid">
        Fecha
      </Col>
      <Col span={3} className="tituloGrid">
        Cliente
      </Col>
      <Col span={4} className="tituloGrid">
        Campaña
      </Col>
      <Col span={4} className="tituloGrid">
        Servicio
      </Col>
      <Col span={2} className="tituloGrid">
        Estatus
      </Col>
      <Col span={5} className="tituloGrid">
        Ubicacion
      </Col>
      <Col span={2} className="tituloGrid">
        Costo
      </Col>
    </Row>
  );
};
