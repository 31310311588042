import { Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { startGetAsistencias } from "../../context/actions/asistenciasActions";
import CardPage from "../../layout/CardPage";
import { Controles } from "./Controles";
import { useColumns } from "./useColumns";
import { useFiltroAsistencias } from "./useFiltroAsistencias";

export const Asistencias = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { columns } = useColumns();
  const { listaFinal, filter } = useFiltroAsistencias();
  const {asistencias} = useSelector(state => state.asistenciasReducer)
  const [listaAsistencias, setListaAsistencias] = useState([]);

  useEffect(() => {
    dispatch(startGetAsistencias());
  }, []);

  useEffect(() => {
    setListaAsistencias(asistencias);
  }, [asistencias])

  useEffect(() => {
    setListaAsistencias(listaFinal);
  }, [listaFinal])

  return (
    <CardPage titulo="Asistencias">
      <Controles filter={filter} />
      <Table
        onRow={(record, rowIndex) => {

          return {
            onClick: () =>
              history.push(
                `/asistencias/editarAsistencia/${record.asistenciaID}`
              ),
          };
        }}
        size="small"
        rowClassName="tabalAsistencia"
        rowKey="asistenciaID"
        dataSource={listaAsistencias}
        columns={columns}
      />
    </CardPage>
  );
};
