import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { startLogoff } from "../context/actions/loginActions";
import {
  CarOutlined,
  CrownOutlined,
  DollarCircleOutlined,
  ForkOutlined,
  LineChartOutlined,
  PhoneOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  TeamOutlined,
} from "@ant-design/icons";

////
export default function MenuLayout() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {user} = useSelector((state) => state.userReducer);

  if (!user) return <div>...</div>;
  const {
    Backoffice_Asistencias,
    Backoffice_Clientes,
    Backoffice_Proveedores,
    Backoffice_TiposAsistencia,
    Backoffice_Pagos,
    Backoffice_Usuarios,
    Backoffice_Reportes,
    Backoffice_Calidad
  } = user;

  const handleClick = ({key}) => {
    if (key !== "/logoff") history.push(key);
    else dispatch(startLogoff());
  };

  return (
    <Menu
      onClick={handleClick}
      style={{marginTop: 10}}
      theme='light'
      defaultSelectedKeys={["0"]}
      mode='inline'
      
    >
      {Backoffice_Asistencias && (
        <>
          <Menu.Item key='/asistencias' icon={<PhoneOutlined/>}>
            Asistencias
          </Menu.Item>
          {/* <Menu.Item key="/asignacion" icon={<LoginOutlined />}>
            Asignación
          </Menu.Item> */}
        </>
      )}

      {
        Backoffice_Clientes && (
        <Menu.Item key='/clientes' icon={<CrownOutlined/>}>
          Clientes
        </Menu.Item>
      )
      }

      {Backoffice_Proveedores && (
        <Menu.Item key='/proveedores' icon={<CarOutlined/>}>
          Proveedores
        </Menu.Item>
      )}

      {Backoffice_TiposAsistencia && (
        <>
          <Menu.Item key='/tiposAsistencia' icon={<ForkOutlined/>}>
            Tipos asistencia
          </Menu.Item>

          <Menu.Item key='/listas' icon={<ProfileOutlined/>}>
            Listas
          </Menu.Item>
        </>
      )}

      {Backoffice_Pagos && (
        <Menu.Item key='/ordenesPago' icon={<DollarCircleOutlined/>}>
          Pagos
        </Menu.Item>
      )}


      {!Backoffice_Reportes && (
        <Menu.Item key='/reportes' icon={<LineChartOutlined/>}>
          Reportes
        </Menu.Item>
      )}


      {Backoffice_Calidad && (
        <Menu.Item key='/calidad' icon={<DollarCircleOutlined/>}>
          Calidad
        </Menu.Item>
      )}


      {Backoffice_Usuarios && (
        <Menu.Item key='/usuarios' icon={<TeamOutlined/>}>
          Usuarios
        </Menu.Item>
      )}

      <Menu.Item key='/logoff' icon={<PoweroffOutlined/>}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );
}
