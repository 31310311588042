import React from "react";

export const Cuadro = ({ titulo, cantidad, monto, backgroundColor }) => {
  return (
    <div className="cuadro__contenedor" style={{ backgroundColor }}>
      <div className="cuadro__titulo">{titulo}</div>
      <div className="cuadro__titulo">{cantidad}</div>
      <div className="cuadro__titulo">{monto}</div>
    </div>
  );
};
