import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LabelText from "../../../../components/input/LabelText";
import {
  cleanCampaniaForm,
  setValueCampania,
} from "../../../../context/actions/campaniasActions";
import LabelDateTime from "../../../../components/input/LabelDateTime";
import LabelNumber from "../../../../components/input/LabelNumber";
import { LabelTelefono } from "../../../../components/input/LabelTelefono";

//////////
export default function FormaCampania() {
  const dispatch = useDispatch();
  const {
    nombre,
    abreviatura,
    afiliadosProyectados,
    fechaInicio,
    fechaVencimiento,

    contactoComercial,
    emailComercial,
    telefonoComercial,
    tipoTelefonoComercial,

    contactoOperativo,
    emailOperativo,
    telefonoOperativo,
    tipoTelefonoOperativo,
  } = useSelector((state) => state.campaniasReducer.campania);

  const handleChange = ({ target }) => {
    dispatch(setValueCampania(target));
  };

  const handleChangeTipo = (value, name) => {
    const target = {
      name,
      value,
    };
    dispatch(setValueCampania(target));
  };


  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelText
            label="Nombre de campaña"
            name="nombre"
            onChange={handleChange}
            value={nombre}
          />
        </Col>
        <Col span={12}>
          <LabelText
            label="Prefijo de campaña"
            name="abreviatura"
            onChange={handleChange}
            value={abreviatura}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelDateTime
            label="Fecha inicio"
            name="fechaInicio"
            onChange={handleChange}
            value={fechaInicio}
          />
        </Col>
        <Col span={12}>
          <LabelDateTime
            label="Fecha vencimiento"
            name="fechaVencimiento"
            onChange={handleChange}
            value={fechaVencimiento}
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            label="Afiliados proyectados"
            name="afiliadosProyectados"
            onChange={handleChange}
            value={afiliadosProyectados}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelText
            label="Contacto Comercial"
            name="contactoComercial"
            onChange={handleChange}
            value={contactoComercial}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelText
            label="Email Comercial"
            name="emailComercial"
            onChange={handleChange}
            value={emailComercial}
          />
        </Col>
        <Col span={12}>
          <LabelTelefono
            onChangeTipo={(val) =>
              handleChangeTipo(val, "tipoTelefonoComercial")
            }
            onChange={handleChange}
            name="telefonoComercial"
            label="Teléfono 1"
            value={telefonoComercial}
            valueTipo={tipoTelefonoComercial}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelText
            label="Contacto operativo"
            name="contactoOperativo"
            onChange={handleChange}
            value={contactoOperativo}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelText
            label="Email operativo"
            name="emailOperativo"
            onChange={handleChange}
            value={emailOperativo}
          />
        </Col>
        <Col span={12}>
          <LabelTelefono
            onChangeTipo={(val) =>
              handleChangeTipo(val, "tipoTelefonoOperativo")
            }
            onChange={handleChange}
            name="telefonoOperativo"
            label="Teléfono operativo"
            value={telefonoOperativo}
            valueTipo={tipoTelefonoOperativo}
          />
        </Col>
      </Row>
    </>
  );
}
