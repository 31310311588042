import { Select } from "antd";
import React from "react";
import { getYearNow } from "../../utils/getYearNow";

const { Option } = Select;

export const SelectAnios = ({ value, onChange }) => {
  const currentYear = getYearNow();
  let anios = [];
  for (let i = 2022; i <= currentYear; i++) {
    anios = [...anios, i];
  }

  return (
    <Select value={value} onChange={onChange} style={{ width: "100%" }}>
      {anios.map((a, i) => (
        <Option key={i} value={a}>
          {a}
        </Option>
      ))}
    </Select>
  );
};
