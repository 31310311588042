import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startGetCampania } from "../../../context/actions/campaniasActions";
import { startGetCliente } from "../../../context/actions/clientesActions";
import { SeccionInfoCampania } from "./SeccionInfoCampania";
import { SeccionTiposAsistencia } from "./SeccionTiposAsistencia";

/////////////////
export default function DetalleCampania() {
  const [nombreCampania, setNombreCampania] = useState("");
  const { clienteID, campaniaID } = useParams();

  const { cliente } = useSelector((state) => state.clientesReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetCampania(campaniaID));
    dispatch(startGetCliente(clienteID));
  }, []);

  useEffect(() => {
    if (cliente.campanias)
      if (cliente.campanias.length > 0) {
        cliente.campanias.map((a) => {
          if (a.campaniaID == campaniaID) setNombreCampania(a.nombre);
        });
      }
  }, [cliente]);

  /////////////////
  return (
    <>
      <SeccionInfoCampania nombreCampania={nombreCampania} />
      <SeccionTiposAsistencia />
    </>
  );
}
