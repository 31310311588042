import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSucursalSelected } from "../../../../context/actions/asistenciasActions";

export const SucursalesItem = ({
  sucursal: { proveedorSucursalID, nombre },
}) => {
  ////////////////////////////
  const dispatch = useDispatch();

  const { sucursalSelected } = useSelector((state) => state.asistenciasReducer);

  const handleClick = () => {
    dispatch(setSucursalSelected(proveedorSucursalID));
  };

  return (
    <div
      onClick={handleClick}
      className={`proveedorItem ${
        proveedorSucursalID === sucursalSelected && "proveedorSelected"
      }`}
    >
      {nombre}
    </div>
  );
};
