import { Form, Input, Select } from "antd";
import { URL_BASE_CALIDAD } from "../../constants/url";
import { useState } from "react";
import axios from "axios";


function debonce(func, timeout = 2000) {
  let timer;

  return (...arg) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, arg)
    }, timeout)
  }
}

export default function FormularioBusquedaCalida({setData}) {
  const [tipoBusqueda, setTipoBusqueda] = useState();

  async function onChangeInput({target}) {

    const data = await axios.post(`${URL_BASE_CALIDAD}/GetServicios/`,
      {'tipoBusqueda': 2, 'busqueda': target.value});

    setData(data.data);
  }

  const change = debonce(onChangeInput);

  return (
    <Form layout="inline">
      <Form.Item>
        <div className="inputLabel">Busqueda</div>

        <Form.Item name="busqueda">
          <Input placeholder="Buscar" onChange={change} allowClear />
        </Form.Item>
      </Form.Item>
    </Form>
  );
}
