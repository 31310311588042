import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import LabelText from "../../../../components/input/LabelText";
import {
  changeServicio,
  startPutServicio,
} from "../../../../context/actions/serviciosActions";
import LabelSelectSiNo from "../../../../components/select/LabelSelectSiNo";

export default function DatosServicio() {
  const dispatch = useDispatch();
  const { tipoAsistenciaID } = useParams();

  const { nombre, requiereDestino, x1, x2, x3, x4, claveSAT, id } = useSelector(
    (state) => state.tiposAsistenciaReducer.servicioSelected
  );
  const handleChange = ({ target }) =>
    dispatch(changeServicio({ [target.name]: target.value }));

  const handleSelect = (val) =>
    dispatch(changeServicio({ requiereDestino: val }));
  //
  const handleClick = () => dispatch(startPutServicio(tipoAsistenciaID));

  //////////////
  return (
    <>
      <div className="subtituloMenos">Datos del Servicio id: {id}</div>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelText
            name="nombre"
            value={nombre}
            label="Nombre"
            onChange={handleChange}
          />
        </Col>
        <Col span={6}>
          <LabelSelectSiNo
            label="Requiere destino"
            name="requiereDestino"
            value={requiereDestino}
            onChange={handleSelect}
          />
        </Col>
        <Col span={6}>
          <LabelText
            label="Clave SAT"
            name="claveSAT"
            value={claveSAT}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={4}>
          <LabelText name="x1" value={x1} label="X1" onChange={handleChange} />
        </Col>
        <Col span={4}>
          <LabelText name="x2" value={x2} label="X2" onChange={handleChange} />
        </Col>
        <Col span={4}>
          <LabelText name="x3" value={x3} label="X3" onChange={handleChange} />
        </Col>
        <Col span={4}>
          <LabelText name="x4" value={x4} label="X4" onChange={handleChange} />
        </Col>
      </Row>

      <div className="contenedorBotonSubmit" style={{ maxWidth: 200 }}>
        <BotonSubmit onClick={handleClick} tituloBoton="Guardar" />
      </div>
    </>
  );
}
