import { Alert } from "antd";
import { useSelector } from "react-redux";

export const EstatusOrdenPago = () => {
  const { estatusOrdenPagoID } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );

  let texto = "La orden de pago no ha sido solicitada";
  let type = "info";

  if (estatusOrdenPagoID === 1) {
    texto = "Orden creada, por solicitar factura a proveedor";
    type = "warning";
  }

  if (estatusOrdenPagoID === 2) {
    texto = "Factura solicitada a proveedor, en espera de documento";
    type = "warning";
  }

  if (estatusOrdenPagoID === 3) {
    texto = "Factura de proveedor cargada, en espera solicitud de pago";
    type = "warning";
  }

  if (estatusOrdenPagoID === 4) {
    texto = "Orden de pago solicitada, en espera de autorización";
    type = "warning";
  }

  if (estatusOrdenPagoID === 5) {
    texto = "Orden de pago autorizada, en espera de pago";
    type = "warning";
  }

  if (estatusOrdenPagoID === 6) {
    texto = "Orden pagada, en espera de complemento de pago";
    type = "warning";
  }

  if (estatusOrdenPagoID === 7) {
    texto = "Pago efectuado";
    type = "success";
  }

  return (
    <Alert
      style={{ marginTop: 15, marginBottom: 15 }}
      message={texto}
      type={type}
    />
  );
};
