import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import LabelValor from "../../../../../components/label/LabelValor";
import { AutorizacionPago } from "./autorizacionPago/AutorizacionPago";
import { CargaFactura } from "./cargaFactura/CargaFactura";
import { CargaPago } from "./cargaPago/CargaPago";
import { CargaComplementoPago } from "./cargaComplementoPago/CargaComplementoPago";
import { FechaCreacion } from "./fechaCreacion/FechaCreacion";
import { SolicitudFactura } from "./solicitudFactura/SolicitudFactura";
import { ConfirmarFactura } from "./confirmarFactura/ConfirmarFactura";
import ButtonPagar from "../../../ButtonPagar";
import { dateFromString } from "../../../../../utils/dateFromString";
import { useState } from "react";

export const Acciones = () => {
  const { proveedor, clienteNombre, ordenPagoID, estaPagado, fechaPagado, nombreUsuarioPagado, estatusOrdenPagoID } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );

  const [pagado, setPagado] = useState(estaPagado);

  return (
    <div style={{ marginTop: 25 }}>
      <Row gutter={[10, 10]} style={{marginBottom: 10}}>
        <Col span={12} offset={12}>
          {
            estaPagado || pagado ?
              <LabelValor label="Pagado" valor={`${dateFromString(fechaPagado).soloFecha || ''} - ${nombreUsuarioPagado ?? ''}`} />
                :
              estatusOrdenPagoID === 5 ?
                <>
                  <div className="label">Pagado</div>
                  <ButtonPagar model={{ordenPagoID}} setPagado={setPagado} />
                </>
              : null
          }
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelValor label="Proveedor" valor={proveedor} />
        </Col>
        <Col span={12}>
          <LabelValor label="Cliente" valor={clienteNombre} />
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <FechaCreacion />
        </Col>
        <Col span={12}>
          <SolicitudFactura />
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <CargaFactura />
        </Col>
        <Col span={12}>
          <ConfirmarFactura />
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <AutorizacionPago />
        </Col>
        <Col span={12}>
          <CargaPago />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <CargaComplementoPago />
        </Col>
      </Row>
    </div>
  );
};
