import { types } from "../types/types";

export const setLoadingCampaniaServicios = (payload) => ({
  type: types.setLoadingCampaniaServicios,
  payload,
});

export const setLoadingCampos = (payload) => ({
  type: types.setLoadingCampos,
  payload,
});
