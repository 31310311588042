import { CuerpoMenu } from "./cuerpoFolder/CuerpoMenu";
import { CuerpoFolder } from "./cuerpoFolder/CuerpoFolder";

export const Cuerpo = () => {
  return (
    <>
      <CuerpoMenu />
      <CuerpoFolder />
    </>
  );
};
