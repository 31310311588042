import React from "react";
import { useSelector } from "react-redux";
import { LocalCarreteroItem } from "./_LocalCarreteroItem";

export const LocalCarretero = () => {
  const [open, setOpen] = React.useState(true);
  const {
    asistenciasDia: { localCarretero },
  } = useSelector((state) => state.reportesReducer);

  return (
    <div>
      <div
        onClick={() => setOpen(!open)}
        className="tituloCabeceraRound"
        style={{ fontSize: 16, fontWeight: 600, cursor: "pointer" }}
      >
        Local Carretero
      </div>
      <div style={{ display: open ? "" : "none" }}>
        {localCarretero && (
          <div
            style={{
              paddingRight: 4,
            
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {localCarretero.map((localCarretero, i) => (
              <LocalCarreteroItem key={i} localCarretero={localCarretero} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
