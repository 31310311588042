import React from 'react'
import { useSelector } from 'react-redux'
import { LocalCarreteroItem } from './LocalCarreteroItem'
import { localCarreteroClienteReduce } from './localCarreteroClienteReduce'


export const LocalCarretero = ({ cliente }) => {
    const { asistenciasMes } = useSelector(state => state.reportesReducer)
    const listado = localCarreteroClienteReduce(asistenciasMes, cliente.Id)

    return (
        <div style={{  padding: 5, borderBottom: '1px solid #ccc' }}>
            {listado.map((reg, i) => <LocalCarreteroItem reg={reg} key={i} i={i} />)}
        </div>
    )
}
