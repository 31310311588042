import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import { startChangeServicio } from "../../../../context/actions/asistenciasActions";
import ModelPage from "../../../../layout/ModelPage";
import { Forma } from "./Forma";

export const CambioServicio = () => {
  const { asistenciaID } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [servicioID, setServicioID] = useState(0);

  const handleOk = () => {
    const body = {
      asistenciaID,
      servicioID,
    };

    if (servicioID !== 0) dispatch(startChangeServicio(body));
    setOpenModal(false);
  };
  return (
    <>
      <BotonDivL
        tituloBoton="Cambiar Servicio"
        onClick={() => setOpenModal(true)}
      />
      <ModelPage
        titulo="Cambiar Servicio"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <Forma onChange={(val) => setServicioID(val)} />
      </ModelPage>
    </>
  );
};
