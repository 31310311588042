import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonDivLeft2 from "../../../../components/Boton/BotonDivLeft2";
import LabelSelect from "../../../../components/select/LabelSelect";
import { mesesOptions } from "../../../../constants/cons";
import { setFiltroReporte, startGetAsistenciasMes } from "../../../../context/actions/reportesActions";
import { aniosOptions } from "../../../../utils/aniosOptions";
import { getYearNow } from "../../../../utils/getYearNow";

export const Controles = () => {
  const dispatch = useDispatch();
  const [anios, setAnios] = useState([]);
  const { mes, anio } = useSelector(state => state.reportesReducer.filtroReporte)

  useEffect(() => {
    const a = aniosOptions(2022, getYearNow());
    setAnios(a);
  }, []);

  const handleClick = () => dispatch(startGetAsistenciasMes());

  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={4}>
        <LabelSelect
          value={mes}
          onChange={(value) => dispatch(setFiltroReporte({ mes: value }))}
          opciones={mesesOptions}
        />
      </Col>
      <Col span={4}>
        <LabelSelect
          value={anio}
          onChange={(value) => dispatch(setFiltroReporte({ anio: value }))}
          opciones={anios}
        />
      </Col>
      <Col span={2} style={{ paddingTop: 3 }}>
        <BotonDivLeft2 onClick={handleClick} tituloBoton="Filtrar" />
      </Col>
    </Row>
  );
};
