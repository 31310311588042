import { types } from "../types/types";

const init = {
  asistenciasAsignables: [],
  loadingAsistencias: false,
  loadingProveedores: false,
  proveedoresServicio: [],
};
export const asignacionReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeProveedoresServicio:
      return {
        ...state,
        proveedoresServicio: action.payload,
      };

    case types.setLoadingProveedores:
      return {
        ...state,
        loadingProveedores: action.payload,
      };

    case types.setLoadingAsistencias:
      return {
        ...state,
        loadingAsistencias: action.payload,
      };

    case types.storeAsistenciasAsignables:
      return {
        ...state,
        asistenciasAsignables: action.payload,
      };

    default:
      return state;
  }
};
