import React from "react";
import { useSelector } from "react-redux";
import Item from "./Item";

export default function ListItems() {
  const { clienteFiles } = useSelector((state) => state.campaniasReducer);
  return (
    <div>
      {clienteFiles.map((f, i) => (
        <Item key={i} file={f} />
      ))}
    </div>
  );
}
