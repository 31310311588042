import { notificationInitial } from "../initial/notificationInitial";
import { types } from "../types/types";

export const notificationReducer = (state = notificationInitial, action) => {
  switch (action.type) {
    case types.storeVersionBackend:
      return {
        ...state,
        versionBackend: action.payload,
      };
    case types.notification:
      return {
        ...state,
        notification: action.payload,
      };

    default:
      return state;
  }
};
