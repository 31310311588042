import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LabelSelect from "../../../../components/select/LabelSelect";
import {
  estatusCanceladosOptions,
  estatusOptions,
} from "../../../../constants/cons";
import { setEstatusFinalSelected } from "../../../../context/actions/asistenciasActions";

export const Forma = () => {
  const dispatch = useDispatch();
  const { estatusID, estatusFinalID, estatus, estatusFinal } = useSelector(
    (state) => state.asistenciasReducer.asistencia
  );

  let opciones = [];

/*
  if (estatusID === 6) {
    opciones = estatusOptions;
  } else if (estatusFinalID === 4 || estatusFinalID === 5) {
    opciones = estatusCanceladosOptions;
  }
*/

  if(estatusFinalID > 1 ){
    opciones = estatusOptions;
  }
  const onChange = (val) => dispatch(setEstatusFinalSelected(val));

  //////
  return (
    <div>
      <div>Estatus de servicio: {estatus.nombre}</div>
      <div style={{ marginBottom: 15 }}>
        Estatus conclusión: {estatusFinal.nombre}
      </div>
      <div style={{ maxWidth: 200 }}>
        <LabelSelect
          label="Nuevo estatus final"
          onChange={onChange}
          opciones={opciones}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <div className="small">
          Si el servicio esta concluido, se puede cambiar a cualquier estatus
          final
        </div>
        <div className="small">
          Si el servicio no ha sido concluido, deberá tener algun tipo de
          conclusión
        </div>
      </div>
    </div>
  );
};
