import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Col, Input, Popconfirm, Row } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { startPostEvaluarServicio } from '../../../../../context/actions/opServiciosActions'

export const Controles = ({ setOpen, servicioPorValidarID }) => {
  const disparch = useDispatch()

  const [comentarios, setComentarios] = React.useState()
  const [err, setErr] = React.useState(false)

  //
  const evaluar = (estatusServicioPorValidarID) => { // 3 autorizado, 4 rechazado
    if (estatusServicioPorValidarID === 4 && !comentarios) {
      setErr(true)
      return;
    }

    const body = {
      estatusServicioPorValidarID,
      servicioPorValidarID,
      comentarios

    }

    disparch(startPostEvaluarServicio(body))
    setErr(false)
    setOpen(false)
  }

  //
  return (
    <div style={{ marginTop: 30 }}>
      <Input.TextArea
        value={comentarios}
        onChange={({ target }) => setComentarios(target.value)}
        placeholder='Comentarios de la evaluación'
        style={{ width: '100%', border: err ? "2px solid #f5abab" : "" }} />
      <Row justify='center' style={{ marginTop: 10 }} gutter={10}>
        <Popconfirm
          title="Confirmar Autorizar Servicio"
          onConfirm={() => evaluar(3)}
          onCancel={() => { }}
          okText="Si"
          cancelText="No"
        >
          <Col span={8}><Button style={{ width: '100%' }} size='small' type='primary' >Autorizar</Button></Col>
        </Popconfirm>

        <Popconfirm
          title="Confirmar Rechazar Servicio"
          onConfirm={() => evaluar(4)}
          onCancel={() => { }}
          okText="Si"
          cancelText="No"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Col span={8}><Button style={{ width: '100%' }} size='small' type='primary' danger >Rechazar</Button></Col>
        </Popconfirm>
      </Row>
    </div>
  )
}
