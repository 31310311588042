import { URL_SELFSERVICE_SERVICIOS } from "../../constants/url"
import {
    composeErrorNotification,
    composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, postFile, postGetFile } from "../../utils/fetch";
import { notification } from "./notificationActions";
import { typesOpServicios } from "../types/typesOpServicios"
import { ACCION_EXITOSA } from "../../constants/cons";



export const startGetServiciosPorValidar = () => {
    return async (dispatch) => {

        const url = `${URL_SELFSERVICE_SERVICIOS}/GetServiciosPorValidar`
        const result = await get(url)
        if (result.code === 0)
            dispatch(storeServiciosPorValidar(result.data))
        else
            dispatch(
                notification(
                    composeErrorNotification(
                        "startGetServiciosPorValidar",
                        `${result.error}`
                    )
                )
            );

    }
}

const storeServiciosPorValidar = (data) => ({
    type: typesOpServicios.storeServiciosPorValidar,
    payload: data
})


//
export const startGetServicioPorValidar = (asistenciaID) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const url = `${URL_SELFSERVICE_SERVICIOS}/GetServicioPorValidar/${asistenciaID}`
        const result = await get(url)

        if (result.code === 0)
            dispatch(storeServicioPorValidar(result.data))
        else
            dispatch(
                notification(
                    composeErrorNotification(
                        "startGetServicioPorValidar",
                        `${result.error}`
                    )
                )
            );
        dispatch(setLoading(false))

    }
}

const storeServicioPorValidar = (data) => ({
    type: typesOpServicios.storeServicioPorValidar,
    payload: data
})

const setLoading = (loading) => ({
    type: typesOpServicios.setLoading,
    payload: loading
})



//
export const startPostEvaluarServicio = (body) => {
    return async (dispatch) => {
        const url = `${URL_SELFSERVICE_SERVICIOS}/PostEvaluarServicio`
        const result = await post(url, body)
        
        console.log(body)
        //
        if (result.code === 0) {
            dispatch(
                notification(
                    composeSuccessNotification(
                        ACCION_EXITOSA,
                        result.data
                    )
                )
            );
            dispatch(startGetServiciosPorValidar())
        }
        else
            dispatch(
                notification(
                    composeErrorNotification(
                        "startPostEvaluarServicio",
                        `${result.error}`
                    )
                )
            );
    }
}
