import { Col, Input } from "antd";
import React from "react";

export const InputLatLng = ({ handleLatLng, textoLatLng }) => {
  return (
    <Col xs={24} md={6} xl={4}>
      <Input
        style={{ borderRadius: 3 }}
        placeholder="lat,-lng"
        onChange={handleLatLng}
        value={textoLatLng}
      />
    </Col>
  );
};
