import { Button, Col, Row, Upload } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import BotonDiv from "../../../../components/Boton/BotonDiv";
import LabelText from "../../../../components/input/LabelText";
import LabelSelect from "../../../../components/select/LabelSelect";
import { URL_UPLOAD_FILE } from "../../../../constants/url";
import { postFile } from "../../../../utils/fetch";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startLoadDocumentos } from "../../../../context/actions/campaniasActions";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../../../utils/composeNotification";
import { notification } from "../../../../context/actions/notificationActions";
import CampaniasSelect from "../../../../components/select/CampaniasSelect";

export default function Create() {
  const dispatch = useDispatch();
  const [body, setBody] = useState({});
  const { clienteID } = useParams();

  //
  const handleChange = ({ target }) =>
    setBody({ ...body, [target.name]: target.value });

  const siNo = [
    { texto: "Si", valor: true },
    { texto: "No", valor: false },
  ];

  const camposEror = () => {
    let salida = false;
    if (
      estaVacioNull(body.nombre, "nombre") ||
      estaVacioNull(body.campaniaID, "campaniaID") ||
      estaVacioNull(body.esConfidencial, "esConfidencial") ||
      estaVacioNull(body.file, "file")
    )
      salida = true;

    console.log({ salida });
    return salida;
  };

  const estaVacioNull = (valor, campo) => {
    console.log(campo, valor);
    if (valor === "" || valor === null || valor === undefined) {
      return true;
    } else return false;
  };

  const handleClick = async () => {
    if (camposEror()) {
      dispatch(
        notification(composeErrorNotification("Error en forma - Faltan campos"))
      );
      return;
    }

    let formdata = new FormData();
    formdata.append("file", body.file);
    formdata.append("filename", "file");
    formdata.append("mimetype", "application/pdf");

    formdata.append("Nombre", body.nombre);
    formdata.append("CampaniaID", body.campaniaID);
    formdata.append("EsConfidencial", body.esConfidencial);

    const result = await postFile(URL_UPLOAD_FILE, formdata);
    console.log(result);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startLoadDocumentos(clienteID));
      setBody({});
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post documento",
            `${result.error}`
          )
        )
      );
    }
  };

  const props = {
    beforeUpload: (file) => {
      setBody({ ...body, file });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  return (
    <>
      <Row style={{ marginTop: 20 }} gutter={10}>
        <Col span={4}>
          <LabelText
            name="nombre"
            value={body.nombre}
            onChange={handleChange}
            label="Nombre"
          />
        </Col>
        <Col span={4}>
          <CampaniasSelect
            onChange={(value) =>
              handleChange({ target: { name: "campaniaID", value } })
            }
            value={body.campaniaID}
          />

          {/* <LabelSelect
            name="campaniaID"
            value={body.campaniaID}
            onChange={(value) =>
              handleChange({ target: { name: "campaniaID", value } })
            }
            label="Campaña"
            opciones={campanias}
          /> */}
        </Col>
        <Col span={4}>
          <LabelSelect
            name="esConfidencial"
            value={body.esConfidencial}
            onChange={(value) =>
              handleChange({ target: { name: "esConfidencial", value } })
            }
            label="Confidencial"
            opciones={siNo}
          />
        </Col>
        <Col span={4}>
          <Upload {...props} style={{ width: "100%" }}>
            <Button
              style={{ textAlign: "left", marginTop: 20 }}
              icon={<UploadOutlined />}
            >
              Seleccione documento
            </Button>
          </Upload>
        </Col>
        <Col span={4} style={{ marginTop: 20 }}>
          <BotonDiv tituloBoton="Agregar" onClick={handleClick} />
        </Col>
      </Row>
      <div style={{ minHeight: 5, marginTop: 10 }}>
        {" "}
        {body.file && body.file.name}
      </div>
    </>
  );
}
