import { Col, Row } from "antd";
import React from "react";

export const OrdenesPagoCabecera = () => {
  return (
    <Row gutter={10} className="tituloGrid">
      <Col span={2}>Orden ID</Col>
      <Col span={2}>Facturado</Col>
      <Col span={2}>Dias</Col>
      <Col span={5}>Razón Social</Col>
      <Col span={3}>Banco</Col>
      <Col span={2}>Cliente</Col>
      <Col span={2} style={{ textAlign: "center" }}>
        Servicios
      </Col>
      <Col span={2} style={{ textAlign: "center" }}>
        SubTotal
      </Col>
      <Col span={2} style={{ textAlign: "center" }}>
        Total
      </Col>
      <Col span={1}></Col>
    </Row>
  );
};
