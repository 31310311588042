import { Spin } from "antd";
import React from "react";

export default function Loading({ size }) {
  return (
    <div style={{ marginTop: 30, textAlign: "center" }}>
      <Spin size={size} />
    </div>
  );
}
