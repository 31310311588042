import { Spin } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelValor from "../../../../../../components/label/LabelValor";
import LabelValor2Link from "../../../../../../components/label/LabelValor2Link";
import LabelValorLink from "../../../../../../components/label/LabelValorLink";
import { startDownloadDocumentoOP } from "../../../../../../context/actions/ordenesPagoActions";
import { SolicitudFacturaModal } from "./SolicitudFacturaModal";

export const SolicitudFactura = () => {
  const dispatch = useDispatch();
  const { ordenPagoID } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { estatusOrdenPagoID, fechaSolicitudFactura, usuarioSolicitudFactura } =
    useSelector((state) => state.ordenesPagoReducer.ordenPago);
  const { enviandoProveedor } = useSelector(
    (state) => state.ordenesPagoReducer
  );

  const descargaFile = (tipo) =>
    dispatch(startDownloadDocumentoOP(ordenPagoID, tipo));

  /////
  if (enviandoProveedor)
    return (
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <Spin /> <span style={{ marginLeft: 10 }}>Enviando correo</span>
      </div>
    );
  /// boton para abril modal y poner email de proveedor
  if (estatusOrdenPagoID === 1)
    return (
      <>
        <BotonSubmit
          style={{ marginTop: 18 }}
          tituloBoton="Solicitar Factura a Proveedor"
          onClick={() => setShowModal(true)}
        />
        <SolicitudFacturaModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </>
    );

  // Damos oportunidad de solicitar de neuvo si no hay factura aun
  if (estatusOrdenPagoID === 2)
    return (
      <>
        <LabelValor2Link
          label="Solicitud de factura"
          valor={`${fechaSolicitudFactura} - ${usuarioSolicitudFactura}`}
          linkLabel=" ver OP "
          onClick={() => descargaFile(5)}
          linkLabel2=" reenviar "
          onClick2={() => setShowModal(true)}
        />
        <SolicitudFacturaModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </>
    );

  /// Ya tiene datos  y no se puede solitar de nuevo
  return (
    <LabelValorLink
      linkLabel=" ver orden "
      onClick={() => descargaFile(5)}
      label="Solicitud de factura"
      valor={`${fechaSolicitudFactura} - ${usuarioSolicitudFactura}`}
    />
  );
};
