import React from "react";
import { useSelector } from "react-redux";
import { SinDatos } from "../../../components/loading/SinDatos";
import { CampoItem } from "./CampoItem";

export const SeccionCamposListado = () => {
  const { listaCampos } = useSelector((state) => state.listasReducer);

  if (listaCampos.length === 0) return <SinDatos />;
  return (
    <div style={{ marginTop: 10 }}>
      {listaCampos.map((c, i) => (
        <CampoItem key={i} item={c} />
      ))}
    </div>
  );
};
