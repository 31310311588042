import { Col, Divider, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import { ProveedoresSelect } from "../../../../components/select/ProveedoresSelect";
import { changeUsuarioForm, startEstableceProveedorID } from "../../../../context/actions/usuariosActions";

export const UsuarioProveedor = () => {
  const dispatch = useDispatch();
  const { esProveedor, proveedorID } = useSelector(
    (state) => state.usuariosReducer.usuario
  );

  if (esProveedor !== true) return <></>;

  const handleChange = (val) =>
    dispatch(changeUsuarioForm({ proveedorID: val }));

  const handleAplicar = () => dispatch(startEstableceProveedorID());

  ///
  return (
    <>
      <Divider />

      <Row gutter={10}>
        <Col span={16}>
          <ProveedoresSelect onChange={handleChange} value={proveedorID} />
        </Col>
        <Col span={6}>
          <BotonDivL onClick={handleAplicar} tituloBoton="Aplicar" />
        </Col>
      </Row>
    </>
  );
};
