import { Col, Row } from 'antd'
import React from 'react'
import { formatCurrency } from '../../../../utils/formatCurrency'

export const CampaniaItem = ({ reg }) => {
    console.log(reg)
    return (
        <Row gutter={10} className="rowItemElementoSin" >
            <Col span={1}></Col>
            <Col span={8}>{reg.campania}</Col>
            <Col span={2} style={{ textAlign: 'center' }}>{reg.cantidad}</Col>
            <Col span={4} style={{ textAlign: 'right' }}>{formatCurrency(reg.costo)}</Col>
            <Col span={4} style={{ textAlign: 'right' }}>{formatCurrency(reg.costo / reg.cantidad)}</Col>
        </Row>
    )
}
