import { DeleteOutlined } from "@ant-design/icons";

export default function BotonEliminar({ onClick }) {
  return (
    <DeleteOutlined
      style={{ color: "red" }}
      className="botonEliminar"
      onClick={onClick}
    />
  );
}
