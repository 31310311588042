
export const estatusProveedorReduce = (asistencias, proveedorID) => {

    var array = asistencias.filter(a => a.proveedorID === proveedorID)
    var result = [];
    array.reduce((res, value) => {
        if (!res[value.estatusFinalID]) {
            res[value.estatusFinalID] = { estatusFinal: value.estatusFinal, costo: 0, cantidad: 0 };
            result.push(res[value.estatusFinalID])
        }
        res[value.estatusFinalID].costo += value.costo;
        res[value.estatusFinalID].cantidad++;

        return res;
    }, {})
    return result
}
