import { useEffect, useState } from "react";
import axios from "axios";
import { URL_BASE, ver } from "../constants/url";
import { useDispatch, useSelector } from "react-redux";
import { setModalVersionShow, setValidVersion } from "../context/actions/appActions";
import { Button, Modal } from "antd";

export const ValidateVersion = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)


  const showModal = useSelector(state => state.appReducer.showModal)

  const validVersion = useSelector(state => state.appReducer.isValidVersion)
  useEffect(() => {
    axios.get(`${URL_BASE}/api/ver/GetFrontAdminVer`).then(res => {
      if (res.data !== ver) {
        dispatch(setValidVersion(false))
        setOpen(showModal === 'show')
      }
    })
  }, []);


  const onOk = () => {
    window.location.reload()
  }

  const onCancel = () => {
    dispatch(setModalVersionShow(false))
    setOpen(false)
  }
  return (
    <>
      <Modal onCancel={() => setOpen(false)} title={'Actualizar versión'} open={open} footer={null}>
        <p>Existe una nueva versión de SIGSA-ADMIN</p>
        <p>¿ Desea actualizar ?  </p>
        <div>
          <Button onClick={onCancel} type="primary" danger>No</Button>
          <Button onClick={onOk} type="primary" style={{marginRight: '20px'}}>Si</Button>
        </div>
      </Modal>
    </>
  )
}