import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BotonDiv from "../../../../../components/Boton/BotonDiv";
import { cleanFormaSucursal, startPostSucursal } from "../../../../../context/actions/sucursalesActions";
import ModelPage from "../../../../../layout/ModelPage";
import FormaSucursal from "./formaSucursal/FormaSucursal";
import Tabla from "./tabla/Tabla";

export default function Sucursales() {
  const { proveedorID } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const handleOk = () => {
    dispatch(startPostSucursal(proveedorID));
    setOpenModal(false);
  };

  const handleAgregar = () => {
    dispatch(cleanFormaSucursal())
    setOpenModal(true)
  }
  return (
    <>
      <BotonDiv tituloBoton="Agregar" onClick={handleAgregar} />
      <Tabla />
      <ModelPage
        titulo="Agregar Sucursal"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
        isVisible={openModal}
      >
        <FormaSucursal />
      </ModelPage>
    </>
  );
}
