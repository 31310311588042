import React from "react";
import { useSelector } from "react-redux";
import { EstatusItem } from "./_EstatusItem";
import { LocalCarretero } from "../localCarretero/LocalCarretero";

export const Estatus = () => {
  const [open, setOpen] = React.useState(true);
  const {
    asistenciasDia: { estatus },
  } = useSelector((state) => state.reportesReducer);

  return (
    <div>
      <div
        onClick={() => setOpen(!open)}
        className="tituloCabeceraRound"
        style={{ fontSize: 16, fontWeight: 600, cursor: "pointer" }}
      >
        Estatus
      </div>
      <div style={{ display: open ? "" : "none" }}>
        {estatus && (
          <div
            style={{
              paddingRight: 4,
              height: 240,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {estatus.map((estatus, i) => (
              <EstatusItem key={i} estatus={estatus} />
            ))}

           <div style={{marginTop: 20}}> <LocalCarretero /></div>
          </div>
        )}
      </div>
    </div>
  );
};
