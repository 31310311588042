import { useDispatch } from "react-redux";
import BotonSubmit from "../../../../../components/Boton/BotonSubmit";
import { startPostInfoPago } from "../../../../../context/actions/proveedoresActions";

export default function Enviar() {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(startPostInfoPago());
  return (
    <div className="contenedorBotonSubmit" style={{ maxWidth: 250 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Actualizar información" />
    </div>
  );
}
