import FormCobertura from "../../../../../components/formas/FormCobertura";

const Cobertura = () => {

  return (
    <>
      <div
        style={{ padding: '20px', marginBottom: '20px' }}
      >
        <h3 style={{fontWeight: "bold", fontSize: "20px", textAlign: "center"}}>Auto-Asignación</h3>
      </div>
        <FormCobertura />
    </>
  );
};

export default Cobertura;
