import { types } from "../types/types";

export const setLoginErr = (payload) => ({
  type: types.setLoginErr,
  payload,
});

export const setRegisterDeviceErr = (payload) => ({
  type: types.setRegisterDeviceErr,
  payload,
});
