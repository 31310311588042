import { Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import LabelText from "../../../components/input/LabelText";
import { startBuscaAsistenciaID } from "../../../context/actions/ordenPagoBuscaActions";

export const BuscaAsistenciaID = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [asistenciaID, setAsistenciaID] = useState();

  const handleClick = () => {
    dispatch(startBuscaAsistenciaID(asistenciaID)).then((respuesta) => {
      if (respuesta.ok)
        history.push(`/ordenesPago/detalle/${respuesta.ordenPagoID}`);
    });
  };

  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={6}>
        <LabelText
          label="Asistencia ID"
          value={asistenciaID}
          onChange={({ target }) => setAsistenciaID(target.value)}
        />
      </Col>
      <Col span={6} style={{ paddingTop: 22 }}>
        <BotonSubmit onClick={handleClick} tituloBoton="Buscar AsistenciaID" />
      </Col>
    </Row>
  );
};
