import { Input, Table } from "antd";
import { useHistory } from "react-router";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";

////
export default function Tabla() {
  const history = useHistory();
  const { columnas } = useColums();
  const datos = useSelector((state) => state.usuariosReducer.usuarios);

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(datos);
  }, [datos]);

  ///

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = datos.filter(
        (a) =>
          (a.nombre &&
            a.nombre.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.email &&
            a.email.toLowerCase().includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      setListaFinal(datos);
    }
  };

  ////
  return (
    <>
      <Input
        onChange={filter}
        placeholder="buscar"
        style={{ width: 300, marginBottom: 10 }}
        size="small"
      />
      <Table
        size="small"
        rowKey="usuarioID"
        columns={columnas}
        dataSource={listaFinal}
      />
    </>
  );
}
