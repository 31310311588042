import { Alert, Col, Row } from "antd";
import { useSelector } from "react-redux";
import LabelValor from "../../../../components/label/LabelValor";
import { formatDate } from "../../../../utils/formatDate";
import { EstatusCampania } from "./EstatusCampania";

export const InfoCampania = () => {
  const {
    nombre,
    abreviatura,
    fechaInicio,
    fechaVencimiento,
    afiliadosProyectados,
    contactoComercial,
    emailComercial,
    telefonoComercial,
    contactoOperativo,
    telefonoOperativo,
    emailOperativo,
    tipoTelefonoComercial,
    tipoTelefonoOperativo,
  } = useSelector((state) => state.campaniasReducer.campania);

  return (
    <>
      <EstatusCampania />
      <Row gutter={[10, 10]} style={{ marginBottom: 10, marginTop: 10 }}>
        <Col span={6}>
          <LabelValor label="Nombre campaña" valor={nombre} />
        </Col>
        <Col span={6}>
          <LabelValor label="Prefijo de campaña" valor={abreviatura} />
        </Col>
        <Col span={6}>
          <LabelValor
            label="Afiliados proyectados"
            valor={afiliadosProyectados}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelValor label="Fecha de inicio" valor={formatDate(fechaInicio)} />
        </Col>
        <Col span={6}>
          <LabelValor
            label="Fecha de vencimiento"
            valor={formatDate(fechaVencimiento)}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelValor label="Contacto comercial" valor={contactoComercial} />
        </Col>
        <Col span={6}>
          <LabelValor label="email comercial" valor={emailComercial} />
        </Col>
        <Col span={6}>
          <LabelValor
            label={`Telefono comercial: ${tipoTelefonoComercial}`}
            valor={telefonoComercial}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelValor label="Contacto operativo" valor={contactoOperativo} />
        </Col>
        <Col span={6}>
          <LabelValor label="Email operativo" valor={emailOperativo} />
        </Col>
        <Col span={6}>
          <LabelValor
            label={`Telefono operativo: ${tipoTelefonoOperativo}`}
            valor={telefonoOperativo}
          />
        </Col>
      </Row>
    </>
  );
};
