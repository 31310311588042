import { Modal } from "antd";
import React from "react";

export default function ModelPage({
  titulo,
  isVisible,
  children,
  handleOk,
  handleCancel,
  width,
  style
}) {
  ////////////////
  return (
    <Modal
      width={width}
      title={titulo}
      style={{...style}}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {children}
    </Modal>
  );
}
