import { Table, Tag, Tooltip } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { WarningTwoTone } from "@ant-design/icons";

export default function TableCalidad({ dataSource }) {
  const history = useHistory();

  const columns = [
    {
      title: "Id Asistencia",
      dataIndex: "asistenciaID",
      key: "asistenciaID",
      render: (_, { asistenciaID, tieneQueja }) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            {asistenciaID}

            {tieneQueja && (
              <Tooltip title="Tiene Queja">
                <WarningTwoTone twoToneColor="red" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
    },
    {
      title: "Nombre Asegurado",
      dataIndex: "nombreAsegurado",
      key: "nombreAsegurado",
    },
    {
      title: "Servicio",
      dataIndex: "servicio",
      key: "servicio",
    },
    {
      title: "Cuenta",
      dataIndex: "cuenta",
      key: "cuenta",
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
      key: "estatus",
      render: (_, { estatus }) => {
        const colores = {
          1: {
            nombre: "Sin Calificar",
            color: "#ca2228",
          },
          2: {
            nombre: "Parcialmente Calificada",
            color: "geekblue",
          },
          3: {
            nombre: "Calificada",
            color: "green",
          },
        };

        return (
          <Tag color={colores[`${estatus}`]?.color}>
            {colores[`${estatus}`]?.nombre}
          </Tag>
        );
      },
    },
    {
      title: "Atención",
      key: "atencion",
      render(_, { encuestaAtencion }) {
        return encuestaAtencion?.calificacion || "Sin calificar";
      },
    },
    {
      title: "Tipificación",
      key: "tipificacion",
      render(_, { encuestaTipificacion }) {
        return encuestaTipificacion?.calificacion || "Sin calificar";
      },
    },
    {
      title: "Calificación Asegurado",
      key: "calificacionUsuario",
      render(_, { calificacionAsegurado }) {
        const calificacion = calificacionAsegurado;

        if (calificacion !== null) {
          return (
            <section style={{ display: "flex", justifyContent: "center" }}>
              <Tag
                color={
                  calificacion < 7
                    ? "#ca2228"
                    : calificacion == 8 || calificacion == 7
                    ? "#f6a700"
                    : "green"
                }
              >
                {calificacion < 7
                  ? "Detractor"
                  : calificacion == 8 || calificacion == 7
                  ? "Neutral"
                  : "Promotor"}
              </Tag>
            </section>
          );
        }

        return (
          <section style={{ display: "flex", justifyContent: "center" }}>
            <Tag color={"default"}>Sin calificar</Tag>
          </section>
        );
      },
    },
  ];

  return (
    <Table
      style={{ marginTop: "15px" }}
      size="small"
      columns={columns}
      rowClassName="tabalAsistencia"
      rowKey="asistenciaID"
      onRow={(asistencia) => {
        return {
          onClick: () => {
            history.push(
              `/calidad/calificarServicio/${asistencia.asistenciaID}`
            );
          },
        };
      }}
      dataSource={dataSource}
      pagination
    />
  );
}
