import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetOrdenesPagoPorCargarPago } from "../../../context/actions/ordenesPagoActions";
import CardPage from "../../../layout/CardPage";
import { OrdenesPagoCabecera } from "../pagar/OrdenesPagoCabecera";
import { ControlesPorCargarPago } from "./ControlesPorCargarPago";
import { OrdenesListaPorCargarPago } from "./OrdenesListaPorCargarPago";

export const PorCargarPago = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetOrdenesPagoPorCargarPago());
  }, []);
  return (
    <CardPage>
      <ControlesPorCargarPago />
      <OrdenesPagoCabecera />
      <OrdenesListaPorCargarPago />


    </CardPage>
  );
};
