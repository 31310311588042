import { Col, Modal, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonDiv from "../../../components/Boton/BotonDiv";
import LabelText from "../../../components/input/LabelText";
import { startPostLista } from "../../../context/actions/listasActions";
import Nombre from "./Nombre";

export function ListaNombres() {
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState("");
  const [show, setShow] = useState(false);

  const { listas } = useSelector((state) => state.listasReducer);

  const handleOk = () => {
    dispatch(startPostLista(nombre));
    setShow(false);
  };
  const handleNuevo = () => {
    setNombre("");
    setShow(true);
  };
  return (
    <>
      <Row>
        <Col span={12}>
          <div className="subtituloMenos">Listas</div>
        </Col>
        <Col span={12}>
          <BotonDiv onClick={handleNuevo} tituloBoton="nuevo" />
        </Col>
      </Row>

      {listas.map((l, i) => (
        <Nombre key={i} lista={l} />
      ))}

      <Modal
        title="Crear Lista"
        open={show}
        onOk={handleOk}
        onCancel={() => setShow(false)}
      >
        <LabelText
          label="Nombre de lista"
          onChange={({ target }) => setNombre(target.value)}
          name="nombre"
          value={nombre}
        />
      </Modal>
    </>
  );
}
