import { DeleteOutlined } from "@ant-design/icons";

export const DeleteIcon = ({ onClick }) => {
  return (
    <DeleteOutlined
      onClick={onClick}
      style={{ color: "#ca2228", fontSize: 20 }}
      className="eyeIcon"
    />
  );
};
