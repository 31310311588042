import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import ModelPage from "../../../../../../layout/ModelPage";
import { useUploadFile } from "../../useUploadFile";
import { useParams } from "react-router";

export const CargaComplementoPagoModal = ({ showModal, setShowModal }) => {
  const { ordenPagoID } = useParams();
  const { body, props, setBody, handleClick } = useUploadFile(2, ordenPagoID); // 2 = complemento pago

  const ok = () => {
    handleClick();
    setShowModal(false);
  };

  const cancel = () => {
    setBody({});
    setShowModal(false);
  };

  //////////////
  return (
    <ModelPage
      titulo="Cargar Complemento de Pago"
      isVisible={showModal}
      handleOk={ok}
      handleCancel={cancel}
    >
      <div className="tituloPequeño">
        Agregue el complemento de pago emitido por el proveedor
      </div>

      <Upload {...props} style={{ width: "100%" }}>
        <Button
          style={{ textAlign: "left", marginTop: 20 }}
          icon={<UploadOutlined />}
        >
          Seleccione documento
        </Button>
      </Upload>
      <span style={{ marginLeft: 20 }}>{body.file?.name}</span>
    </ModelPage>
  );
};
