import React from "react";
import { useSelector } from "react-redux";
import Loading from "../../../components/loading/Loading";
import { SeccionCamposCrear } from "./SeccionCamposCrear";
import { SeccionCamposListado } from "./SeccionCamposListado";

export const SeccionCampos = () => {
  const { loadingCampos } = useSelector((state) => state.listasReducer);

  if (loadingCampos) return <Loading />;

  return (
    <>
      <SeccionCamposCrear />
      <SeccionCamposListado />
    </>
  );
};
