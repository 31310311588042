
export const serviciosProveedorReduce = (asistencias, proveedorID) => {
    var array = asistencias.filter(a => a.proveedorID === proveedorID)
    var result = [];
    array.reduce((res, value) => {
        if (!res[value.servicioID]) {
            res[value.servicioID] = { servicio: value.servicio, costo: 0, cantidad: 0 };
            result.push(res[value.servicioID])
        }
        res[value.servicioID].costo += value.costo;
        res[value.servicioID].cantidad++;

        return res;
    }, {})
    return result
}
