import axios from "axios";
import { MAPBOX_TOKEN } from "../constants/cons";

const geoFetch = axios.create({
  baseURL: "https://api.mapbox.com/geocoding/v5/mapbox.places",
  params: {
    country: "mx",
    language: "es",
    access_token: MAPBOX_TOKEN,
  },
});

export default geoFetch;
