import { Col, Image, InputNumber, Modal, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { SyncIcon } from "../../../../components/icons/SyncIcon";
import { startDeleteOrdenPagoRow, startUpdateOrdenPagoRow, } from "../../../../context/actions/ordenesPagoActions";
import { dateFromString } from "../../../../utils/dateFromString";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { CheckCircleTwoTone, IdcardOutlined } from "@ant-design/icons";
import { URL_BASE_ASISTENCIAS } from "../../../../constants/url";

export const RowItem = ({
                          estatusOrdenPagoID,
                          rowItem: {
                            asistenciaID,
                            tienePlaca,
                            servicio,
                            claveSAT,
                            localCarretero,
                            monto,
                            iva,
                            ivaRetenido,
                            isrRetenido,
                            ordenPagoItemID,
                            ordenPagoID,
                            fechaLlamadaIniciada,
                            direccionOrigen,
                            direccionDestino,
                            estatusFinalID,
                            estatusFinal,
                            proveedorVerificado,
                          },
                        }) => {
  const dispatch = useDispatch();
  const [ montoNew, setMontoNew ] = useState(monto);
  const [ open, setOpen ] = useState(false)

  const [ ivaNew, setIvaNew ] = useState(iva);
  const [ ivaRetenidoNew, setIvaRetenidoNew ] = useState(ivaRetenido);
  const [ isrRetenidoNew, setIsrRetenidoNew ] = useState(isrRetenido);
  const [ claveSATNew, setClaveSATNew ] = useState(claveSAT);

  useEffect(() => {
    setIvaNew(iva);
    setIvaRetenidoNew(ivaRetenido);
    setIsrRetenidoNew(isrRetenido);
  }, [ monto ]);

  const handleSync = () => {
    const body = {
      ordenPagoItemID,
      iva: ivaNew,
      ivaRetenido: ivaRetenidoNew,
      isrRetenido: isrRetenidoNew,
      monto: montoNew,
      ordenPagoID,
      asistenciaID,
      claveSAT: claveSATNew.toString(),
    };
    dispatch(startUpdateOrdenPagoRow(body, ordenPagoID));
  };

  const handleDelete = () =>
    dispatch(startDeleteOrdenPagoRow(ordenPagoItemID, ordenPagoID));

  return (
    <div className='rowItemElemento'>
      <Row
        style={
          estatusFinalID === 4 ?
            { backgroundColor: "#fce3e3", paddingTop: 5 }
            :
            { paddingTop: 5 }} gutter={[ 10, 10 ]} className=''
      >
        <Col span={2}>
          <Row gutter={48}>
            <Col span={1}>
              <a
                href={`https://sigsa.escotel.mx/detalleAsistencia/${asistenciaID}`}
                target='_blank' rel='noreferrer'
              >
                {asistenciaID}
              </a>
            </Col>
            <Col span={1} style={{ marginLeft: 30, display: "flex", gap: "5px" }}>
              {
                proveedorVerificado && (
                  <Tooltip title='Proveedor Verificado'>
                    <CheckCircleTwoTone />
                  </Tooltip>
                )
              }

              {
                tienePlaca &&
                <Tooltip title='Placas cargadas correctamente'>
                  <IdcardOutlined onClick={() => setOpen(true)} style={{ color: 'green' }} />
                </Tooltip>
              }
              <Modal open={open} onCancel={() => setOpen(false)} title={`Placas asistencia ${asistenciaID}`} footer={null}>
                <div style={{display: 'grid', placeItems: "center"}}>
                  <Image
                    src={`${URL_BASE_ASISTENCIAS}/getPlacaImage/${asistenciaID}`}
                    style={{width: "100%", height: "200px" }}
                    height={200}
                    alt='Imagen de la placa'
                  />
                </div>
              </Modal>
            </Col>


          </Row>
        </Col>
        <Col span={2} style={{ textAlign: 'center' }}>{dateFromString(fechaLlamadaIniciada).soloFecha}</Col>
        <Col span={2}>
          <InputNumber
            disabled={estatusFinalID !== 4}
            size='small'
            style={{ width: "100%" }}
            value={claveSATNew}
            onChange={(val) => setClaveSATNew(val)}
          />
        </Col>
        <Col span={3} style={{ textAlign: 'center' }}>{servicio}</Col>

        <Col span={3} style={{ textAlign: 'center' }}>{estatusFinal} - {localCarretero === "local" ? "L" : "C"}</Col>
        <Col span={2}>
          <InputNumber
            disabled
            size='small'
            style={{ width: "100%" }}
            value={montoNew}
            onChange={(val) => setMontoNew(val)}
          />
        </Col>
        <Col span={2}>
          <InputNumber
            size='small'
            disabled
            style={{ width: "100%" }}
            value={ivaNew}
            onChange={(val) => setIvaNew(val)}
          />
        </Col>
        <Col span={2}>
          <InputNumber
            size='small'
            style={{ width: "100%" }}
            value={ivaRetenidoNew}
            onChange={(val) => setIvaRetenidoNew(val)}
          />
        </Col>
        <Col span={2}>
          <InputNumber
            size='small'
            style={{ width: "100%" }}
            value={isrRetenidoNew}
            onChange={(val) => setIsrRetenidoNew(val)}
          />
        </Col>
        <Col span={2} style={{ marginTop: 5, textAlign: "right" }}>
          {formatCurrency(monto + ivaNew - ivaRetenidoNew - isrRetenidoNew)}
        </Col>
        {estatusOrdenPagoID < 3 && (
          <Col span={2} className='spaceChildren'>
            <Tooltip title='Actualizar datos'>
              <SyncIcon onClick={handleSync} />
            </Tooltip>
            <Tooltip title='Quitar este servicio'>
              <DeleteIcon onClick={handleDelete} />
            </Tooltip>
          </Col>
        )}
      </Row>
      <Row
        gutter={[ 10, 10 ]} className='rowItemElemento' style={
        estatusFinalID === 4 ?
          { backgroundColor: "#fce3e3", borderBottom: '2px solid #ccc', }
          :
          { borderBottom: '2px solid #ccc', paddingBottom: 4 }
      }
      >
        <Col span={12}>
          <b>Origen: </b>
          {direccionOrigen}
        </Col>
        {direccionDestino !== "" && (
          <Col span={12}>
            <b>Destino: </b>
            {direccionDestino}
          </Col>
        )}
      </Row>
    </div>
  );
};
