import React from "react";
import { dateFromString } from "../../../../utils/dateFromString";

export const HistorialItem = ({ historial }) => {
  const { fecha, nombre, comentario } = historial;
  return (
    <div style={{ fontSize: 12 }}>
      <span style={{ color: "#ca2228" }}>
        {dateFromString(fecha).f1} - {nombre}:{" "}
      </span>
      {comentario}
    </div>
  );
};
