import { Col, Row } from "antd";
import CardPage from "../../../layout/CardPage";
import { FacturaSolicitada } from "./facturaSolicitada/FacturaSolicitada";
import { Asistencias } from "./asistencias/Asistencias";
import { PagoEfectuado } from "./pagoEfectuado/PagoEfectuado";
import { PagoSolicitado } from "./pagoSolicitado/PagoSolicitado";
import { ConOrdenPago } from "./conOrdenPago/ConOrdenPago";


export const Dashboard = () => {

  return (
    <CardPage>
      <Row gutter={10}>
        <Col span={4}>
          <Asistencias />
        </Col>
        <Col span={4}>
          <ConOrdenPago />
        </Col>
        <Col span={4}>
          <FacturaSolicitada />
        </Col>
        <Col span={4}>
          <PagoSolicitado />
        </Col>
        <Col span={4}>
          <PagoEfectuado />
        </Col>
      </Row>
    </CardPage>
  );
};
