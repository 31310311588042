import Agregar from "./Agregar";
import Lista from "./Lista";

export default function CamposServicio() {
  return (
    <>
      <Agregar />
      <Lista />
    </>
  );
}
