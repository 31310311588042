import { Menu } from "antd";
import {
  BankOutlined,
  DollarCircleOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setPaginaCliente } from "../../../../context/actions/clientesActions";

export default function MenuClientes() {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("campanias");

  const handleClick = ({ key }) => {
    setCurrent(key);
    dispatch(setPaginaCliente(key));
  };

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      style={{ marginBottom: 15 }}
    >
      <Menu.Item key="campanias" icon={<BankOutlined />}>
        Campañas
      </Menu.Item>
      <Menu.Item key="documentos" icon={<ToolOutlined />}>
        Documentos
      </Menu.Item>
      <Menu.Item key="script" icon={<DollarCircleOutlined />}>
        Script
      </Menu.Item>
    </Menu>
  );
}
