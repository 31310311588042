import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import {
  startDownloadTxtExcelOrdenesPago,
  startDownloadTxtOrdenesPago
} from "../../../context/actions/ordenesPagoActions";
import { OrdenesItem } from "./OrdenesItem";

export const OrdenesLista = () => {
  const dispatch = useDispatch();
  const { ordenesPagoPagar, ordenesPagoPagarFiltered } = useSelector((state) => state.ordenesPagoReducer);


  return (
    <div>
      {
        ordenesPagoPagarFiltered.length ? ordenesPagoPagarFiltered.map((o, i) => (
            <OrdenesItem key={i} o={o} />)) :
          ordenesPagoPagar.ordenes?.map((o, i) => (
            <OrdenesItem key={i} o={o} />
          ))
      }
      <div
        style={{ display: "flex", justifyContent: "space-between", width: 400, marginTop: 20, marginBottom: 20 }}
      >
        <BotonSubmit
          onClick={() => dispatch(startDownloadTxtOrdenesPago())}
          tituloBoton='Generar Archivo TXT'
        />

        <BotonSubmit
          style={{ backgroundColor: "#17ad32", color: "white", border: "none" }}
          onClick={() => dispatch(startDownloadTxtExcelOrdenesPago())}
          tituloBoton='Generar Archivo TXT Excel'
        />
      </div>
    </div>
  );
};
