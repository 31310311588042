import { types } from "../types/types";

const init = {
  usuarios: [],
  usuario: {},
  mensaje: {},
  showForma: false,
  accesoBackOffice: [],
};
export const usuariosReducer = (state = init, action) => {
  switch (action.type) {
    case types.cleanAccesoBackOffice:
      return {
        ...state,
        accesoBackOffice: [],
      };

    case types.storeAccesoBackOffice:
      return {
        ...state,
        accesoBackOffice: action.payload,
      };
    case types.storeUsusario:
      return {
        ...state,
        usuario: action.payload,
      };
    case types.setShowForma:
      return {
        ...state,
        showForma: action.payload,
        mensaje: {},
      };

    case types.setMensaje:
      return {
        ...state,
        mensaje: action.payload,
      };
    case types.storeUsuarios:
      return {
        ...state,
        usuarios: action.payload,
      };
    case types.changeUsuarioForm:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
