import LabelValor from "../label/LabelValor";
import {Col_12_8_6_4} from './Col_12_8_6_4'

export const ColCampo = ({ campo }) => {
    if (campo === null || campo === "" || campo === undefined) return <></>;
    const arr = campo.split(":");
    return (
      <Col_12_8_6_4>
        <LabelValor label={arr[0]} valor={arr[1]} />
      </Col_12_8_6_4>
    );
  };