import { Col, Row } from "antd";
import React from "react";
import ListaTiposAsistencia from "./listaTiposAsistencia/ListaTiposAsistencia";
import Servicios from "./servicios/Servicios";

export default function TiposAsistencia() {
  return (
    <Row gutter={[10, 10]}>
      <Col span={5}>
        <ListaTiposAsistencia />
      </Col>
      <Col span={19}>
        <Servicios />
      </Col>
    </Row>
  );
}
