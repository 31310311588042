import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  startGetAcesoBackOffice,
  startRemoveAcesoBackOffice,
} from "../../../../context/actions/accesoBackOfficeActions";

export const UsuarioModulosListado = () => {
  const dispatch = useDispatch();
  const { accesoBackOffice } = useSelector((state) => state.usuariosReducer);

  if (!accesoBackOffice) return <></>;

  return (
    <div>
      {accesoBackOffice.map((a, i) => (
        <Row key={i} style={{ paddingTop: 2 }}>
          <Col span={8} style={{ paddingLeft: 10 }}>
            {a.modulo}
          </Col>
          <Col span={4}>
            <DeleteOutlined
              onClick={() => dispatch(startRemoveAcesoBackOffice(a.id))}
              style={{ color: "#ca2228" }}
              className="eyeIcon"
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};
