import { Alert } from "antd";
import React from "react";

export const Mensaje = ({ componente: { type, message, show } }) => {
  if (show === true)
    return (
      <Alert
        message={message}
        type={type}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    );

  return <></>;
};
