import { Table } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { startGetOrdenesPago } from "../../../context/actions/ordenesPagoActions";
import CardPage from "../../../layout/CardPage";
import { Controles } from "./controles/Controles";
import { useColumns } from "./useColumns";
import { UseNoRepeatArrayOP } from "../../../hooks/UseNoRepeatArrayOP";

export const OrdenesMes = () => {
  const dispatch = useDispatch();

  const { ordenesPago } = useSelector((state) => state.ordenesPagoReducer);
  const propertiesToFilter = UseNoRepeatArrayOP(ordenesPago, ['proveedor', "ordenPagoID", "razonSocial", "clienteNombre"])
  const { columns } = useColumns(propertiesToFilter);



  useEffect(() => {
    dispatch(startGetOrdenesPago());
  }, []);

  return (
    <CardPage>
      <Controles />
      <Table
        size="small"
        rowClassName="size12"
        rowKey="ordenPagoID"
        dataSource={ordenesPago}
        columns={columns}
      />
    </CardPage>
  );
};
