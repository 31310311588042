import { Divider } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { UsuarioModulosCrear } from "./UsuarioModulosCrear";
import { UsuarioModulosListado } from "./UsuarioModulosListado";

export const UsuarioModulos = () => {
  const { esBackoffice } = useSelector(
    (state) => state.usuariosReducer.usuario
  );

  if (esBackoffice !== true) return <></>;
  
  return (
    <>
      <Divider />
      <UsuarioModulosCrear />
      <UsuarioModulosListado />
    </>
  );
};
