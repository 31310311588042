import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";

////
export default function Tabla() {
  const { clientesColumns } = useColums();
  const { clientes } = useSelector((state) => state.clientesReducer);

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(clientes);
  }, [clientes]);

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = clientes.filter(
        (a) =>
          (a.nombreComercial &&
            a.nombreComercial
              .toLowerCase()
              .includes(target.value.toLowerCase())) ||
          (a.razonSocial &&
            a.razonSocial.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.contactoNombre &&
            a.contactoNombre
              .toLowerCase()
              .includes(target.value.toLowerCase())) ||
          (a.contactoTelefono &&
            a.contactoTelefono
              .toLowerCase()
              .includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      setListaFinal(clientes);
    }
  };

  ////
  return (
    <>
      <Input
        onChange={filter}
        placeholder="buscar"
        style={{ width: 300, marginBottom: 10 }}
        size="small"
      />
      <Table
        rowKey="clienteID"
        columns={clientesColumns}
        dataSource={listaFinal}
        size="small"
        className="tablaListado"
      />
    </>
  );
}
