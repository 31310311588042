import { Alert, Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { cambiarEstatusCampania } from "../../../../context/actions/campaniasActions";

export const EstatusCampania = () => {
  const dispatch = useDispatch();
  const { campaniaID } = useParams();
  const { estaActiva } = useSelector(
    (state) => state.campaniasReducer.campania
  );

  const handleAction = () => dispatch(cambiarEstatusCampania(campaniaID));
  if (estaActiva)
    return (
      <Alert
        type="success"
        message="Campaña Activa"
        action={
          <Button onClick={handleAction} size="small" type="text">
            desactivar
          </Button>
        }
      />
    );

  return (
    <Alert
      type="error"
      message="Campaña Inactiva"
      action={
        <Button onClick={handleAction} size="small" type="text">
          activar
        </Button>
      }
    />
  );
};
