import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetAsistenciasAsignables } from "../../context/actions/asignacionActions";
import CardPage from "../../layout/CardPage";
import { AsistenciasListado } from "./AsistenciasListado";

export const Asignacion = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetAsistenciasAsignables());
  }, []);

  return (
    <CardPage titulo="Asistencias por Asignar">
      <AsistenciasListado />
    </CardPage>
  );
};
