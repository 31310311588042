import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonDivL from "../../../components/Boton/BotonDivL";
import { startGetProveedoresXLS } from "../../../context/actions/proveedoresActions";

export const XLSBoton = () => {

  const dispatch = useDispatch();
  return (
    <BotonDivL
      onClick={() => dispatch(startGetProveedoresXLS())}
      tituloBoton="XLS"
    />
  );
};
