import { Col, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import LabelText from "../../../../components/input/LabelText";
import LabelSelect from "../../../../components/select/LabelSelect";
import { siNo, tipoCampo } from "../../../../constants/cons";
import { startPostCampos } from "../../../../context/actions/tiposAsistenciaActions";

////
export default function Agregar() {
  const dispatch = useDispatch();
  const [body, setBody] = useState({});
  const [show, setShow] = useState(false);
  const { listas, servicioSelected } = useSelector(
    (state) => state.tiposAsistenciaReducer
  );

  const selectChange = (name, value) => {
    if (name === "tipo") {
      if (value === "lista") setShow(true);
      else setShow(false);
    }
    setBody({ ...body, [name]: value });
  };

  const handleChange = ({ target }) =>
    setBody({ ...body, [target.name]: target.value });

  const handleClick = () => {
    dispatch(startPostCampos({ ...body, servicioID: servicioSelected.id }));
  };

  /////
  return (
    <>
      <div className="subtituloMenos">Diseñador de Campos</div>
      <Row gutter={[10, 10]}>
        <Col span={5}>
          <LabelText
            name="label"
            label="Etiqueta"
            onChange={handleChange}
            value={body.etiqueta}
          />
        </Col>
        <Col span={5}>
          <LabelSelect
            onChange={(val) => selectChange("esRequerido", val)}
            opciones={siNo}
            label="Es requerido"
            value={body.esRequerido}
          />
        </Col>
        <Col span={5}>
          <LabelSelect
            onChange={(val) => selectChange("tipo", val)}
            opciones={tipoCampo}
            label="Tipo de campo"
            value={body.tipo}
          />
        </Col>

        {show && (
          <Col span={5}>
            <LabelSelect
              onChange={(val) => selectChange("listaID", val)}
              opciones={listas}
              label="Lista"
              value={body.listaID}
            />
          </Col>
        )}

        <Col span={4} style={{ paddingTop: 22 }}>
          <BotonSubmit onClick={handleClick} tituloBoton="Agregar" />
        </Col>
      </Row>
    </>
  );
}
