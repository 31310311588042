import { Col } from "antd";
import React from "react";

export const DireccionOrigen = ({ direccionOrigen }) => {
  if (
    direccionOrigen === null ||
    direccionOrigen === "" ||
    direccionOrigen === undefined
  )
    return <></>;

  return (
    <Col xs={23} md={21} xl={16} className="infoMapa line-30">
      {direccionOrigen}
    </Col>
  );
};
