import { Col, Divider, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelText from "../../../../../components/input/LabelText";
import { SelectSearch } from "../../../../../components/select/SelectSearch";
import { setValueProveedor, startGetBancos } from "../../../../../context/actions/proveedoresActions";
import { formatBancosOptions } from "../../../../../utils/formatBancosOptions";
import Enviar from "./Enviar";
import { FilesPago } from "./FilesPago";

///
export default function InformacionPago() {

  const dispatch = useDispatch();
  const {contactoPago, banco, clabe, cuenta, sucursal, referencia, claveBanco} =
    useSelector((state) => state.proveedoresReducer.proveedor);
  const {bancos} = useSelector((state) => state.proveedoresReducer);

  useEffect(() => {
    void dispatch(startGetBancos());
  }, [dispatch]);

  const bancosOptions = formatBancosOptions(bancos)
  const handleChange = ({target}) => {
    const body = {
      name: target.name,
      value: target.value,
    };
    dispatch(setValueProveedor(body));
  };

  const handleChangeBanco = (val) => {
    let claveBancoTemp = ""
    bancos.map(b => {
      if (b.nombre === val) claveBancoTemp = b.claveBanco
    })
    const bancoBody = {
      name: "banco",
      value: val
    }
    const claveBancoBody = {
      name: "claveBanco",
      value: claveBancoTemp
    }

    dispatch(setValueProveedor(bancoBody));
    dispatch(setValueProveedor(claveBancoBody));

  }

  return (
    <>
      <Row gutter={[10, 10]} style={{marginBottom: 10}}>
        <Col span={6}>
          <LabelText
            label="Contacto de pago"
            name="contactoPago"
            value={contactoPago}
            onChange={handleChange}
          />
        </Col>

        <Col span={6}>
          <SelectSearch
            value={banco}
            onChange={handleChangeBanco}
            label="Banco"
            options={bancosOptions}

          />
        </Col>
        <Col span={6}>
          <LabelText
            disabled
            label="Clave Banco"
            name="claveBanco"
            value={claveBanco}
            onChange={handleChange}
          />
        </Col>
        <Col span={6}>
          <LabelText
            label="CLABE"
            name="clabe"
            value={clabe}
            onChange={handleChange}
          />
        </Col>
        <Col span={6}>
          <LabelText
            label="Cuenta"
            name="cuenta"
            value={cuenta}
            onChange={handleChange}
          />
        </Col>
        <Col span={6}>
          <LabelText
            label="Sucursal"
            name="sucursal"
            value={sucursal}
            onChange={handleChange}
          />
        </Col>
        <Col span={6}>
          <LabelText
            label="Referencia"
            name="referencia"
            value={referencia}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Enviar/>
      <Divider/>
      <FilesPago/>
    </>
  );
}
