import React from "react";
import { useHistory } from "react-router";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import CardPage from "../../../layout/CardPage";
import { BuscaAsistenciaID } from "./BuscaAsistenciaID";
import { BuscarOrdenPagoID } from "./BuscarOrdenPagoID";

export const Busca = () => {
  const history = useHistory()
  return (
    <CardPage>


      <div style={{ width: 300, marginTop: 10, marginBottom: 50 }}>
        <BotonSubmit onClick={() => history.push("/ordenesPago/crear")} tituloBoton="Cerar Orden Nueva" />
      </div>
      <BuscarOrdenPagoID />
      <BuscaAsistenciaID />
      <div style={{ height: 200 }}></div>
    </CardPage>
  );
};
