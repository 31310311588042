import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetOrdenesPagoAutorizar } from "../../../context/actions/ordenesPagoActions";
import CardPage from "../../../layout/CardPage";
import { useColumns } from "./useColumns";
import { UseNoRepeatArrayOP } from "../../../hooks/UseNoRepeatArrayOP";
import { StatsTable } from "../StatsTable";
import { getStats } from "../../../utils/getStatsOP";

export const Autorizar2 = () => {
  const [listaFinal, setListaFinal] = useState([]);
  const dispatch = useDispatch();

  const filters = UseNoRepeatArrayOP(listaFinal, ["cliente", "proveedor"]);
  const { columns } = useColumns(filters);
  const { ordenesPagoAutorizar } = useSelector(
    (state) => state.ordenesPagoReducer
  );
  useEffect(() => {
    dispatch(startGetOrdenesPagoAutorizar());
  }, []);

  useEffect(() => {
    getOrdenesAuth1();
  }, [ordenesPagoAutorizar]);

  const getOrdenesAuth1 = () => {
    let salida = ordenesPagoAutorizar.filter((o) => o.fechaAutorizacionPago); // ya tienen autorizacion1
    setListaFinal(salida);
  };

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = listaFinal.filter(
        (a) =>
          (a.proveedor &&
            a.proveedor.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.cliente &&
            a.cliente.toLowerCase().includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      getOrdenesAuth1();
    }
  };

  //////////////
  const { totalItems, totalCash } = getStats(listaFinal);

  return (
    <CardPage>
      <div
        style={{
          display: "flex",
          gap: "30px",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <div style={{ width: 200 }}>
          <Input size="small" onChange={filter} placeholder="buscar" />
        </div>
        <div>
          <StatsTable totalCash={totalCash} totalOrdenes={totalItems} />
        </div>
      </div>

      <Table
        size="small"
        rowClassName="size12"
        rowKey="ordenPagoID"
        dataSource={listaFinal}
        columns={columns}
      />
    </CardPage>
  );
};
