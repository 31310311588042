

export const formatBancosOptions = (bancos) => {
    if (!bancos || bancos === "") return []
    let salida = []
    bancos.map(b => {
        salida = [...salida, {
            value: `${b.nombre}`,
            label: `${b.nombre}`
        }]
    })
    return salida
}
