import { Col, Row } from "antd";
import LabelValor from "../../../../components/label/LabelValor";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import { ColCampo } from "../../../../components/col/ColCampo";

export default function DatosAsistencia({data}) {
  const {
    datos
  } = data;

  return (
    <>
      <Row style={{ marginBottom: 10 }} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor label="ID" valor={datos.asistenciaID} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Campaña"
            valor={datos.asegurado?.campania?.nombre}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Aperturó" valor={datos.usuario?.nombre} />
        </Col_12_8_6_4>
      </Row>
      <Row style={{ marginBottom: 10 }} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor label="Nombre" valor={datos.nombreLlamada} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido paterno"
            valor={datos.apellidoPaternoLlamada}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido materno"
            valor={datos.apellidoMaternoLlamada}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Teléfono" valor={datos.telefono} />
        </Col_12_8_6_4>
      </Row>
      <Row style={{ marginBottom: 10 }} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor
            label="Nombre beneficiario"
            valor={datos.nombreBeneficiario}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido paterno"
            valor={datos.apellidoPaternoBeneficiario}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido materno"
            valor={datos.apellidoMaternoBeneficiario}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Email" valor={datos.email} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Tipo de persona" valor={datos.tipoPersona} />
        </Col_12_8_6_4>
      </Row>
      <Row style={{ marginBottom: 10 }} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor
            label="Validador 1"
            valor={datos.asegurado.validador1}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Validador 2"
            valor={datos.asegurado.validador2}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Vigencia"
            valor={datos.asegurado.vigenciaTermino}
          />
        </Col_12_8_6_4>
        <Col xs={24} sm={16} md={12} xl={8}>
          <LabelValor
            label="Servicio"
            valor={`${datos.servicio?.tipoAsistencia.nombre} / ${datos.servicio?.nombre}`}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }} gutter={[10, 10]}>
        <ColCampo campo={datos.campo1} />
        <ColCampo campo={datos.campo2} />
        <ColCampo campo={datos.campo3} />
        <ColCampo campo={datos.campo4} />
        <ColCampo campo={datos.campo5} />
        <ColCampo campo={datos.campo6} />
        <ColCampo campo={datos.campo7} />
        <ColCampo campo={datos.campo8} />
        <ColCampo campo={datos.campo9} />
        <ColCampo campo={datos.campo10} />
        <ColCampo campo={datos.campo11} />
        <ColCampo campo={datos.campo12} />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
          <LabelValor
            label="Direccion origen"
            valor={`${datos.direccionOrigen} (${datos.municipioOrigen}) | ${datos.latOrigen},${datos.lngOrigen} `}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
          {datos.direccionDestino !== "" && (
            <LabelValor
              label="Direccion destino"
              valor={`${datos.direccionDestino} (${datos.municipioOrigen}) | ${datos.latDestino},${datos.lngDestino}  `}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
