import { Spin } from "antd";
import React from "react";

export default function BotonSubmit({
  tituloBoton,
  onClick,
  disabled,
  loading,
  style,
}) {
  return (
    <div
      onClick={onClick}
      className={disabled ? "botonSubmitDisabled" : "botonSubmit"}
      style={{ ...style }}
    >
      {loading && <Spin size="small" />} {tituloBoton}
    </div>
  );
}
