import { Button, Col, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { startSugiereProveedor } from "../../context/actions/asignacionActions";
import { dateFromString } from "../../utils/dateFromString";
import { SelectProveedor } from "./SelectProveedor";

export const AsistenciaItem = ({ a }) => {
  const dispatch = useDispatch();
  const [proveedorID, setProveedorID] = useState();
  const [showForma, setShowForma] = useState(false);

  const handleClick = () => {
    dispatch(startSugiereProveedor(proveedorID, a.asistenciaID));
    setShowForma(false);
  };
  ////////
  return (
    <>
      <Col span={6} onClick={() => setShowForma(true)}>
        <div className="recuadroAsistencias">
          <div className="campoID">{a.asistenciaID}</div>
          <div style={{ color: "#ca2228" }}>{a.servicio}</div>
          <div>{dateFromString(a.fechaLlamadaIniciada).dateStep}</div>
          <div>{a.origen}</div>
          <div>{a.destino == ", " ? "..." : a.destino}</div>
          <div>{a.cliente}</div>
        </div>
      </Col>
      <Modal
        title="Asigna Servicio a Proveedor"
        open={showForma}
        onCancel={() => setShowForma(false)}
        footer={[
          <Button key="Cancelar" onClick={() => setShowForma(false)}>
            Cancelar
          </Button>,
          <Button type="primary" key="Asignar" onClick={handleClick}>
            Asignar
          </Button>,
        ]}
      >
        <SelectProveedor
          proveedorID={proveedorID}
          setProveedorID={setProveedorID}
          a={a}
        />
      </Modal>
    </>
  );
};
