import { useDispatch, useSelector } from "react-redux";
import { correosReporte } from "../../../../constants/cons";
import { useEffect, useState } from "react";
import { setVerTodo } from "../../../../context/actions/reportesActions";

export const useVerTodo = () => {
    const dispatch = useDispatch();
  const { email } = useSelector((state) => state.userReducer.user);


  const [canHide] = useState(correosReporte.includes(email));

  useEffect(() => {
    if(correosReporte.includes(email))
        dispatch(setVerTodo(true));
    else 
        dispatch(setVerTodo(false));
    }, [email, dispatch]);


    const handleVerTodo = (estado) =>  dispatch(setVerTodo(estado));
  return { handleVerTodo, canHide };
};
