export const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // Get the year, month, and day as separate variables (optional)
  const year = yesterday.getFullYear();
  const month = yesterday.getMonth() + 1; // Months are zero-indexed
  const day = yesterday.getDate();

  // Format the date string (optional)
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  return formattedDate;
};
